import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PaymentProps, PaymentState } from "../Interface/loginInterface";
import "./styleCheckout.css";
import {
  getCart,
  saveOrder,
  applyCoupon,
  saveOrderPeriod,
  setTabName
} from "../../Redux";
import i18next from "i18next";
import { connect } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import { addressesListk, cardTransaction, dumpCardTempOrder, getCardDetails, getTimezone, getTimezoneForLater, MashrikPaymentData, resetCoupon, saveAddress, saveAddressExtraDetails, saveCardOrder, saveCouponData, saveDeliveryFee, SaveDiscountValue, saveGuestAddress, saveOrderType, savePaymentMethod, saveStore, saveTaxDetails, SaveTaxValue } from "../../Redux/Actions";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { priceunit } from "../../ApiManager/client-config";
import jwtDecode from "jwt-decode";
import { browserName, browserVersion } from "react-device-detect";
import queryString from 'query-string'
import UseTrackEvent from "../../hooks/useTrackHook";
import axios from "axios";
class NewPayment extends Component<PaymentProps, PaymentState> {
  // discount: number;
  checkTotal: any;
  cartTotal: any;
  itemTotal: any;
  tax: any;
  totalTax: any;
  discount: number;
  couponDiscount: number;
  codeExeCount: number;
  currentTime: any;
  quantity: number;
  validTimeFlag: any;
  WindowObj: any;
  netWork = false;
  constructor(props: any) {
    super(props);

    this.state = {
      uniqueId: new Date().getTime(),
      firstname: "",
      lastname: "",
      cardOrderId: new Date().getTime(),
      email: "",
      isValidEmail: true,
      phonenumber: "971",
      mashrik_bank_data: null,
      laterdatetime: new Date(),
      special: "",
      cardordermodal: false,
      coupanCode: "",
      order_id: "",
      open: false,
      discountFlag: false,
      netWork: false,
      cartTotal: 0,
      cardUniqueOrderId: "",
      cardResponse: "",
      responseToken: "",
      paymentError: "",
      GotoHomeFlag: false,
      customer_id: "",
      errText: "",
      errModal: false,
      cardnetWork: false,
      cardLoading: false,
      billing_address: "",
      billing_city: "",
      billing_country: "",
      buildingname: "",
      buildingnumber: "",
      room: "",
      description: "",
      isDisable: false,
      ip_address: "",
      device_info: "",
      device_os: "",
      isSaveAddress: "",
      isAddressDetails: "",
      addEvent: () => { }
    };
    this.itemTotal = 0;
    this.discount = 0;
    this.quantity = 0;
    this.totalTax = 0;
    this.checkTotal = 0
    this.codeExeCount = 0;
    this.couponDiscount = 0;
    this.handleValidTime = this.handleValidTime.bind(this)
    this.handleChangeCoupan = this.handleChangeCoupan.bind(this);
    this.handlePaymentCheck = this.handlePaymentCheck.bind(this);
  }

  async componentDidMount() {
    this.getIP();
    let detailerAddress = sessionStorage.getItem('extra_address_details')
    let saverAddress = sessionStorage.getItem('address_save_session')
    this.setState({ isAddressDetails: detailerAddress, isSaveAddress: saverAddress })
    let params: any = queryString.parse(this.props.location.search)
    const paramstoken = params && params.token;
    if (paramstoken) {
      let decodedparams: any = jwtDecode(paramstoken)
      if (decodedparams && decodedparams.order_status == 'Success') {
        toast.success("Payment Successfully Completed", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose: 3000,
        });
        this.props.dumpCardTempOrder(decodedparams.order_id);
      }
      if (decodedparams.order_status !== "Success") {
        toast.error(`${decodedparams.status_message}`, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    }
    let detectOS = "Unknown OS"
    if (navigator.appVersion.indexOf("Win") != -1) detectOS = "Windows"
    if (navigator.appVersion.indexOf("Mac") != -1) detectOS = "MacOS"
    if (navigator.appVersion.indexOf("Linux") != -1) detectOS = "Linux"
    let deviceInfo = (`${detectOS},${browserName} ${browserVersion}`);
    this.setState({ device_info: deviceInfo, device_os: detectOS })
    const token: any = localStorage.getItem("token");
    let address_id = sessionStorage.getItem("address_id")
    this.props.getCart();
    this.props.setTabName("payment")
    let orderType: any = await sessionStorage.getItem("orderType");
    if (!orderType) {
      this.props.history && this.props.history.push("/")
    }
    if (token && orderType == 'Delivery') {
      if (_.isEmpty(this.props.storeSelected) || _.isEmpty(address_id)) {
        this.props.history.push({ pathname: "/checkout/location", search: window.location.search })
        this.props.setTabName("location")
      }
    }
    else if (token && orderType == 'Pickup' || orderType == 'qr-Pickup') {
      this.props.saveOrderType(orderType)
    }
    else if (!token) {
      this.props.history.push({ pathname: "/checkout/contact", search: window.location.search })
    }
    let couponData: any = sessionStorage.getItem("coupon")
    if (couponData) {
      let parsedCoupon: any = JSON.parse(couponData)
      this.props.saveCouponData(parsedCoupon)
    }
  }

  componentWillUnmount() {
    this.props.savePaymentMethod("Card")
  }

  getIP = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    this.setState({ ip_address: res.data.ip })
  };
  //here
  saveEvents = (option: any, category: any) => {
    try {
      this.state.addEvent({
        category,
        events: option
      });
    } catch (error) {
      console.error("error while saving event on header page", error);
    }
  }

  // add this handle
  handleSetAddEvent = (eventFunc: any) => {
    this.setState({ addEvent: eventFunc });
  }

  ApplyCoupan = async () => {
    let { coupanCode, discountFlag } = this.state;
    let { orderType, cart, coupon } = this.props;
    this.codeExeCount = 0;
    let Cart: any = cart;
    let itemTotal;
    let discountItemFlag = false;
    let dataCart = JSON.parse(Cart);
    dataCart.forEach((item: any) => {
      itemTotal = item.totalItemPrice
      if (item.combo) {
        if (item.combo.discount_price) {
          this.setState({ discountFlag: true })
          discountItemFlag = true;
        }
      }
      else {
        if (item.selectedsize.discount_price) {
          this.setState({ discountFlag: true })
          discountItemFlag = true;

        }
      }
    })
    if (coupanCode !== "" && discountItemFlag == false) {
      let token: any = localStorage.getItem("token");
      let decodedData: any = jwtDecode(token);
      this.props.getCart();
      let storeSelected: any = await sessionStorage.getItem("selectedStore");
      let data = {
        customer_id: decodedData.customer.customer_id,
        store_id: storeSelected ? JSON.parse(storeSelected).store_id : null,
        coupon_code: coupanCode,
        order_mode: "online",
        order_channel: orderType == "Delivery" ? "delivery" : "pickup"
      };
      if (coupon.min_amount > JSON.stringify(itemTotal)) {
        toast.error("Coupon can not as cart total is less than cooupon min total value", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000, className: 'toast-message' });
        this.props.resetCoupon({});
      }
      await this.props.applyCoupon(data);
      this.calcTotal(dataCart);
    } else {
      toast.error("Coupon can not be applied on discounted items", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000, className: 'toast-message' });
    }
  };

  handlePaymentCheck = (e: any) => {
    this.props.savePaymentMethod(e.target.value)
  }

  isCoupanReady = () => {
    let { coupanCode } = this.state;
    if (coupanCode.trim() !== "") {
      return true;
    } else {
      return false;
    }
  };
  isGuestReadyCash = () => {
    let { laterdatetime } = this.state;
    let { orderPeriod, paymentMethod, brand_details } = this.props;
    if (orderPeriod == "now") {
      return orderPeriod !== "" && paymentMethod !== "" && this.checkTotal > brand_details.min_cart_value
    } else if (orderPeriod == "later") {
      return paymentMethod !== "" && orderPeriod !== "" && laterdatetime !== "" && this.validTimeFlag == true && this.checkTotal > brand_details.min_cart_value;
    }
  };
  //Compare time make array of hours
  HoursArray(hourDiff: any, starttime: any) {
    if (String(hourDiff).indexOf('-') !== -1) {
      hourDiff = hourDiff + 24;
    }
    var hours: any = [];
    let hour = starttime;
    for (let i = 0; i <= hourDiff; i++) {
      hour = starttime + i;
      let AmOrPm = '';
      hour >= 12 && hour < 24 ? (AmOrPm = 'pm') : (AmOrPm = 'am');
      hour = hour % 12 || 12;
      hours.push(hour + AmOrPm);
    }
    var string = hours.join(':');
    return string;
  };

  handleValidTime(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value
    });

    let storeSelected: any = sessionStorage.getItem("selectedStore");
    storeSelected = JSON.parse(storeSelected);
    let validMinDateTime = new Date();
    validMinDateTime.setHours(validMinDateTime.getHours() + 1);
    let futureDate = moment(event.target.value).startOf('day')
    let currentDate = moment(new Date()).startOf('day');
  
    
    
        let futureDatetime = new Date(event.target.value)
        let futureSelectedDate = moment(futureDatetime).format("HH:mm:ss");

    // console.log('futureSelectedDate',futureSelectedDate)
    // console.log('storeSelected.store_close_time',storeSelected.store_close_time) 

    if (futureDate.isSame(currentDate)) {

      if (futureDatetime >= validMinDateTime) {

        this.validTimeFlag = true;
      } 
      // ===================
      // else if (futureSelectedDate >= storeSelected.store_close_time) {
      //   alert('ssdddd')
      //   toast.error("At this time store is not open, so order cannot be placed");
      //   this.validTimeFlag = true;
      // }
      else {
        toast.error("Future order can be placed minimum 1 hour ahead of current time", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000, className: 'toast-message' });
        this.validTimeFlag = false;
      }
    }
    else if (futureSelectedDate >= storeSelected.store_close_time) {

      toast.error("At this time store is not open, so order cannot be placed");
      this.validTimeFlag = false;
    }
    else {
      this.validTimeFlag = true;
    }

  }
  handleChangeCoupan(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  calcItemTotal = (data: any) => {
    let sum = 0;
    data.forEach((item: any) => {
      sum += parseFloat(item.totalItemPrice);
    });
    this.itemTotal = sum;
    return sum;
  };
  calculateOrderDiscount = (data: any) => {
    let discount = 0;
    data.forEach((item: any) => {
      if (item.combo) {
        if (item.combo.discount_price) {
          discount += item.quantity * item.combo.combo_mrp_price - item.combo.discount_price;
        }
      } else {
        if (item.selectedsize.discount_price) {
          discount += item.quantity * item.selectedsize.mrp - item.selectedsize.discount_price;
        }
      }
    })
    return discount;
  }
  calcTotalTax = (taxAmount: any) => {
    let totalTax = 0;
    if (this.props.paymentMethod == 'Cash') {
      if (this.props.taxDataCash.tax_type == 0) {
        let tax_rate = this.props.taxDataCash.tax_percent;
        totalTax = (this.cartTotal) * ((parseInt(tax_rate)) / (100 + parseInt(tax_rate)));
        this.totalTax = totalTax.toFixed(2);
      }
      else {
        totalTax = this.cartTotal * this.props.taxDataCash.tax_percent / 100;
        this.totalTax = totalTax.toFixed(2);
      }
    }
    else if (this.props.paymentMethod == 'Card') {
      if (this.props.taxDataCard.tax_type == 0) {
        let tax_rate = this.props.taxDataCard.tax_percent;
        totalTax = (this.cartTotal) * ((parseInt(tax_rate)) / (100 + parseInt(tax_rate)));
        this.totalTax = totalTax.toFixed(2);
        let session_tax_rate: any = sessionStorage.getItem("tax_rate")
        if (_.isEmpty(session_tax_rate)) {
          sessionStorage.setItem("tax_rate", tax_rate)
        }
      }
      else {
        totalTax = this.cartTotal * this.props.taxDataCard.tax_percent / 100;
        this.totalTax = totalTax.toFixed(2);
        let session_tax_rate: any = sessionStorage.getItem("tax_rate")
        if (_.isEmpty(session_tax_rate)) {
          sessionStorage.setItem("tax_rate", this.props.taxDataCard.tax_percent)
        }
      }
    }
    else if (this.props.paymentMethod == 'COD' || this.props.paymentMethod === 'COP') {
      if (this.props.taxDataCard.tax_type == 0) {
        let tax_rate = this.props.taxDataCard.tax_percent;
        totalTax = (this.cartTotal) * ((parseInt(tax_rate)) / (100 + parseInt(tax_rate)));
        this.totalTax = totalTax.toFixed(2);
      }
      else {
        totalTax = this.cartTotal * this.props.taxDataCard.tax_percent / 100;
        this.totalTax = totalTax.toFixed(2);
      }
    }
    this.props.SaveTaxValue(this.totalTax)
    if (this.props.paymentMethod == 'Card') {
      sessionStorage.setItem("tax_amount", this.totalTax)
    }
    return totalTax;
  }

  //here
  calcTotal = (data: any) => {
    let { deliveryfee, coupon } = this.props;
    this.couponDiscount = 0;
    this.discount = 0;
    let sum: any = 0;
    // let Coupon: string = coupon && coupon.channel;
    data.forEach((item: any) => {
      sum += parseFloat(item.totalItemPrice);
    });
    let discountValue: any = 0;
    if (!_.isEmpty(coupon)) {
      // if (
      //   Coupon.charAt(0).toUpperCase() + Coupon.slice(1) ==
      //   this.props.orderType
      // ) {
      if (sum > coupon.min_amount) {
        if (coupon.free_delivery === 0) {
          if (coupon.type == "menu") {
            if (coupon.coupon_value !== 0) {
              this.couponDiscount = coupon.coupon_value;
              discountValue += coupon.coupon_value;
            } else {
              discountValue = (sum * coupon.percent) / 100;
              this.couponDiscount = discountValue;
            }
            if (this.couponDiscount === 0 && this.codeExeCount == 0) {
              toast.error("Coupon can not be applied on these items", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000, className: 'toast-message' });
              this.props.resetCoupon({});
              this.codeExeCount = 1
            }
          } else if (coupon.type == "group") {
            let CouponGroupsArr = JSON.parse(coupon.groups_json);
            data.forEach((item: any, index: any) => {
              CouponGroupsArr.map((coupon_groups: any) => {
                if (item.combo) {
                  if (item.combo.group_id.group_id == coupon_groups.value) {
                    if (coupon.coupon_value !== 0) {
                      discountValue = coupon.coupon_value;
                      this.couponDiscount = discountValue;
                      // item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
                    } else {
                      discountValue = (item.totalItemPrice * coupon.percent) / 100;
                      this.couponDiscount += discountValue;
                      // item.totalItemPrice = item.totalItemPrice - discountValue;
                    }
                  }
                }
                if (item.item) {
                  if (
                    item.item.item_group_id.group_id == coupon_groups.value ||
                    item.item.group_id == coupon_groups.value
                  ) {
                    if (coupon.coupon_value !== 0) {
                      discountValue = coupon.coupon_value;
                      this.couponDiscount = discountValue;
                      // item.totalItemPrice = item.totalItemPrice - discountValue;
                    } else {
                      discountValue = (item.totalItemPrice * coupon.percent) / 100;
                      this.couponDiscount += discountValue;
                      // item.totalItemPrice = item.totalItemPrice - discountValue;
                    }
                  }
                }
              });
            });
            if (this.couponDiscount === 0 && this.codeExeCount == 0) {
              toast.error("Coupon can not be applied on these items", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000, className: 'toast-message' });
              this.props.resetCoupon({});
              this.codeExeCount = 1
            }
          } else if (coupon.type == "item") {
            let CouponitemsArr = JSON.parse(coupon.items_json);
            data.forEach((item: any, index: any) => {
              CouponitemsArr.map((coupon_items: any) => {
                if (item.combo && coupon_items.combo_id) {
                  if (item.combo.combo_id == coupon_items.combo_id) {
                    if (coupon.coupon_value !== 0) {
                      discountValue = coupon.coupon_value * item.quantity;
                      this.couponDiscount += discountValue;
                      // item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
                    } else {
                      discountValue = (item.totalItemPrice * coupon.percent) / 100;
                      this.couponDiscount += discountValue;
                      // item.totalItemPrice = item.totalItemPrice - discountValue;
                    }
                  }
                }
                if (item.item && coupon_items.menu_item_id) {
                  if (item.item.menu_item_id == coupon_items.menu_item_id) {
                    if (coupon.coupon_value !== 0) {
                      discountValue = coupon.coupon_value * item.quantity;
                      this.couponDiscount += discountValue;
                      // item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
                    } else {
                      discountValue = (item.totalItemPrice * coupon.percent) / 100;
                      this.couponDiscount += discountValue;
                      // item.totalItemPrice = item.totalItemPrice - discountValue;
                    }
                  }
                }
              });
            });
            if (this.couponDiscount === 0 && this.codeExeCount == 0) {
              toast.error("Coupon can not be applied on these items", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000, className: 'toast-message' });
              this.props.resetCoupon({});
              this.codeExeCount = 1
            }
          } else if (coupon.type == "store") {
            if (coupon.coupon_value !== 0) {
              this.couponDiscount = coupon.coupon_value;
              discountValue += coupon.coupon_value;
            } else {
              discountValue = (sum * coupon.percent) / 100;
              this.couponDiscount += discountValue;
            }
            if (this.couponDiscount === 0 && this.codeExeCount == 0) {
              toast.error("Coupon can not be applied on these items", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
              this.props.resetCoupon({});
              this.codeExeCount = 1
            }
          }
        } else {
          this.props.saveDeliveryFee(0)
        }
      }
      else {
        if (this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error("Coupon can not be applied on cart value below " + coupon.min_amount, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000, className: 'toast-message' });
          this.props.resetCoupon({});
          this.codeExeCount = 1
        }
      }
    } else {
      this.discount = 0;
      this.couponDiscount = 0;
      this.cartTotal = 0;
    }
    if (this.couponDiscount > sum) {
      this.couponDiscount = sum;
      sum = 0;
    }
    if (this.props.orderType == "Delivery") {
      sum = this.couponDiscount > sum ? 0 + parseFloat(deliveryfee) : sum - this.couponDiscount + parseFloat(deliveryfee);
      // sum = sum - this.couponDiscount + parseFloat(deliveryfee);
    } else if (this.props.orderType == "Pickup" || this.props.orderType == "qr-Pickup") {
      sum = this.couponDiscount > sum ? 0 : sum - this.couponDiscount;
      // sum = sum - this.couponDiscount;
    }
    this.props.SaveDiscountValue(this.couponDiscount);
    // sessionStorage.setItem("coupon_discount",JSON.stringify(this.couponDiscount))
    this.cartTotal = sum;
    if (this.props.paymentMethod == "Cash") {
      if (this.props.taxDataCash.tax_type == 1) {
        sum = parseFloat(sum) + parseFloat(this.totalTax);
        // sum = parseInt(sum);
      } else {
        sum = parseFloat(sum);
      }
    } else if (this.props.paymentMethod == "Card") {
      if (this.props.taxDataCard.tax_type == 1) {
        sum = parseFloat(sum) + parseFloat(this.totalTax);
        // sum = parseInt(sum);
      } else {
        sum = parseFloat(sum);
      }
    }
    this.checkTotal = sum
    return sum;
    // }
  };

  calcTotalDiscount = (discount: any) => {
    return discount;
  }

  productItem = (data: any) => {
    let { deliveryfee, coupon } = this.props;
    let { cartTotal } = this.state;
    cartTotal = this.cartTotal
    // let Coupon: string = coupon && coupon.channel;
    if (!_.isEmpty(data)) {
      let cartData: any[] = JSON.parse(data);
      if (cartData.length > 0) {
        return (
          <div className="col-lg-4">
            <div className="cart-summary" style={{ padding: "1.4rem 1.8rem 0.1rem" }}>
              <h3 className="myprofile"> {i18next.t('checkoutPage.order_summary')}</h3>
              {/* <h4>
                <a className="custom-default-fonts-color"
                >
                  {cartData.length} {i18next.t('checkoutPage.products_in_cart')}
                </a>
              </h4> */}
              {/* <Scrollbars style={{ height: 250 }}>
                <div id="total-estimate-section">
                  <table className="table table-mini-cart">
                    <tbody>

                      {cartData.map((carts, indexes) => (
                        <div style={{ display: 'contents' }}>
                          <tr className="d-flex" key={indexes}>
                            <td className="product-col col-3">
                              <figure className="product-image-container">
                                <a className="product-image">
                                  <img
                                    src={`${carts.selectedsize ? carts.selectedsize.image_url : carts.image}`}
                                    alt="product"
                                  />
                                </a>
                              </figure>
                            </td>
                            <td className="col-6">
                              <div>
                                <h2
                                  className="product-title custom-default-fonts-color"
                                >
                                  {!_.isEmpty(carts.item) &&
                                    <a>
                                      {carts.quantity}{'X. '}{carts.item.item_name}
                                    </a>
                                  }
                                  {!_.isEmpty(carts.combo) &&
                                    <a>
                                      {carts.quantity}{'X. '}{carts.combo.combo_name}
                                    </a>
                                  }


                                </h2>
                              </div>
                            </td>
                            {carts.selectedsize
                              ?
                              <td className="col-md-3">
                                {((carts.selectedsize.discount_price || carts.selectedsize.discount_price == 0) && carts.selectedsize.order_channel == 'online') ?
                                  <>
                                    {priceunit + (carts.selectedsize.discount_price * carts.quantity).toFixed(2)}
                                  </>
                                  :
                                  <>
                                    {priceunit + (carts.selectedsize.mrp * carts.quantity).toFixed(2)}
                                  </>
                                }
                              </td>
                              :
                              <td className="col-md-3">
                                {priceunit + (carts.price * carts.quantity).toFixed(2)}
                              </td>
                            }

                          </tr>
                          <tr>

                            {
                              (!_.isEmpty(carts.item) && !_.isEmpty(carts.item.modifiers)) &&
                              Object.keys(carts.item.modifiers).map(
                                (key: any, index) => {
                                  return (
                                    carts.item.modifiers[key].map(
                                      (item: any, index: any) => {
                                        return (
                                          item.selected === true &&
                                          <tr className="d-flex">
                                            <td className="col-3">

                                            </td>
                                            <td className="col-6">
                                              {
                                                (item.quantity*carts.quantity) + 'X. ' + item.modifier_name  
                                              }
                                            </td>
                                            <td className="col-3">
                                              {priceunit + (item.modifier_sale_price * item.quantity*carts.quantity).toFixed(2)}{''}
                                            </td>

                                          </tr>
                                        )
                                      })
                                  );
                                }
                              )
                            }
                            {
                              (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.modifiers)) &&
                              Object.keys(carts.combo.modifiers).map(
                                (key: any, index) => {
                                  return (
                                    carts.combo.modifiers[key].map(
                                      (item: any, index: any) => {
                                        return (
                                          item.selected === true &&
                                          <tr className="d-flex">
                                            <td className="col-3">

                                            </td>
                                            <td className="col-6">
                                              {
                                                item.modifier_name + ' x ' + item.quantity
                                              }
                                            </td>
                                            <td className="col-3">
                                              {priceunit + item.modifier_sale_price * item.quantity*carts.quantity}{''}
                                            </td>

                                          </tr>
                                        )
                                      })
                                  );
                                }
                              )
                            }

                            {
                              (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.choices)) &&
                              Object.keys(carts.combo.choices).map(
                                (key: any, index) => {
                                  return (
                                    carts.combo.choices[key].map(
                                      (item: any, index: any) => {
                                        return (
                                          item.selected === true && item.quantity > 0 &&
                                          <tr className="d-flex">
                                            <td className="col-3">

                                            </td>
                                            <td className="col-6">
                                              {item.size ?
                                                <span>{item.item_name}({JSON.parse(item.size).size}) x {item.quantity * carts.quantity}</span>
                                                :
                                                <span>{item.item_name + ' x ' + item.quantity}</span>
                                              }
                                            </td>
                                            {item.extra_price > 0 && item.quantity == 1 ?
                                              <td className="col-3">
                                                {priceunit + item.extra_price * carts.quantity}{''}
                                              </td>
                                              :
                                              <td className="col-3">
                                                {priceunit + item.extra_price * item.quantity * carts.quantity}{''}
                                              </td>
                                            }


                                          </tr>
                                        )
                                      })
                                  );
                                }
                              )
                            }



                          </tr>
                          <tr>
                            <tr className="d-flex">
                              <td className="col-3">

                              </td>
                              <td className="col-6">
                                {
                                  i18next.t('checkoutPage.total')
                                }
                              </td>
                              <td className="col-3">
                                {priceunit + carts.totalItemPrice}{''}
                              </td>

                            </tr>
                          </tr>
                        </div>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Scrollbars> */}
              {/* cart items end */}
              {this.props.orderType === "Delivery" && (
                <div className="checkout-info-box">
                  <address>
                    <h5> {i18next.t('checkoutPage.delivery_address')}</h5>
                    <p>
                      { this.props.extra_address_details == "" ? this.state.isAddressDetails : this.props.extra_address_details }
                      { '  ' + '/' + '  ' }   
                      { this.props.address_save == "" ? this.state.isSaveAddress : this.props.address_save }
                    </p>
                  </address>
                  {this.props.houseDetail && (
                    <address>
                      <h5> {i18next.t('checkoutPage.detail_for_address')}</h5>
                      <p>{this.props.extra_address_details}</p>
                    </address>
                  )}
                </div>
              )}

              {coupon && this.couponDiscount == 0 && (
                <div>
                  <h4 style={{ paddingTop: "10px" }}>{i18next.t('checkoutPage.apply_discount_code')}</h4>
                  <div
                    style={{ paddingTop: "10px", marginBottom: "-1px" }}
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder={i18next.t('checkoutPage.apply_discount_code')}
                        name="coupanCode"
                        onChange={this.handleChangeCoupan}
                      />
                      <div className="input-group-append">
                        <button
                          id="roundb"
                          className="btn btn-sm btn-primary custom-default-red-button"
                          disabled={!this.isCoupanReady()}
                          onClick={() => {
                            this.ApplyCoupan()
                            // this.saveEvents({ event_name: "click on apply coupon button" }, "events")
                          }}
                        // type="submit"
                        >
                          {/* here */}
                          {i18next.t('checkoutPage.apply_coupon_btn')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* coupan end */}
              <table className="table table-totals">
                <tbody>
                  <tr>
                    <td>{i18next.t('checkoutPage.subtotal')}</td>
                    <td>{priceunit + this.calcItemTotal(cartData).toFixed(2)}</td>
                  </tr>

                  {this.props.orderType == "Delivery" &&
                    !_.isEmpty(this.props.storeSelected) && (
                      <tr>
                        {this.props.deliveryfee == 0 ?
                          <>
                            <td className="discountStyle">{i18next.t("checkoutPage.delivery_fee")}</td>
                            <td className="discountStyle">{priceunit + deliveryfee}</td>
                          </>
                          :
                          <>
                            <td>{i18next.t("checkoutPage.delivery_fee")}</td>
                            <td>{priceunit + deliveryfee}</td>
                          </>
                        }
                      </tr>
                    )}
                  {!_.isEmpty(coupon) && this.props.discountAmount > 0 &&
                    // Coupon.charAt(0).toUpperCase() + Coupon.slice(1) ==
                    // this.props.orderType && (
                    <tr>
                      <td className="discountStyle">{i18next.t('checkoutPage.discount')}</td>
                      <td className="discountStyle">
                        {coupon.coupon_value !== 0
                          ? `${priceunit + this.calcTotalDiscount(this.props.discountAmount).toFixed(2)}`
                          : `${priceunit + this.calcTotalDiscount(this.props.discountAmount).toFixed(2)}`
                        }
                      </td>
                    </tr>
                    // )
                  }
                  {this.calculateOrderDiscount(cartData) > 0 &&
                    <tr>
                      <td className="discountStyle">{i18next.t('common.your_discount')}</td>
                      <td className="discountStyle">
                        {priceunit + this.calculateOrderDiscount(cartData).toFixed(2)}
                      </td>
                    </tr>
                  }
                </tbody>
                <tfoot>
                  <tr>
                    <td style={{ paddingBottom: "unset" }}>{i18next.t('checkoutPage.order_total')}</td>
                    <td style={{ paddingBottom: "unset" }}>{priceunit + this.calcTotal(cartData).toFixed(2)}</td>
                  </tr>
                  {this.props.paymentMethod == 'Cash' ?
                    <React.Fragment>
                      {this.props.taxDataCash.tax_type == 1 ?
                        <tr >
                          <td style={{ color: "rgb(255, 131, 0)", fontSize: "13px", fontStyle: "italic", textAlign: "inherit" }}>{i18next.t('checkoutPage.vat')}: {this.props.taxDataCash.tax_percent}{"%" + " "}{i18next.t('checkoutPage.ie_')}{this.calcTotalTax(this.props.taxAmount).toFixed(2) + " " + priceunit} </td>
                          {/* <td>{priceunit + this.calcTotalTax(this.props.taxAmount).toFixed(2)}</td> */}
                        </tr>
                        :
                        <tr>
                          <td style={{ color: "rgb(255, 131, 0)", fontSize: "13px", fontStyle: "italic", textAlign: "inherit" }}>{i18next.t('checkoutPage.vat_incl')}{this.props.taxDataCash.tax_percent}{"%" + " "}{i18next.t('checkoutPage.ie_')}{this.calcTotalTax(this.props.taxAmount).toFixed(2) + " " + priceunit}</td>
                          {/* <td>{priceunit + this.calcTotalTax(this.props.taxAmount).toFixed(2)}</td> */}
                        </tr>
                      }
                    </React.Fragment>
                    :
                    <React.Fragment>
                      {this.props.taxDataCard.tax_type == 1 ?
                        <tr>
                          <td style={{ color: "rgb(255, 131, 0)", fontSize: "13px", fontStyle: "italic", textAlign: "inherit" }}>{i18next.t('checkoutPage.vat')}: {this.props.taxDataCard.tax_percent}{"%" + " "}{i18next.t('checkoutPage.ie_')}{this.calcTotalTax(this.props.taxAmount).toFixed(2) + " " + priceunit}</td>
                          {/* <td>{priceunit + this.calcTotalTax(this.props.taxAmount).toFixed(2)}</td> */}
                        </tr>
                        :
                        <tr>
                          <td style={{ color: "rgb(255, 131, 0)", fontSize: "13px", fontStyle: "italic", textAlign: "inherit" }}>{i18next.t('checkoutPage.vat_incl')}{this.props.taxDataCard.tax_percent}{"%" + " "}{i18next.t('checkoutPage.ie_')}{this.calcTotalTax(this.props.taxAmount).toFixed(2) + " " + priceunit}</td>
                          {/* <td>{priceunit + this.calcTotalTax(this.props.taxAmount).toFixed(2)}</td> */}
                        </tr>
                      }
                    </React.Fragment>
                  }
                </tfoot>
              </table>

              {/* <div className="checkout-methods"> */}
              {/* <Link
                  style={{
                    backgroundColor: "#950a1c",
                    borderColor: "#950a1c",
                  }}
                  to="/payments"
                  className="btn btn-block btn-sm btn-primary"
                >
                  Go to Checkout
                </Link> */}
              {/* <a href="#" className="btn btn-link btn-block">Check Out with Multiple Addresses</a> */}
              {/* </div> */}
            </div>
          </div>
        );
      } else {
        <div>{i18next.t('common.empty_cart')}</div>;
      }
    } else {
      <div>{i18next.t('common.empty_cart')}</div>;
    }
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };

  onCloseModalCard = () => {
    this.setState({ cardordermodal: false });
  };

  handleCloseCardModal = () => {
    sessionStorage.removeItem("cart");
    this.props.history.push("/menu")
  }
  GotoHome = () => {
    this.setState({ GotoHomeFlag: true })
    localStorage.setItem("orderId", this.props.order_id)
    setTimeout(() => {
      window.location.href = "/track-order"
    }, 2000);
  }
  savePaymentCash = (option: any) => {
    this.setState({ netWork: false });
    this.props.savePaymentMethod(option);
  }
  savePayment = async () => {
    let orderType = sessionStorage.getItem("orderType")
    let storeSelected: any = await sessionStorage.getItem("selectedStore");
    storeSelected = JSON.parse(storeSelected);
    let address = sessionStorage.getItem("address_save_session")
    let customer_phone = localStorage.getItem("phoneNumber")
    let customer_token = localStorage.getItem("token")
    let country = 'united arab emirates'
    let TimingCheck: any = false;
    if (this.props.orderPeriod == "now") {
      if (storeSelected?.isDays == 0) {
        TimingCheck = await this.compareOpenClosingTimeCard(storeSelected);
      }
      else if (storeSelected?.isDays == 1) {
        TimingCheck = await this.compareTimingbyEachDayCard(storeSelected);
      }
    }
    else if (this.props.orderPeriod == "later") {
      if (storeSelected.isDays == 0) {
        TimingCheck = this.compareLaterOpenClosingTimeCard(storeSelected);
      }
      else if (storeSelected.isDays == 1) {
        TimingCheck = this.compareLaterTimingbyEachDayCard(storeSelected)
      }
    }

    if (TimingCheck) {
      let amountOrder: any = "";
      if (this.props.taxDataCard.tax_type == 1) {
        amountOrder = (this.cartTotal + (this.cartTotal * this.props.taxDataCard.tax_percent) / 100)
      } else {
        amountOrder = this.cartTotal
      }
      this.props.savePaymentMethod("Card")
      // amountOrder = amountOrder - this.props.discountAmount
      let obj: any = {
        currency: "AED",
        amount: amountOrder,
        order_id: this.state.cardOrderId,
        address,
        customer_phone,
        customer_token,
        country,
        address_id: sessionStorage.getItem("address_id"),
        orderType: orderType,
      }

      let deliveryfee: any = 0
      deliveryfee = storeSelected.state_id.delivery_fee
      if (orderType == 'Delivery' && parseFloat(deliveryfee) > 0) {
        Object.assign(obj, { delivery_fee: deliveryfee })
      }
      // if(_.isEmpty(paramstoken)) {
      await this.props.MashrikPaymentData(obj, this.cartTotal)
      this.setState({
        cardnetWork: false,
        netWork: false,
        cardResponse: ''
      });
      // }

    }
    if (this.props.orderPeriod == "later") {
      let amountOrder: any = "";
      if (this.props.taxDataCard.tax_type == 1) {
        amountOrder = (this.cartTotal + (this.cartTotal * this.props.taxDataCard.tax_percent) / 100)
      } else {
        amountOrder = this.cartTotal
      }
      this.props.savePaymentMethod("Card")
      // amountOrder = amountOrder - this.props.discountAmount
      let obj: any = {
        currency: "AED",
        amount: amountOrder,
        order_id: this.state.cardOrderId,
        address,
        customer_phone,
        customer_token,
        country,
        address_id: sessionStorage.getItem("address_id"),
        orderType: orderType
      }
      if (this.props.orderPeriod == "later") {
        obj.future_status = 1
        let offset = new Date(this.state.laterdatetime).getTimezoneOffset()
        let finaloffset = Math.abs(offset) / 60
        let date = new Date(this.state.laterdatetime)
        let selectedtime: any = moment(date).format("YYYY-MM-DD HH:mm")
        date.setHours(date.getHours() - finaloffset);
        obj.future_date = moment(date).format("YYYY-MM-DD HH:mm")
        let future_order: any = {
          orderPeriod: this.props.orderPeriod,
          future_date: obj.future_date,
          selectedtime: selectedtime
        }
        sessionStorage.setItem("future_order", JSON.stringify(future_order))

        // obj.future_date = moment(this.state.laterdatetime).utc(true).format('YYYY-MM-DD HH:mm')
      }
      // if(_.isEmpty(paramstoken)) {
      await this.props.MashrikPaymentData(obj, this.cartTotal)
      // }
      // await this.props.MashrikPaymentData(obj)
    }
  }

  saveOrderPeriod = () => {
    this.props.saveOrderPeriod("later")
  }
  updateLoaderButton = () => {
    this.setState({
      cardnetWork: false,
      netWork: false,
      cardResponse: '',
      uniqueId: new Date().getTime()
    });
  }
  paymentTabs = (successFlag: any) => {
    let lang = localStorage.getItem("lang")
    let doc = (document as any)
    let { netWork } = this.state;
    let { cart, orderType, posFlag, futureOpen, order_id, brand_details } = this.props;
    const token = localStorage.getItem("token");
    let decoded: any;
    if (token) {
      decoded = jwt_decode(token);
    }
    let todayDateTime = new Date();
    todayDateTime.setSeconds(0, 0);
    let today = todayDateTime.toISOString().split(".")[0];
    let maxDateTime = todayDateTime.setDate(todayDateTime.getDate() + 1);
    let maxDate = new Date(maxDateTime).toISOString().split(".")[0];
    return (
      <div className="row">
        {/* leftsidebar summary */}
        {this.productItem(cart)}
        <div
          className=" locationsec col-lg-8 order-lg-first"
          style={{ height: "40%" }}
        >
          <div className="checkout-payment">
            {/* <div id="new-checkout-address" className="show">
              {token ? (
                <h2 className="title">{i18next.t('checkoutPage.order_timing_title')}</h2>
              ) : null}
              {token ? (
                <div className="form-group">
                  <div
                    className=""
                    onClick={() => {
                      this.props.saveOrderPeriod("now");
                    }}
                  >
                    <input
                      style={{ fontSize: "16px" }}
                      type="radio"
                      name="orderPeriod"
                      value="now"
                      {...(this.props.orderPeriod == "now" && {
                        checked: true
                      })}
                    />
                    <label className="now" style={{ marginLeft: "10px" }}>{i18next.t('checkoutPage.now')} </label>
                  </div>
                  <div
                    className=""
                    onClick={() => {
                      this.props.saveOrderPeriod("later");
                    }}
                  // style={{ width: "40%" }}
                  >
                    <input
                      style={{ fontSize: "16px" }}
                      type="radio"
                      name="orderPeriod"
                      value="later"
                      {...(this.props.orderPeriod == "later" && {
                        checked: true
                      })}
                    />
                    <label className="later" style={{ marginLeft: "10px" }}>{i18next.t('checkoutPage.later')}</label>
                    {this.props.orderPeriod == "later" ? (
                      <input
                        style={{ fontSize: "16px" }}
                        type="datetime-local"
                        min={today}
                        max={maxDate}
                        value={this.state.laterdatetime}
                        className="form-control laterDateInput"
                        placeholder="DD/MM/YYYY"
                        name="laterdatetime"
                        onChange={this.handleValidTime}
                      />
                    ) : null}
                  </div>
                  <div></div>
                  <div></div>
                </div>
              ) : null}
            </div> */}
            {this.props.orderType == 'Pickup' && <> <div className="">
              <h2 className='title'>{i18next.t('checkoutPage.order_timing_title')}</h2>
              <div onClick={() => { this.props.saveOrderPeriod("now"); }} className={this.state.isDisable ? "disable" : ""}>
                <input
                  style={{ fontSize: "16px" }}
                  type="radio"
                  name="orderPeriod"
                  value="now"
                  {...(this.props.orderPeriod == "now" && {
                    checked: true
                  })}
                  className="order_method_radio"
                  id="nowMobile"
                />
                <label style={{ marginLeft: "10px" }}>{i18next.t('checkoutPage.now')} </label>
              </div>
              <div onClick={() => { this.saveOrderPeriod(); }} className={this.state.isDisable ? "disable" : ""}>
                <input
                  style={{ fontSize: "16px" }}
                  type="radio"
                  name="orderPeriod"
                  value="later"
                  className="order_method_radio"
                  {...(this.props.orderPeriod == "later" && {
                    checked: true
                  })}
                  id="nowMobile"
                />
                <label style={{ marginLeft: "10px" }}>{i18next.t('checkoutPage.later')}</label>
                {this.props.orderPeriod == "later" ? (
                  <div style={{ width: window.innerWidth > 800 ? '50%' : '100%' }}>
                    <input
                      style={{ fontSize: "16px" }}
                      type="datetime-local"
                      min={today}
                      max={maxDate}
                      value={this.state.laterdatetime}
                      className="form-control laterDateInput"
                      placeholder="DD/MM/YYYY"
                      name="laterdatetime"
                      onChange={this.handleValidTime}
                    />
                  </div>
                ) : null}
              </div>
            </div>
              <br /> </>}
            {/* here */}
            <h2 className="title" style={{ color: "#000000" }}>{i18next.t('checkoutPage.payment_method')}</h2>
            {orderType == "Delivery" && (
              <div>
                <ul>
                  <li onClick={() => {
                    this.savePaymentCash("Card")
                  }} className={this.state.isDisable ? "disable" : ""} >
                    <input
                      type="radio"
                      id="card"
                      name="drone"
                      value="Card"
                      {...(this.props.paymentMethod == "Card" && {
                        checked: true
                      })}
                      style={{ marginRight: "15px" }}
                    />
                    <label htmlFor="card" className="my-1">
                      {i18next.t('checkoutPage.card')}
                    </label>
                  </li>
                  <li onClick={() => {
                    // this.saveEvents({ event_name: "click on payment by cash" }, "events")
                    this.savePaymentCash("Cash")
                  }} className={this.state.isDisable ? "disable" : ""} >
                    <input
                      type="radio"
                      id="cash"
                      name="drone"
                      value="COD"
                      {...(this.props.paymentMethod == "Cash" && {
                        checked: true
                      })}

                      className="cash"
                      style={{ marginRight: "15px" }}
                    />
                    <label htmlFor="cash" className="my-1"  >

                      {i18next.t('checkoutPage.cash')}
                    </label>
                  </li>
                  {/* <li onClick={() => this.savePaymentCash("COD")}>
                    <input
                      type="radio"
                      id="card_delivery"
                      name="drone"
                      value="COD"
                      {...(this.props.paymentMethod == "COD" && {
                        checked: true
                      })}
                      className="cash"
                      style={{ marginRight: "15px" }}
                    />
                    <label htmlFor="card_delivery" className="my-1">
                      {i18next.t('checkoutPage.card_on_delivery')}
                    </label>
                  </li> */}
                </ul>
                {/* here */}
                {this.props.paymentMethod == 'Card' && <form id="nonseamless" className="m-0" method="post" name="redirect" action="https://secure.ccavenue.ae/transaction/transaction.do?command=initiateTransaction">
                  <input id="encRequest" type='hidden' name="encRequest" value={this.props.mashrik_bank_data && this.props.mashrik_bank_data.encRequest} />
                  <input name="access_code" type='hidden' id="access_code" value={this.props.mashrik_bank_data && this.props.mashrik_bank_data.accessCode} />
                  {/* <script>{redirect && redirect.submit()}</script> */}
                </form>}
              </div>
            )}

            {(orderType == "Pickup" || orderType == "qr-Pickup") && (
              <div>
                <ul>
                  <li onClick={() => {
                    this.savePaymentCash("Card")
                  }} className={this.state.isDisable ? "disable" : ""} >
                    <input 
                      type="radio"
                      id="card"
                      name="drone"
                      value="Card"
                      {...(this.props.paymentMethod == "Card" && {
                        checked: true
                      })}
                      onChange={this.handlePaymentCheck}
                      style={{ marginRight: "15px" }}
                    />
                    <label htmlFor="card" className="my-1">
                      {i18next.t('checkoutPage.card')}
                    </label>
                    {/* here */}
                    {this.props.paymentMethod == 'Card' && <form style={{ display: "none" }} id="nonseamless" method="post" name="redirect" action="https://secure.ccavenue.ae/transaction/transaction.do?command=initiateTransaction">
                      <input id="encRequest" type='hidden' name="encRequest" value={this.props.mashrik_bank_data && this.props.mashrik_bank_data.encRequest} />
                      <input name="access_code" type='hidden' id="access_code" value={this.props.mashrik_bank_data && this.props.mashrik_bank_data.accessCode} />
                      {/* <script>{redirect && redirect.submit()}</script> */}
                    </form>}
                  </li>
                  <li onClick={() => {
                    // this.saveEvents({ event_name: 'click on payment card on pickup' }, "events")
                    this.savePaymentCash("COP")
                  }} className={this.state.isDisable ? "disable" : ""}>
                    <input
                      type="radio"
                      id="cardpickup"
                      name="drone"
                      value="COD"
                      {...(this.props.paymentMethod == "COP" && {
                        checked: true
                      })}
                      className="cash"
                      onChange={this.handlePaymentCheck}
                      style={{ marginRight: "15px" }}
                    />
                    <label htmlFor="cardpickup" className="my-1">
                      {i18next.t('checkoutPage.card_on_pickup')}
                    </label>
                  </li>
                  <li onClick={() => {
                    // this.saveEvents({ event_name: 'click on payment by COD' }, "events")
                    this.savePaymentCash("Cash")
                  }} className={this.state.isDisable ? "disable" : ""}>
                    <input
                      type="radio"
                      id="cash"
                      name="drone"
                      value="COD"
                      {...(this.props.paymentMethod == "Cash" && {
                        checked: true
                      })}
                      onChange={this.handlePaymentCheck}
                      className="cash"
                      style={{ marginRight: "15px" }}
                    />
                    <label htmlFor="cash" className="my-1">
                      {i18next.t('checkoutPage.cash')}
                    </label>
                  </li>
                </ul>
              </div>
            )}

            <div className="clearfixButton">
              {/* {netWork == false && this.props.paymentMethod !== 'Card' && ( */}
              {netWork == false && (
                <button
                  onClick={this.confirmOrder}
                  // onClick={this.cardPayment}
                  disabled={!this.isGuestReadyCash()}
                  className="btn btn-primary float-right custom-default-red-button"
                  style={{ width: '240px' }}
                  id="roundb">
                  {/* here */}
                  {i18next.t('checkoutPage.place_order_btn')}
                </button>

              )}
              {/* {this.state.cardnetWork == true && this.props.paymentMethod !== "Card" && this.state.netWork == true && */}
              {this.state.cardnetWork == true && this.state.netWork == true &&
                <button
                  disabled={this.state.cardnetWork}
                  style={{ width: '240px' }}
                  className="btn btn-primary float-right custom-default-red-button"
                  id="roundb">
                  <i className="fa fa-spinner fa-spin"></i>
                  {i18next.t('checkoutPage.in_progress')}
                </button>
              }
            </div>
            {this.checkTotal < brand_details.min_cart_value && <p className='mt-1' style={{ color: "rgb(255, 131, 0)", textAlign: "center" }}>{lang == 'en' ? 'Total cart value should be greater than' : 'يجب أن تكون القيمة الإجمالية لسلة التسوق أكبر من'} {brand_details.min_cart_value}</p>}
            <Modal
              open={this.state.errModal}
              showCloseIcon={false}
              onClose={this.onCloseModal}
              center
            >
              <div style={{ paddingTop: "5%", paddingBottom: "2%" }}>
                <p>
                  <h6> {this.state.errText}</h6>
                </p>

                <div style={{ textAlign: "center" }}>
                  <button
                    // disabled={this.state.GotoHomeFlag}
                    onClick={() => { this.setState({ errModal: false }) }}
                    className="btn btn-sm btn-primary center custom-default-red-button">
                    {i18next.t('common.ok')}
                  </button>
                </div>
              </div>
            </Modal>
            {/* fail pos modal */}
            <Modal
              open={posFlag}
              showCloseIcon={false}
              onClose={this.onCloseModal}
              center
            >
              <div style={{ paddingTop: "5%", paddingBottom: "2%" }}>
                <p>
                  {/* here */}
                  {i18next.t('checkoutPage.order_failed_pos')}
                </p>

                <div style={{ textAlign: "center" }}>
                  <button
                    disabled={this.state.GotoHomeFlag}
                    onClick={() => { window.location.href = "/menu" }}
                    className="btn btn-sm btn-primary center custom-default-red-button">
                    {i18next.t('common.ok')}
                  </button>
                </div>
              </div>
            </Modal>
            <Modal
              open={this.props.paymentMethod == 'Cash' || this.props.paymentMethod == 'COP' || this.props.paymentMethod == 'Card' ? successFlag : false}
              showCloseIcon={false}
              onClose={this.onCloseModal}
              center
            >
              <div style={{ paddingTop: "5%", paddingBottom: "2%" }}>
                <p>
                  {i18next.t('checkoutPage.congratulations')} {i18next.t('checkoutPage.order_placed')}
                  <h6>{i18next.t('checkoutPage.order_id_is')} {order_id}</h6>
                </p>

                <div style={{ textAlign: "center" }}>
                  <button
                    disabled={this.state.GotoHomeFlag}
                    onClick={this.GotoHome}
                    className="btn btn-sm btn-primary center custom-default-red-button">
                    {i18next.t('common.ok')}
                  </button>
                </div>
              </div>
            </Modal>
            <Modal
              open={this.state.cardordermodal}
              showCloseIcon={false}
              onClose={this.onCloseModalCard}
              center
            >
              <div style={{ paddingTop: "5%", paddingBottom: "2%" }}>
                <p style={{ textAlign: 'center' }}>
                  {/* {i18next.t('checkoutPage.congratulations')} {i18next.t('checkoutPage.order_placed')} */}
                  Sorry your order could not proceed with card, want to order through cash?
                  {/* <h6>{i18next.t('checkoutPage.order_id_is')} {order_id}</h6> */}
                </p>

                <div style={{ textAlign: "center" }}>
                  <button
                    style={{ width: '100px' }}
                    disabled={this.state.GotoHomeFlag}
                    onClick={() => this.setState({ cardordermodal: false })}
                    className="btn btn-sm btn-primary center custom-default-red-button">
                    Yes
                    {/* {i18next.t('common.ok')} */}
                  </button>

                  <button
                    style={{ width: '100px' }}
                    disabled={this.state.GotoHomeFlag}
                    onClick={this.handleCloseCardModal}
                    className="btn btn-sm btn-primary center custom-default-red-button">
                    No
                    {/* {i18next.t('common.ok')} */}
                  </button>
                </div>
              </div>
            </Modal>
            <Modal
              open={futureOpen}
              showCloseIcon={false}
              onClose={this.onCloseModal}
              center
            >
              <div style={{ paddingTop: "5%", paddingBottom: "2%" }}>
                <p>{i18next.t('checkoutPage.congratulations')} {i18next.t('checkoutPage.order_placed')}</p>

                <div style={{ textAlign: "center" }}>
                  <button
                    onClick={this.GotoHome}
                    className="btn btn-sm btn-primary center custom-default-red-button">
                    {i18next.t('common.ok')}
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  };
  //here

  confirmOrder = async () => {
    const user_token: any = localStorage.getItem("token");
    let decoded: any = jwt_decode(user_token);
    let store_id: any = this.props.storeSelected && this.props.storeSelected.store_id
    let branch_code: any = this.props.storeSelected && this.props.storeSelected.branch_code
    let phone_number: any = localStorage.getItem("phoneNumber")
    let login_name: any = decoded?.customer?.login_name || "guest"
    let price: any;
    let payment_status: any;



    this.setState({ isDisable: true })
    let customer_address: any = sessionStorage.getItem("customer_address")
    // let params: any = queryString.parse(this.props.location.search)
    // const paramstoken = params && params.token;

    this.setState({ netWork: true, cardnetWork: true });
    //storeid
    let { storeSelected, paymentMethod, taxDataCard, taxDataCash } = this.props;
    let { device_info, device_os, ip_address, uniqueId } = this.state;
    let data: any = this.props.cart;
    let tax: any = 0;
    tax = paymentMethod == 'Cash' ? taxDataCash.tax_percent : taxDataCard.tax_percent
    payment_status = paymentMethod == 'Cash' ? "COD" : paymentMethod == "COP" ? "Card On Pickup" : "Card Pending"
    let cart: any = JSON.parse(data);

    let discount = 0;
    this.quantity = 0;
    cart.forEach((item: any) => {
      item.tax_percent = tax;
      item.check_number = '';
      if (item.combo) {
        if (item.combo.discount_price) {
          discount += item.quantity * Math.round(item.combo.combo_mrp_price - item.combo.discount_price);
        }
        if (item.combo.choices) {
          Object.keys(item.combo.choices).map(key => {
            item.combo.choices[key].map((element: any) => {
              if (element.selected == true && element.quantity > 0) {
                this.quantity += item.quantity * parseInt(element.quantity);
              }
            })
          })
        }
      } else {
        this.quantity += item.quantity;
        if (item.selectedsize.discount_price) {
          discount += item.quantity * Math.round(item.selectedsize.mrp - item.selectedsize.discount_price);
        }
      }
    })

    if (taxDataCash.tax_type == 1 || taxDataCash.tax_type == 0) {
      cart.forEach((item: any) => {
        if (item.combo) {
          item.tax_amount = (item.combo.discount_price && (item.combo.order_channel == 'online')) ? (item.combo.discount_price * tax / 100) * item.quantity : (item.combo.combo_mrp_price * tax / 100) * item.quantity;
          if (item.combo.choices) {
            Object.keys(item.combo.choices).map((key: any) => {
              item.combo.choices[key].map(
                (item: any, index: any) => {
                  if (item.selected == true) {
                    item.tax_percent = tax;
                    item.tax_amount = item.extra_price * tax / 100;
                  }
                })
            })
          }
          if (item.combo.modifiers) {
            Object.keys(item.combo.modifiers).map((key: any) => {
              item.combo.modifiers[key].map(
                (item: any, index: any) => {
                  if (item.selected == true) {
                    item.tax_percent = tax;
                    item.tax_amount = item.modifier_sale_price * tax / 100;
                  }
                })
            })
          }
        } else {
          let sizearray = JSON.parse(item.item.item_size);
          item.tax_amount = (sizearray[0].discount_price && (sizearray[0].order_channel == 'online')) ? (Math.round(sizearray[0].discount_price) * tax / 100) * item.quantity : (Math.round(sizearray[0].mrp) * tax / 100) * item.quantity;
          if (item.item.modifiers) {
            Object.keys(item.item.modifiers).map((key: any) => {
              item.item.modifiers[key].map(
                (item: any, index: any) => {
                  if (item.selected == true) {
                    item.tax_percent = tax;
                    item.tax_amount = item.modifier_sale_price * tax / 100;
                  }
                })
            })
          }
        }
      })
    }
    this.discount = discount;
    const token = await localStorage.getItem("token");
    let lat = sessionStorage.getItem("changeLat");
    let lng = sessionStorage.getItem("changeLng");
    let orderType = sessionStorage.getItem("orderType")
    let address = sessionStorage.getItem("address_save_session")
    let customer_phone = localStorage.getItem("phoneNumber")
    let customer_token = localStorage.getItem("token")
    let country = 'united arab emirates'
    if (this.props.orderType !== "" && !_.isEmpty(this.props.storeSelected)) {
      let amountOrder: any = "";
      if (taxDataCard.tax_type == 1) {
        amountOrder = (this.cartTotal + (this.cartTotal * taxDataCard.tax_percent) / 100)
      } else {
        amountOrder = this.cartTotal
      }
      let mashrikObj: any = {
        currency: "AED",
        amount: amountOrder,
        order_id: this.state.cardOrderId,
        address,
        customer_phone,
        customer_token,
        country,
        address_id: sessionStorage.getItem("address_id"),
        orderType: orderType
      }
      let deliveryfee: any = 0
      deliveryfee = storeSelected.state_id.delivery_fee
      if (orderType == 'Delivery' && parseFloat(deliveryfee) > 0) {
        Object.assign(mashrikObj, { delivery_fee: deliveryfee })
      }
      if (token) {
        let address_id = sessionStorage.getItem("address_id")
        let address: any = this.props.addressData && this.props.addressData.filter((data: any) => {
          return data.address_id == address_id
        })
        let curtlery: any = sessionStorage.getItem("curtlery")
        let obj: any = {
          order_status_code: 1,
          // order_grossprice: (taxDataCash.tax_type == 1) ? this.cartTotal + (this.cartTotal * tax / 100) : this.cartTotal,
          order_grossprice: (taxDataCash.tax_type == 1) ? this.cartTotal + parseFloat(this.props.taxAmount) : this.cartTotal,
          discount: discount,
          order_channel: "web",
          store_id: this.props.storeSelected && this.props.storeSelected.store_id,
          branch_code: this.props.storeSelected && this.props.storeSelected.branch_code,
          delivery_status: this.props.orderType,
          special_inst: curtlery == true || curtlery == 'true' ? this.props.special_ins + ' - Add Curtlery' : this.props.special_ins,
          payment_method: this.props.paymentMethod,
          uniqueOrderId: uniqueId,
          cartItems: cart,
          check_number: '',
          tax_percent: tax,
          quantity: this.quantity,
          lat: lat ? lat : 0,
          lng: lng ? lng : 0,
          // customer_id: this.props.CustomerId,
          "house/street": this.props.houseDetail,
          device_info: device_info,
          device_os: device_os,
          req_customer_ip_address: ip_address
        };
        if (!_.isEmpty(this.props.coupon)) {
          obj.coupon = this.props.coupon;
          obj.coupon_code = this.props.coupon.coupon_code;
          obj.coupon_redeem = 1;
          obj.coupon_discount_value = this.props.discountAmount;
        }
        if (this.props.paymentMethod === 'Card') {
          obj.cardOrderId = this.state.cardOrderId;
          obj.cardresponse = this.props.bank_api_success // here
          // obj.cardInfoo = cardInfo;
          // obj.cardInfo = this.cardPayment(obj)
        }
        if (this.props.orderType == "Delivery") {
          obj.order_netprice = this.couponDiscount > this.cartTotal ? this.cartTotal + this.couponDiscount - this.props.deliveryfee : this.cartTotal + this.couponDiscount - this.props.deliveryfee;
          obj.tax_amount = this.props.taxAmount;
          obj.order_grosspricewd = (taxDataCash.tax_type == 1) ? obj.order_netprice + parseFloat(this.props.taxAmount) : obj.order_netprice
          obj.delivery_fee = this.props.deliveryfee;
          obj.address_id = address_id
        } else if (this.props.orderType == "Pickup" || this.props.orderType == "qr-Pickup") {
          obj.order_netprice = this.couponDiscount > this.cartTotal ? this.cartTotal + this.couponDiscount : this.cartTotal + this.couponDiscount;
          obj.tax_amount = this.props.taxAmount;
          obj.order_grosspricewd = (taxDataCash.tax_type == 1) ? obj.order_netprice + parseFloat(this.totalTax) : this.cartTotal
        }
        if (customer_address && customer_address !== "" && this.props.orderType == "Delivery") {
          obj.delivery_address = customer_address
        }
        if (this.props.address_id != '') {
          obj.address_id = this.props.address_id
        }
        if (this.props.orderPeriod == "later") {
          if (storeSelected.isDays == 0) {
            this.compareLaterOpenClosingTime(storeSelected, obj, mashrikObj);
          }
          else if (storeSelected.isDays == 1) {
            this.compareLaterTimingbyEachDay(storeSelected, obj, mashrikObj)
          }
        } else if (this.props.orderPeriod == "now") {
          let { storeSelected } = this.props;
          if (storeSelected.isDays == 0) {
            this.compareOpenClosingTime(storeSelected, obj, mashrikObj);
          }
          else if (storeSelected.isDays == 1) {
            this.compareTimingbyEachDay(storeSelected, obj, mashrikObj)
          }
        }
      } else {
        //guest order
        if (!_.isEmpty(this.props.guestInfo)) {
          let address_id = sessionStorage.getItem("address_id")
          login_name = "guest"
          //price here
          price = taxDataCash.tax_type == 1 ? this.cartTotal + this.props.taxAmount : this.cartTotal - discount

          let address: any = this.props.addressData && this.props.addressData.filter((data: any) => {
            return data.address_id == address_id
          })
          let obj: any = {
            order_status_code: 1,
            order_grossprice: taxDataCash.tax_type == 1 ? this.cartTotal + this.props.taxAmount : this.cartTotal,
            discount: discount,
            store_id: this.props.storeSelected && this.props.storeSelected.store_id,
            branch_code: this.props.storeSelected && this.props.storeSelected.branch_code,
            delivery_status: this.props.orderType,
            order_channel: "web",
            payment_method: this.props.paymentMethod,
            cartItems: cart,
            special_inst: this.props.special_ins,
            check_number: '',
            tax_percent: tax,
            lat: lat ? lat : 0,
            lng: lng ? lng : 0,
            uniqueOrderId: uniqueId,
            // "house/street": this.props.houseDetail + " " + this.props.streetDetail,
            quantity: this.quantity,
            "house/street": this.props.houseDetail,
            device_info: device_info,
            device_os: device_os,
            req_customer_ip_address: ip_address
          };
          if (!_.isEmpty(this.props.coupon)) {
            obj.coupon = this.props.coupon;
            obj.coupon_code = this.props.coupon.coupon_code;
            obj.coupon_redeem = 1;
            obj.coupon_discount_value = this.props.discountAmount;
          }
          if (this.props.paymentMethod === 'Card') {
            obj.cardOrderId = this.state.cardOrderId;
            // obj.cardInfoo = cardInfo;
            // obj.cardInfo = this.cardPayment(obj)
          }
          if (this.props.orderType == "Delivery") {
            obj.order_netprice = this.couponDiscount > this.cartTotal ? this.cartTotal + this.couponDiscount - this.props.deliveryfee : this.cartTotal + this.couponDiscount - this.props.deliveryfee;
            obj.tax_amount = this.props.taxAmount;
            obj.order_grosspricewd = (taxDataCash.tax_type == 1) ? obj.order_netprice + parseFloat(this.props.taxAmount) : obj.order_netprice
            obj.delivery_fee = this.props.deliveryfee;
          } else if (this.props.orderType == "Pickup" || this.props.orderType == "qr-Pickup") {
            obj.order_netprice = this.couponDiscount > this.cartTotal ? this.cartTotal + this.couponDiscount : this.cartTotal + this.couponDiscount;
            obj.tax_amount = this.props.taxAmount;
            obj.order_grosspricewd = (taxDataCash.tax_type == 1) ? obj.order_netprice + parseFloat(this.totalTax) : obj.order_netprice
          }
          if (!_.isEmpty(this.props.guestInfo)) {
            obj.customer_id = this.props.guestInfo.customer_id;
            obj.guest = true;
          }
          if (customer_address && customer_address !== "") {
            // obj.delivery_address =this.props.extra_address_details + ' /' +this.props.address_save
            obj.delivery_address = customer_address
            // this.props.houseDetail !== ""
            // : this.props.address_save;
          }
          if (this.props.orderPeriod == "later") {
            if (storeSelected.isDays == 0) {
              this.compareLaterOpenClosingTime(storeSelected, obj, mashrikObj)
            }
            else if (storeSelected.isDays == 1) {
              this.compareLaterTimingbyEachDay(storeSelected, obj, mashrikObj)
            }
          } else if (this.props.orderPeriod == "now") {
            if (storeSelected.isDays == 0) {
              this.compareOpenClosingTime(storeSelected, obj, mashrikObj);
            }
            else if (storeSelected.isDays == 1) {
              this.compareTimingbyEachDay(storeSelected, obj, mashrikObj)
            }
          }
        } else {
          login_name = "guest"
          // in order to process details of guest who have checkmarker to become registered customer
          let obj: any = {
            order_status_code: 1,
            order_grossprice: taxDataCash.tax_type == 1 ? this.cartTotal + this.props.taxAmount : this.cartTotal,
            discount: discount,
            store_id: this.props.storeSelected && this.props.storeSelected.store_id,
            branch_code: this.props.storeSelected && this.props.storeSelected.branch_code,
            delivery_status: this.props.orderType,
            special_inst: this.props.special_ins,
            // "house/street": this.props.houseDetail + " " + this.props.streetDetail,
            cartItems: cart,
            order_channel: "web",
            payment_method: this.props.paymentMethod,
            customer_id: this.props.CustomerId,
            "house/street": this.props.houseDetail,
            uniqueOrderId: uniqueId,
            lat: lat ? lat : 0,
            lng: lng ? lng : 0,
            check_number: '',
            tax_percent: tax,
            quantity: this.quantity,
            device_info: device_info,
            device_os: device_os,
            req_customer_ip_address: ip_address
          };
          if (!_.isEmpty(this.props.coupon)) {
            obj.coupon = this.props.coupon;
            obj.coupon_code = this.props.coupon.coupon_code;
            obj.coupon_redeem = 1;
            obj.coupon_discount_value = this.props.discountAmount;
          }
          if (this.props.paymentMethod === 'Card') {
            obj.cardOrderId = this.state.cardOrderId
            // obj.cardInfoo = cardInfo
            // obj.cardInfo = this.cardPayment(obj)
          }
          if (this.props.orderType == "Delivery") {
            obj.order_netprice = this.couponDiscount > this.cartTotal ? this.cartTotal + this.couponDiscount - this.props.deliveryfee : this.cartTotal + this.couponDiscount - this.props.deliveryfee;
            obj.tax_amount = this.props.taxAmount;
            obj.order_grosspricewd = (taxDataCash.tax_type == 1) ? obj.order_netprice + parseFloat(this.props.taxAmount) : obj.order_netprice
            obj.delivery_fee = this.props.deliveryfee;
          } else if (this.props.orderType == "Pickup" || this.props.orderType == "qr-Pickup") {
            obj.order_netprice = this.couponDiscount > this.cartTotal ? this.cartTotal + this.couponDiscount : this.cartTotal + this.couponDiscount;
            obj.tax_amount = this.props.taxAmount;
            obj.order_grosspricewd = (taxDataCash.tax_type == 1) ? obj.order_netprice + parseFloat(this.totalTax) : obj.order_netprice
          }
          if (this.props.address_save !== "") {
            obj.delivery_address =
              // this.props.houseDetail !== ""
              this.props.extra_address_details + ' /' + this.props.address_save
            // : this.props.address_save;
          }
          if (this.props.orderPeriod == "later") {
            if (storeSelected.isDays == 0) {
              this.compareLaterOpenClosingTime(storeSelected, obj, mashrikObj)
            }
            else if (storeSelected.isDays == 1) {
              this.compareLaterTimingbyEachDay(storeSelected, obj, mashrikObj)
            }
          } else if (this.props.orderPeriod == "now") {
            let { storeSelected } = this.props;
            if (storeSelected.isDays == 0) {
              this.compareOpenClosingTime(storeSelected, obj, mashrikObj);
            }
            else if (storeSelected.isDays == 1) {
              this.compareTimingbyEachDay(storeSelected, obj, mashrikObj)
            }
          }
        }
      }
    }

    if (this.props.taxDataCard.tax_type == 1) {
      // price here
      price = (this.cartTotal + (this.cartTotal * this.props.taxDataCard.tax_percent) / 100)

    } else {
      //price here
      price = this.cartTotal
    }

    // const query = window.location.search;
    // const urlParams = new URLSearchParams(query);
    // const responseToken = urlParams.get('token');

    // if (!paramstoken || payment_status == "COD") {

    //   let paymeny_log_cart: any = {};
    //   let items = [];
    //   for (let i = 0; i < cart.length; i++) {
    //     let item: any = {}
    //     item["name"] = cart[i]?.item?.item_name
    //     item["qty"] = cart[i]?.quantity
    //     item["subTotal"] = cart[i]?.subTotal

    //     items[i] = item;
    //   }
    //   paymeny_log_cart["items"] = items;

    //   let payment_log_data = { store_id, unique_order_id, price, branch_code, phone_number, login_name, payment_status, cart: paymeny_log_cart }
    //   this.saveEvents(payment_log_data, "payment_log")
    // }
  };
  //For CARD Now store same time for all week Case
  compareOpenClosingTimeCard = async (storeSelected: any) => {
    let timeFlag: any = false
    let flag: any = await this.props.getTimezone(storeSelected.store_id).then((serverTime) => {
      //convert the current time 24 hour to 12 hour
      let CurrentHours: any = serverTime.split(":")[0];
      let currentMinutes: any = serverTime.split(":")[1];
      let CurrentAmOrPm = CurrentHours >= 12 ? 'pm' : 'am';
      CurrentHours = CurrentHours % 12 || 12;
      let Currenttime: any = CurrentHours + '' + CurrentAmOrPm;
      //define hours or minutes
      var timeStartMin = new Date(
        '01/01/2007 ' + storeSelected.store_open_time,
      ).getMinutes();
      var timeEndMin = new Date(
        '01/01/2007 ' + storeSelected.store_close_time,
      ).getMinutes();
      var timeStartHours = new Date(
        '01/01/2007 ' + storeSelected.store_open_time,
      ).getHours();
      var timeEndHours = new Date(
        '01/01/2007 ' + storeSelected.store_close_time,
      ).getHours();
      var hourDiff: any = timeEndHours - timeStartHours;
      let HourString = this.HoursArray(hourDiff, timeStartHours);
      let splitHourArray = HourString.split(":")

      if (splitHourArray.indexOf(Currenttime) !== -1) { //if Pick Hour with am/pm is exist in HourString
        if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
          if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
            //if Pick Hour with am/pm is exist in HourString at last index
            toast.error(i18next.t('errorMsg.store_closed'));
            this.setState({ netWork: false })
          } else {
            return timeFlag = true;
          }
        } else {
          if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
            if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
              if (currentMinutes < timeEndMin) {
                return timeFlag = true;
              } else {
                toast.error(i18next.t('errorMsg.store_closed'));
                this.setState({ netWork: false })

              }
            } else {
              return timeFlag = true;
            }
          } else { //if store opening or closing minute is not 00
            if (
              currentMinutes > timeStartMin ||
              currentMinutes < timeEndMin
            ) {
              return timeFlag = true;
            } else {
              toast.error(i18next.t('errorMsg.store_closed'));
              this.setState({ netWork: false })
            }
          }
        }
      } else {
        toast.error(i18next.t('errorMsg.store_closed'));
        this.setState({ netWork: false })
      }
      timeFlag = false;
      return timeFlag
    })
    return flag;
  }

  //For Cash Now store same time for all week Case
  compareOpenClosingTime = (storeSelected: any, obj: any, mashrikObj: any) => {
    this.props.getTimezone(storeSelected.store_id).then((serverTime) => {
      //convert the current time 24 hour to 12 hour
      let CurrentHours: any = serverTime.split(":")[0];
      let currentMinutes: any = serverTime.split(":")[1];
      let CurrentAmOrPm = CurrentHours >= 12 ? 'pm' : 'am';
      CurrentHours = CurrentHours % 12 || 12;
      let Currenttime: any = CurrentHours + '' + CurrentAmOrPm;
      //define hours or minutes
      var timeStartMin = new Date(
        '01/01/2007 ' + storeSelected.store_open_time,
      ).getMinutes();
      var timeEndMin = new Date(
        '01/01/2007 ' + storeSelected.store_close_time,
      ).getMinutes();
      var timeStartHours = new Date(
        '01/01/2007 ' + storeSelected.store_open_time,
      ).getHours();
      var timeEndHours = new Date(
        '01/01/2007 ' + storeSelected.store_close_time,
      ).getHours();
      var hourDiff: any = timeEndHours - timeStartHours;
      let HourString = this.HoursArray(hourDiff, timeStartHours);
      let splitHourArray = HourString.split(":")

      if (splitHourArray.indexOf(Currenttime) !== -1) { //if Pick Hour with am/pm is exist in HourString
        if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
          if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
            //if Pick Hour with am/pm is exist in HourString at last index
            toast.error(i18next.t('errorMsg.store_closed'));
            this.setState({ netWork: false })
          } else {
            let params: any = queryString.parse(this.props.location.search)
            const paramstoken = params && params.token;
            if (obj.payment_method == "Card") {
              this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
            } else {
              this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
            }
          }
        } else {
          if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
            if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
              if (currentMinutes < timeEndMin) {
                //i think here query payment token
                if (obj.payment_method == "Card") {
                  this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
                } else {
                  this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
                }
              } else {
                toast.error(i18next.t('errorMsg.store_closed'));
                this.setState({ netWork: false })

              }
            } else {
              if (obj.payment_method == "Card") {
                this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
              } else {
                this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
              }
            }
          } else { //if store opening or closing minute is not 00
            if (
              currentMinutes > timeStartMin ||
              currentMinutes < timeEndMin
            ) {
              if (obj.payment_method == "Card") {
                this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
              } else {
                this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
              }
            } else {
              toast.error(i18next.t('errorMsg.store_closed'));
              this.setState({ netWork: false })

            }
          }
        }
      } else {
        toast.error(i18next.t('errorMsg.store_closed'));
        this.setState({ netWork: false })
      }
    })
  }
  //For CARD Now store different times for all week Case
  compareTimingbyEachDayCard = async (storeSelected: any) => {
    let timeFlag: any = false
    let flag: any = await this.props.getTimezoneForLater(storeSelected.store_id).then((serverTime) => {
      let dateIndexServer = serverTime.date.indexOf("T")
      let serverDate = serverTime.date.slice(0, dateIndexServer)
      let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      let weekDaysTimingArr = JSON.parse(storeSelected.daysTiming)
      let todayDay = new Date(serverDate).getDay();
      let dayName = days[todayDay];
      weekDaysTimingArr.map((storeDay: any) => {
        if (dayName == storeDay.day) {
          let today = new Date();
          //convert the current time 24 hour to 12 hour
          let CurrentHours: any = today.getHours();
          let currentMinutes: any = today.getMinutes();
          let CurrentAmOrPm = CurrentHours >= 12 ? 'pm' : 'am';
          CurrentHours = CurrentHours % 12 || 12;
          let Currenttime: any = CurrentHours + '' + CurrentAmOrPm;
          //define hours or minutes
          var timeStartMin = new Date(
            '01/01/2007 ' + storeDay.opening,
          ).getMinutes();
          var timeEndMin = new Date(
            '01/01/2007 ' + storeDay.closing,
          ).getMinutes();
          var timeStartHours = new Date(
            '01/01/2007 ' + storeDay.opening,
          ).getHours();
          var timeEndHours = new Date(
            '01/01/2007 ' + storeDay.closing,
          ).getHours();
          var hourDiff: any = timeEndHours - timeStartHours;
          let HourString = this.HoursArray(hourDiff, timeStartHours);
          let splitHourArray = HourString.split(":")

          if (splitHourArray.indexOf(Currenttime) !== -1) { //if Pick Hour with am/pm is exist in HourString
            if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
              if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
                //if Pick Hour with am/pm is exist in HourString at last index
                toast.error(i18next.t('errorMsg.store_closed'));
                this.setState({ netWork: false })
              } else {
                return timeFlag = true;
              }
            } else {
              if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
                if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
                  if (currentMinutes < timeEndMin) {
                    return timeFlag = true;
                  } else {
                    toast.error(i18next.t('errorMsg.store_closed'));
                    this.setState({ netWork: false })
                  }
                } else {
                  return timeFlag = true;
                }
              } else { //if store opening or closing minute is not 00
                if (
                  currentMinutes > timeStartMin ||
                  currentMinutes < timeEndMin
                ) {
                  return timeFlag = true;
                } else {
                  toast.error(i18next.t('errorMsg.store_closed'));
                  this.setState({ netWork: false })
                }
              }
            }
          } else {
            toast.error(i18next.t('errorMsg.store_closed'));
            this.setState({ netWork: false })
          }
        }
      })
      return timeFlag
    });
    return flag;
  }


  //For Cash Now store different times for all week Case
  compareTimingbyEachDay = (storeSelected: any, obj: any, mashrikObj: any) => {
    this.props.getTimezoneForLater(storeSelected.store_id).then((serverTime: any) => {
      let dateIndexServer = serverTime.date.indexOf("T")
      let serverDate = serverTime.date.slice(0, dateIndexServer)

      let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      let weekDaysTimingArr = JSON.parse(storeSelected.daysTiming)
      let todayDay = new Date(serverDate).getDay();
      let dayName = days[todayDay];
      weekDaysTimingArr.map((storeDay: any) => {
        if (dayName == storeDay.day) {
          let today = new Date();
          //convert the current time 24 hour to 12 hour
          // let CurrentHours: any = today.getHours();
          // let currentMinutes: any = today.getMinutes();
          let CurrentHours: any = serverTime.time.split(":")[0]
          let currentMinutes: any = serverTime.time.split(":")[1]
          let CurrentAmOrPm = CurrentHours >= 12 ? 'pm' : 'am';
          CurrentHours = CurrentHours % 12 || 12;
          let Currenttime: any = CurrentHours + '' + CurrentAmOrPm;
          //define hours or minutes
          var timeStartMin = new Date(
            '01/01/2007 ' + storeDay.opening,
          ).getMinutes();
          var timeEndMin = new Date(
            '01/01/2007 ' + storeDay.closing,
          ).getMinutes();
          var timeStartHours = new Date(
            '01/01/2007 ' + storeDay.opening,
          ).getHours();
          var timeEndHours = new Date(
            '01/01/2007 ' + storeDay.closing,
          ).getHours();
          var hourDiff: any = timeEndHours - timeStartHours;
          let HourString = this.HoursArray(hourDiff, timeStartHours);
          let splitHourArray = HourString.split(":")

          if (splitHourArray.indexOf(Currenttime) !== -1) { //if Pick Hour with am/pm is exist in HourString
            if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
              if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
                //if Pick Hour with am/pm is exist in HourString at last index
                toast.error(i18next.t('errorMsg.store_closed'));
                this.setState({ netWork: false })
              } else {
                let params: any = queryString.parse(this.props.location.search)
                const paramstoken = params && params.token;
                if (obj.payment_method == "Card") {
                  this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
                } else {
                  this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
                }
              }
            } else {
              if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
                if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
                  if (currentMinutes < timeEndMin) {
                    let params: any = queryString.parse(this.props.location.search)
                    const paramstoken = params && params.token;
                    if (obj.payment_method == "Card") {
                      this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
                    } else {
                      this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
                    }
                  } else {
                    toast.error(i18next.t('errorMsg.store_closed'));
                    this.setState({ netWork: false })
                  }
                } else {
                  let params: any = queryString.parse(this.props.location.search)
                  const paramstoken = params && params.token;
                  if (obj.payment_method == "Card") {
                    this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
                  } else {
                    this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
                  }
                }
              } else { //if store opening or closing minute is not 00
                if (
                  currentMinutes > timeStartMin ||
                  currentMinutes < timeEndMin
                ) {
                  let params: any = queryString.parse(this.props.location.search)
                  const paramstoken = params && params.token;
                  if (obj.payment_method == "Card") {
                    this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
                  } else {
                    this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
                  }
                } else {
                  toast.error(i18next.t('errorMsg.store_closed'));
                  this.setState({ netWork: false })

                }
              }
            }
          } else {
            toast.error(i18next.t('errorMsg.store_closed'));
            this.setState({ netWork: false })
          }
          return;
        }
      })
    })
  }
  //For CARD Later store same times for all week Case
  compareLaterOpenClosingTimeCard = (storeSelected: any) => {
    this.props.getTimezoneForLater(this.props.storeSelected.store_id).then((serverTime) => {
      let dateIndexServer = serverTime.date.indexOf("T")
      let serverDate = serverTime.date.slice(0, dateIndexServer)
      let dateIndexClient = this.state.laterdatetime.indexOf("T")
      let clientDate = this.state.laterdatetime.slice(0, dateIndexClient)
      let todayValidDate: any = new Date();
      let t = todayValidDate.getDate()
      let validDate = parseInt(t) == parseInt(serverDate.split("-")[2]) && serverDate.split("-")[0] == clientDate.split("-")[0] && serverDate.split("-")[1] == clientDate.split("-")[1] && (parseInt(serverDate.split("-")[2]) == parseInt(clientDate.split("-")[2]) || parseInt(serverDate.split("-")[2]) + 1 == parseInt(clientDate.split("-")[2]))
      if (validDate) {
        let laterdatetime = new Date(this.state.laterdatetime);
        let futureHours: any = laterdatetime.getHours();
        let futureMinutes: any = laterdatetime.getMinutes();
        let futureAmOrPm = futureHours >= 12 ? 'pm' : 'am';
        futureHours = futureHours % 12 || 12;
        let futuretime: any = futureHours + '' + futureAmOrPm;
        //define hours or minutes
        var timeStartMin = new Date(
          '01/01/2007 ' + storeSelected.store_open_time,
        ).getMinutes();
        var timeEndMin = new Date(
          '01/01/2007 ' + storeSelected.store_close_time,
        ).getMinutes();
        var timeStartHours = new Date(
          '01/01/2007 ' + storeSelected.store_open_time,
        ).getHours();
        var timeEndHours = new Date(
          '01/01/2007 ' + storeSelected.store_close_time,
        ).getHours();
        var hourDiff: any = timeEndHours - timeStartHours;
        let HourString = this.HoursArray(hourDiff, timeStartHours);
        let splitHourArray = HourString.split(":")
        if (splitHourArray.indexOf(futuretime) !== -1) { //if Pick Hour with am/pm is exist in HourString
          if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
            if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
              //if Pick Hour with am/pm is exist in HourString at last index
              toast.error(i18next.t('errorMsg.store_closed'));
              this.setState({ netWork: false })
            } else {
              return true;
            }
          } else {
            if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
              if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
                if (futureMinutes < timeEndMin) {
                  return true;
                } else {
                  toast.error(i18next.t('errorMsg.store_closed'));
                  this.setState({ netWork: false })
                }
              } else {
                return true;
              }
            } else { //if store opening or closing minute is not 00
              if (
                futureMinutes > timeStartMin ||
                futureMinutes < timeEndMin
              ) {
                return true;
              } else {
                toast.error(i18next.t('errorMsg.store_closed'));
                this.setState({ netWork: false })
              }
            }
          }
        } else {
          toast.error(i18next.t('errorMsg.store_closed'));
          this.setState({ netWork: false })
        }
      }
      else {
        toast.error(i18next.t('errorMsg.date_invalid'));
        this.setState({ netWork: false })
      }
      return false;
    })
  }

  //For Cash Later store same times for all week Case
  compareLaterOpenClosingTime = (storeSelected: any, obj: any, mashrikObj: any) => {
    this.props.getTimezoneForLater(this.props.storeSelected.store_id).then((serverTime) => {
      let dateIndexServer = serverTime.date.indexOf("T")
      let serverDate = serverTime.date.slice(0, dateIndexServer)
      let dateIndexClient = this.state.laterdatetime.indexOf("T")
      let clientDate = this.state.laterdatetime.slice(0, dateIndexClient)
      let todayValidDate: any = new Date();
      let t = todayValidDate.getDate()

      let validDate = parseInt(t) == parseInt(serverDate.split("-")[2]) && serverDate.split("-")[0] == clientDate.split("-")[0] && serverDate.split("-")[1] == clientDate.split("-")[1] && (parseInt(serverDate.split("-")[2]) == parseInt(clientDate.split("-")[2]) || parseInt(serverDate.split("-")[2]) + 1 == parseInt(clientDate.split("-")[2]))

      if (validDate) {
        let addedTime: any = null;
        let today = new Date();
        let todayHours = today.getHours()

        if (todayHours != parseInt(serverTime.time.split(":")[0])) {
          toast.error(i18next.t('errorMsg.date_invalid'));
          this.setState({ netWork: false })
          this.setState({ isDisable: false })
          return
        }
        obj.future_status = 1;
        obj.future_date = moment(this.state.laterdatetime).utc(true).format('YYYY-MM-DD HH:mm')
        obj.laterdatetime = new Date(this.state.laterdatetime);
        let futureHours: any = obj.laterdatetime.getHours();
        let futureMinutes: any = obj.laterdatetime.getMinutes();
        if (addedTime) {
          futureHours = addedTime.split(":")[0];
          futureMinutes = addedTime.split(":")[1];
        }
        let futureAmOrPm = futureHours >= 12 ? 'pm' : 'am';
        futureHours = futureHours % 12 || 12;
        let futuretime: any = futureHours + '' + futureAmOrPm;
        //define hours or minutes
        var timeStartMin = new Date(
          '01/01/2007 ' + storeSelected.store_open_time,
        ).getMinutes();
        var timeEndMin = new Date(
          '01/01/2007 ' + storeSelected.store_close_time,
        ).getMinutes();
        var timeStartHours = new Date(
          '01/01/2007 ' + storeSelected.store_open_time,
        ).getHours();
        var timeEndHours = new Date(
          '01/01/2007 ' + storeSelected.store_close_time,
        ).getHours();
        var hourDiff: any = timeEndHours - timeStartHours;
        let HourString = this.HoursArray(hourDiff, timeStartHours);
        let splitHourArray = HourString.split(":")
        if (splitHourArray.indexOf(futuretime) !== -1) { //if Pick Hour with am/pm is exist in HourString
          if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
            if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
              //if Pick Hour with am/pm is exist in HourString at last index
              toast.error(i18next.t('errorMsg.store_closed'));
              this.setState({ netWork: false })
            } else {
              let params: any = queryString.parse(this.props.location.search)
              const paramstoken = params && params.token;
              if (obj.payment_method == "Card") {
                this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
              } else {
                this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
              }
            }
          } else {
            if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
              if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
                if (futureMinutes < timeEndMin) {
                  let params: any = queryString.parse(this.props.location.search)
                  const paramstoken = params && params.token;
                  if (obj.payment_method == "Card") {
                    this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
                  } else {
                    this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
                  }
                } else {
                  toast.error(i18next.t('errorMsg.store_closed'));
                  this.setState({ netWork: false })
                }
              } else {
                let params: any = queryString.parse(this.props.location.search)
                const paramstoken = params && params.token;
                if (obj.payment_method == "Card") {
                  this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
                } else {
                  this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
                }
              }
            } else { //if store opening or closing minute is not 00
              if (
                futureMinutes > timeStartMin ||
                futureMinutes < timeEndMin
              ) {
                let params: any = queryString.parse(this.props.location.search)
                const paramstoken = params && params.token;
                if (obj.payment_method == "Card") {
                  this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
                } else {
                  this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
                }
              } else {
                toast.error(i18next.t('errorMsg.store_closed'));
                this.setState({ netWork: false })
              }
            }
          }
        } else {
          toast.error(i18next.t('errorMsg.store_closed'));
          this.setState({ netWork: false })
        }
      } else {
        toast.error(i18next.t('errorMsg.date_invalid'));
        this.setState({ netWork: false })
      }
    });
  }

  compareLaterTimingbyEachDayCard = (storeSelected: any) => {
    this.props.getTimezoneForLater(this.props.storeSelected.store_id).then((serverTime) => {
      let dateIndexServer = serverTime.date.indexOf("T")
      let serverDate = serverTime.date.slice(0, dateIndexServer)
      let dateIndexClient = this.state.laterdatetime.indexOf("T")
      let clientDate = this.state.laterdatetime.slice(0, dateIndexClient)
      let todayValidDate: any = new Date();
      let t = todayValidDate.getDate()
      let validDate = parseInt(t) == parseInt(serverDate.split("-")[2]) && serverDate.split("-")[0] == clientDate.split("-")[0] && serverDate.split("-")[1] == clientDate.split("-")[1] && (parseInt(serverDate.split("-")[2]) == parseInt(clientDate.split("-")[2]) || parseInt(serverDate.split("-")[2]) + 1 == parseInt(clientDate.split("-")[2]))
      if (validDate) {
        let today = new Date();
        let todayHours = today.getHours()
        if (todayHours != parseInt(serverTime.time.split(":")[0])) {
          toast.error(i18next.t('errorMsg.date_invalid'));
          this.setState({ netWork: false })
          return
        }
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let weekDaysTimingArr = JSON.parse(storeSelected.daysTiming)
        let futureDay = new Date(this.state.laterdatetime).getDay();
        let dayName = days[futureDay];
        weekDaysTimingArr.map((storeDay: any) => {
          if (dayName == storeDay.day) {
            let laterdatetime = new Date(this.state.laterdatetime);
            let futureHours: any = laterdatetime.getHours();
            let futureMinutes: any = laterdatetime.getMinutes();
            let futureAmOrPm = futureHours >= 12 ? 'pm' : 'am';
            futureHours = futureHours % 12 || 12;
            let futuretime: any = futureHours + '' + futureAmOrPm;
            //define hours or minutes
            var timeStartMin = new Date(
              '01/01/2007 ' + storeDay.opening,
            ).getMinutes();
            var timeEndMin = new Date(
              '01/01/2007 ' + storeDay.closing,
            ).getMinutes();
            var timeStartHours = new Date(
              '01/01/2007 ' + storeDay.opening,
            ).getHours();
            var timeEndHours = new Date(
              '01/01/2007 ' + storeDay.closing,
            ).getHours();
            var hourDiff: any = timeEndHours - timeStartHours;
            let HourString = this.HoursArray(hourDiff, timeStartHours);
            let splitHourArray = HourString.split(":")
            if (splitHourArray.indexOf(futuretime) !== -1) { //if Pick Hour with am/pm is exist in HourString
              if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
                if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
                  //if Pick Hour with am/pm is exist in HourString at last index
                  toast.error(i18next.t('errorMsg.store_closed'));
                  this.setState({ netWork: false })
                } else {
                  return true;
                }
              } else {
                if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
                  if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
                    if (futureMinutes < timeEndMin) {
                      return true;
                    } else {
                      toast.error(i18next.t('errorMsg.store_closed'));
                      this.setState({ netWork: false })
                    }
                  } else {
                    return true;
                  }
                } else { //if store opening or closing minute is not 00
                  if (
                    futureMinutes > timeStartMin ||
                    futureMinutes < timeEndMin
                  ) {
                    return true;
                  } else {
                    toast.error(i18next.t('errorMsg.store_closed'));
                    this.setState({ netWork: false })
                  }
                }
              }
            } else {
              toast.error(i18next.t('errorMsg.store_closed'));
              this.setState({ netWork: false })
            }
          }
        })
      } else {
        toast.error(i18next.t('errorMsg.date_invalid'));
        this.setState({ netWork: false })
      }
      return false;
    })
  }


  compareLaterTimingbyEachDay = (storeSelected: any, obj: any, mashrikObj: any) => {
    this.props.getTimezoneForLater(this.props.storeSelected.store_id).then(serverTime => {
      let dateIndexServer = serverTime.date.indexOf("T")
      let serverDate = serverTime.date.slice(0, dateIndexServer)
      let dateIndexClient = this.state.laterdatetime.indexOf("T")
      let clientDate = this.state.laterdatetime.slice(0, dateIndexClient)
      let todayValidDate: any = new Date();
      let t = todayValidDate.getDate()
      let validDate = parseInt(t) == parseInt(serverDate.split("-")[2]) && serverDate.split("-")[0] == clientDate.split("-")[0] && serverDate.split("-")[1] == clientDate.split("-")[1] && (parseInt(serverDate.split("-")[2]) == parseInt(clientDate.split("-")[2]) || parseInt(serverDate.split("-")[2]) + 1 == parseInt(clientDate.split("-")[2]))
      if (validDate) {
        let today = new Date();
        let todayHours = today.getHours()

        if (todayHours != parseInt(serverTime.time.split(":")[0])) {
          toast.error(i18next.t('errorMsg.date_invalid'));
          this.setState({ netWork: false })
          return
        }
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let weekDaysTimingArr = JSON.parse(storeSelected.daysTiming)
        let futureDay = new Date(this.state.laterdatetime).getDay();
        let dayName = days[futureDay];
        obj.future_status = 1;
        obj.future_date = moment(this.state.laterdatetime).utc(true).format('YYYY-MM-DD HH:mm')
        weekDaysTimingArr.map((storeDay: any) => {
          if (dayName == storeDay.day) {
            obj.laterdatetime = new Date(this.state.laterdatetime);
            let futureHours: any = obj.laterdatetime.getHours();
            let futureMinutes: any = obj.laterdatetime.getMinutes();
            let futureAmOrPm = futureHours >= 12 ? 'pm' : 'am';
            futureHours = futureHours % 12 || 12;
            let futuretime: any = futureHours + '' + futureAmOrPm;
            //define hours or minutes
            var timeStartMin = new Date(
              '01/01/2007 ' + storeDay.opening,
            ).getMinutes();
            var timeEndMin = new Date(
              '01/01/2007 ' + storeDay.closing,
            ).getMinutes();
            var timeStartHours = new Date(
              '01/01/2007 ' + storeDay.opening,
            ).getHours();
            var timeEndHours = new Date(
              '01/01/2007 ' + storeDay.closing,
            ).getHours();
            var hourDiff: any = timeEndHours - timeStartHours;
            let HourString = this.HoursArray(hourDiff, timeStartHours);
            let splitHourArray = HourString.split(":")
            if (splitHourArray.indexOf(futuretime) !== -1) { //if Pick Hour with am/pm is exist in HourString
              if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
                if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
                  //if Pick Hour with am/pm is exist in HourString at last index
                  toast.error(i18next.t('errorMsg.store_closed'));
                  this.setState({ netWork: false })
                } else {
                  let params: any = queryString.parse(this.props.location.search)
                  const paramstoken = params && params.token;
                  if (obj.payment_method == "Card") {
                    this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
                  } else {
                    this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
                  }
                }
              } else {
                if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
                  if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
                    if (futureMinutes < timeEndMin) {
                      let params: any = queryString.parse(this.props.location.search)
                      const paramstoken = params && params.token;
                      if (obj.payment_method == "Card") {
                        this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
                      } else {
                        this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
                      }
                    } else {
                      toast.error(i18next.t('errorMsg.store_closed'));
                      this.setState({ netWork: false })

                    }
                  } else {
                    let params: any = queryString.parse(this.props.location.search)
                    const paramstoken = params && params.token;
                    if (obj.payment_method == "Card") {
                      this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
                    } else {
                      this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
                    }
                  }
                } else { //if store opening or closing minute is not 00
                  if (
                    futureMinutes > timeStartMin ||
                    futureMinutes < timeEndMin
                  ) {
                    let params: any = queryString.parse(this.props.location.search)
                    const paramstoken = params && params.token;
                    if (obj.payment_method == "Card") {
                      this.props.saveCardOrder(obj, mashrikObj, this.updateLoaderButton);
                    } else {
                      this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
                    }
                  } else {
                    toast.error(i18next.t('errorMsg.store_closed'));
                    this.setState({ netWork: false })
                  }
                }
              }
            } else {
              toast.error(i18next.t('errorMsg.store_closed'));
              this.setState({ netWork: false })
            }
          }
        })
      } else {
        toast.error(i18next.t('errorMsg.date_invalid'));
        this.setState({ netWork: false })
      }
    })


  }

  islocationReady = () => {
    let {
      orderType,
      stores
    } = this.props;
    if (orderType == "Delivery") {
      return stores.length > 0;
    } else if (orderType == "Pickup") {
      return stores.length > 0;
    }
  };
  isGuestReady = () => {
    let {
      firstname,
      lastname,
      phonenumber,
      isValidEmail,
      laterdatetime
    } = this.state;
    let { orderPeriod } = this.props;

    if (orderPeriod == "now") {
      return (
        firstname !== "" &&
        lastname !== "" &&
        phonenumber.length == 12 &&
        orderPeriod !== "" &&
        isValidEmail == true
      );
    } else if (orderPeriod == "later") {
      return (
        firstname !== "" &&
        lastname !== "" &&
        phonenumber.length == 12 &&
        orderPeriod !== "" &&
        laterdatetime !== "" &&
        isValidEmail == true &&
        this.validTimeFlag == true
      );
    }
  };
  render() {
    return (
      <main className="main">
        <UseTrackEvent location={window.location} setAddEvent={this.handleSetAddEvent} />
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
          <div style={{ position: "relative", zIndex: 2 }} className="container">
            <div className="backBtn-wrapper mobile-view-page-contactus">
              <Link to={`/cart`}>
                <div style={{ marginBottom: '20px' }} className="org-header__btn--circle">
                  {/* <svg data-v-c2f068f0="" fill="#820c24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" data-spec="icon-arrow-left" className="org-header__icon" style={{ width: '15px', height: '15px' }}><path d="M74.363 79.637a8.997 8.997 0 010 12.727C72.605 94.121 70.303 95 68 95s-4.605-.879-6.363-2.637l-36-36a8.997 8.997 0 010-12.727l36-36a8.997 8.997 0 0112.727 0 8.997 8.997 0 010 12.727L44.727 50l29.636 29.637z"></path></svg> */}
                  <span className="iconify"
                    style={{
                      // marginRight: "10px",
                      width: "45px",
                      height: "45px",
                      color: "#179145"
                    }}
                    data-icon="bi:arrow-left-circle">
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </nav>
        <div className="container">
          <ul className="checkout-progress-bar">
            <li
              id="responsiveTabs"
              {...((this.props.tabName == "payment") && {
                className: "active",
              })}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.history.push({ pathname: "/checkout/contact", search: window.location.search })
                this.props.setTabName("contact");
              }}
            >
              <span>{i18next.t('checkoutPage.account')}</span>
            </li>
            <li
              id="responsiveTabs"
              {...((this.props.tabName == "payment") && {
                className: "active",
              })}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.history.push({ pathname: "/checkout/location", search: window.location.search })
                this.props.setTabName("location");
              }}
            >
              <span>{i18next.t('checkoutPage.set_location')}</span>
            </li>
            <li
              id="responsiveTabs"
              {...(this.props.tabName == "payment" && {
                className: "active",
              })}
              style={{ cursor: "pointer" }}
              {...(this.islocationReady() &&
                this.isGuestReady()
              )}
            >
              <span>{i18next.t('checkoutPage.payment')}</span>
            </li>
          </ul>
          {this.props.tabName == "payment" && this.paymentTabs(this.props.open)}
        </div>

        <div className="mb-6"></div>
      </main>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    stores: state.login.storesList,
    cart: state.login.cartData,
    orderType: state.login.orderType,
    paymentMethod: state.login.paymentMethod,
    orderPeriod: state.login.orderPeriod,
    deliveryfee: state.login.deliveryfee,
    houseDetail: state.login.houseDetail,
    storeSelected: state.login.storeSelected,
    address_save: state.login.address_save,
    taxData: state.login.taxData,
    guestInfo: state.login.guestInfo,
    CustomerId: state.login.CustomerId,
    coupanCode: state.login.coupanCode,
    coupon: state.login.coupon,
    tabName: state.login.tabName,
    open: state.login.successFlag,
    posFlag: state.login.posFlag,
    futureOpen: state.login.futuresuccessFlag,
    order_id: state.login.order_id,
    taxDataCash: state.login.taxDataCash,
    address_id: state.login.address_id,
    taxDataCard: state.login.taxDataCard,
    taxAmount: state.login.taxAmount,
    discountAmount: state.login.discountAmount,
    special_ins: state.login.special_instructions,
    lat: state.login.lat,
    lng: state.login.lng,
    detail_address: state.login.detail_address,
    cardInfo: state.login.cardInfo,
    extra_address_details: state.login.extra_address_details,
    mashrik_bank_data: state.login.mashrik_bank_data,
    addressData: state.login.addressData,
    bank_api_success: state.login.bank_api_success,
    brand_details: state.login.brand_details
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setTabName: (tabName: any) => {
      dispatch(setTabName(tabName));
    },
    saveOrderPeriod: (period: any) => {
      dispatch(saveOrderPeriod(period));
    },
    getCart: function () {
      dispatch(getCart());
    },
    saveOrder: function (data: any, store: any, tax: any) {
      dispatch(saveOrder(data, store, tax));
    },
    applyCoupon: function (coupon: any) {
      dispatch(applyCoupon(coupon));
    },
    savePaymentMethod: function (payment_method: any) {
      dispatch(savePaymentMethod(payment_method));
    },
    saveOrderType: function (type: any) {
      dispatch(saveOrderType(type));
    },
    SaveTaxValue: function (taxAmount: any) {
      dispatch(SaveTaxValue(taxAmount));
    },
    saveStore: (store: any) => {
      dispatch(saveStore(store));
    },
    SaveDiscountValue: function (discountAmount: any) {
      dispatch(SaveDiscountValue(discountAmount));
    },
    saveDeliveryFee: (deliveryfee: any) => {
      dispatch(saveDeliveryFee(deliveryfee));
    },
    cardTransaction: function (data: any) {
      dispatch(cardTransaction(data));
    },
    getTimezone: function (id: number) {
      return dispatch(getTimezone(id))
    },
    getTimezoneForLater: function (id: number) {
      return dispatch(getTimezoneForLater(id))
    },
    resetCoupon: function (obj: any) {
      dispatch(resetCoupon(obj))
    },
    getCardDetails: function (obj: any) {
      dispatch(getCardDetails(obj))
    },
    MashrikPaymentData: (data: any, total: any) => {
      dispatch(MashrikPaymentData(data))
    },
    saveAddress: function (detailAddress: any) {
      dispatch(saveAddress(detailAddress))
    },
    addressesListk: function () {
      dispatch(addressesListk());
    },
    saveTaxDetails: (statename: any) => {
      dispatch(saveTaxDetails(statename))
    },
    saveCouponData: (data: any) => {
      dispatch(saveCouponData(data))
    },
    saveGuestAddress: function (address: any) {
      dispatch(saveGuestAddress(address));
    },
    saveAddressExtraDetails: (details: any) => {
      dispatch(saveAddressExtraDetails(details))
    },
    saveCardOrder: function (data: any, mashrikObj: any, updateLoaderButton: any) {
      dispatch(saveCardOrder(data, mashrikObj, updateLoaderButton));
    },
    dumpCardTempOrder: (order_id: any) => {
      dispatch(dumpCardTempOrder(order_id))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPayment);
