import React, { Component } from "react";
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import PageNotFound from "../components/pageNotFound/index";
import { Redirect } from "react-router-dom";

class page404 extends Component<{history:any},{}> {
  componentDidMount(){
    let pageHistory = this.props.history ? this.props.history : 'Not Found';
    let windowObj = (window as any)
    windowObj.dataLayer.push({desc: null });
    windowObj.dataLayer.push({
        event: '404_error',
        desc:{pageHistory}
    });
    // document.title = "Forgot Password - Tortilla";
    document.title = "404 - HALLA"
  }
  render() {
    // if (!localStorage.mainToken) {
    //   return <Redirect to="/landing" />
    // }
    return (
      <div className="page-wrapper">
        <div id="page-wrap">
          <Header history={this.props.history}/>
          <PageNotFound history={this.props.history} />
          <Footer />
        </div>
        
      </div>
    );
  }
}

export default page404;
