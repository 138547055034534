import React, { Component } from "react";
import { NInfoProps, NInfoStates } from "../Interface/loginInterface";
import { connect } from "react-redux";
import "./style.css";
import { toast } from "react-toastify";
import i18next from "i18next";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

toast.configure();

class index extends Component<NInfoProps, NInfoStates> {
  constructor(props: any) {
    super(props);
    this.state = {
      information: [
        {
          "id": 256,
          "link": "https://tortillaarabia.com/nutrition_products/medium-burito/",
          "meal_name": "Medium Burrito / Burrito Bowl",
          "nutritionalCat": [
            {
              "group_name": "Medium Burrito",
              "nutritionalSubItem": [
                {
                  "item_name": "Medium Wrap",
                  "kcal": "195.4",
                  "KJ": "826.3",
                  "FAT": "3.2",
                  "SAT_FAT": "1.6",
                  "CARBS": "36.2",
                  "SUGARS": "2.1",
                  "FIBRE": "1.5",
                  "PROTEIN": "4.8",
                  "SALT": "0.7"
                },
                {
                  "item_name": "Mexican Rice",
                  "kcal": "164",
                  "KJ": "693",
                  "FAT": "1.8",
                  "SAT_FAT": "0.1",
                  "CARBS": "33.8",
                  "SUGARS": "1.1",
                  "FIBRE": "0.7",
                  "PROTEIN": "3.4",
                  "SALT": "0.5"
                },
                {
                  "item_name": "Cilantro Rice",
                  "kcal": "146",
                  "KJ": "619",
                  "FAT": "1.5",
                  "SAT_FAT": "0.1",
                  "CARBS": "30.3",
                  "SUGARS": "0.2",
                  "FIBRE": "0.5",
                  "PROTEIN": "3.2",
                  "SALT": "0.4"
                },
                {
                  "item_name": "Romaine Lettuce",
                  "kcal": "6.4",
                  "KJ": "27.2",
                  "FAT": "0.2",
                  "SAT_FAT": "0.0",
                  "CARBS": "0.7",
                  "SUGARS": "0.7",
                  "FIBRE": "0.4",
                  "PROTEIN": "0.3",
                  "SALT": "0.0"
                }
              ]
            },
            {
              "group_name": "Select Beans & Vege",
              "nutritionalSubItem": [
                {
                  "item_name": "Black Beans",
                  "kcal": "99",
                  "KJ": "416",
                  "FAT": "1",
                  "SAT_FAT": "0.2",
                  "CARBS": "19.7",
                  "SUGARS": "1.2",
                  "FIBRE": "7.6",
                  "PROTEIN": "6.7",
                  "SALT": "0.5"
                },
                {
                  "item_name": "Pinto Beans",
                  "kcal": "108",
                  "KJ": "455",
                  "FAT": "0.8",
                  "SAT_FAT": "0.5",
                  "CARBS": "23.3",
                  "SUGARS": "1.5",
                  "FIBRE": "9.6",
                  "PROTEIN": "6.8",
                  "SALT": "0.5"
                },
                {
                  "item_name": "Peppers & Onions",
                  "kcal": "13.2",
                  "KJ": "55.2",
                  "FAT": "0.4",
                  "SAT_FAT": "0.0",
                  "CARBS": "2.1",
                  "SUGARS": "1.8",
                  "FIBRE": "0.9",
                  "PROTEIN": "0.4",
                  "SALT": "0.0"
                }
              ]
            },
            {
              "group_name": "Select Meat",
              "nutritionalSubItem": [
                {
                  "item_name": "Chicken",
                  "kcal": "173.6",
                  "KJ": "726.4",
                  "FAT": "10.4",
                  "SAT_FAT": "2.6",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "20.1",
                  "SALT": "0.5"
                },
                {
                  "item_name": "Chili Con Carne",
                  "kcal": "187.2",
                  "KJ": "779.4",
                  "FAT": "11.7",
                  "SAT_FAT": "4.6",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "20.3",
                  "SALT": "1.4"
                },
                {
                  "item_name": "Barbacoa (beef)",
                  "kcal": "125.1",
                  "KJ": "523.8",
                  "FAT": "5.9",
                  "SAT_FAT": "2.4",
                  "CARBS": "0.5",
                  "SUGARS": "0.5",
                  "FIBRE": "0.0",
                  "PROTEIN": "17.6",
                  "SALT": "0.9"
                },
                {
                  "item_name": "Steak",
                  "kcal": "186.4",
                  "KJ": "776.8",
                  "FAT": "11.9",
                  "SAT_FAT": "5.6",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "19.8",
                  "SALT": "0.8"
                },
                {
                  "item_name": "Shrimps",
                  "kcal": "125.3",
                  "KJ": "519.4",
                  "FAT": "10.5",
                  "SAT_FAT": "4.0",
                  "CARBS": "0.4",
                  "SUGARS": "0.4",
                  "FIBRE": "0.0",
                  "PROTEIN": "7.4",
                  "SALT": "1.4"
                }
              ]
            },
            {
              "group_name": "Select Toppings & Salsas",
              "nutritionalSubItem": [
                {
                  "item_name": "Cheese",
                  "kcal": "112.4",
                  "KJ": "467.3",
                  "FAT": "8.4",
                  "SAT_FAT": "5.4",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "8.8",
                  "SALT": "0.6"
                },
                {
                  "item_name": "Sour Cream",
                  "kcal": "69.7",
                  "KJ": "288.4",
                  "FAT": "6.5",
                  "SAT_FAT": "4.0",
                  "CARBS": "1.8",
                  "SUGARS": "1.8",
                  "FIBRE": "0.0",
                  "PROTEIN": "1.5",
                  "SALT": "0.1"
                },
                {
                  "item_name": "Guacamole",
                  "kcal": "95.4",
                  "KJ": "393.6",
                  "FAT": "9.4",
                  "SAT_FAT": "2.0",
                  "CARBS": "1.6",
                  "SUGARS": "0.7",
                  "FIBRE": "0.2",
                  "PROTEIN": "1.0",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Pico (mild)",
                  "kcal": "16.0",
                  "KJ": "69.6",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "3.2",
                  "SUGARS": "2.8",
                  "FIBRE": "1.0",
                  "PROTEIN": "0.6",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Sweetcorn Salsa",
                  "kcal": "70.4",
                  "KJ": "292.8",
                  "FAT": "0.7",
                  "SAT_FAT": "0.1",
                  "CARBS": "12.7",
                  "SUGARS": "2.9",
                  "FIBRE": "2.1",
                  "PROTEIN": "1.8",
                  "SALT": "0.3"
                },
                {
                  "item_name": "Verde (medium)",
                  "kcal": "10.0",
                  "KJ": "42.8",
                  "FAT": "0.2",
                  "SAT_FAT": "0.0",
                  "CARBS": "2.0",
                  "SUGARS": "1.4",
                  "FIBRE": "0.3",
                  "PROTEIN": "0.2",
                  "SALT": "0.7"
                },
                {
                  "item_name": "Roja (hot)",
                  "kcal": "18.4",
                  "KJ": "76.8",
                  "FAT": "0.6",
                  "SAT_FAT": "0.2",
                  "CARBS": "3.3",
                  "SUGARS": "0.4",
                  "FIBRE": "1.6",
                  "PROTEIN": "0.8",
                  "SALT": "0.4"
                },
                {
                  "item_name": "Pickled onions",
                  "kcal": "7.6",
                  "KJ": "32.0",
                  "FAT": "0.0",
                  "SAT_FAT": "0.0",
                  "CARBS": "1.3",
                  "SUGARS": "0.9",
                  "FIBRE": "0.2",
                  "PROTEIN": "0.2",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Jalapenos",
                  "kcal": "5.6",
                  "KJ": "23.4",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "1.4",
                  "SUGARS": "0.8",
                  "FIBRE": "0.5",
                  "PROTEIN": "0.1",
                  "SALT": "0.6"
                },
                {
                  "item_name": "Iceberg Lettuce",
                  "kcal": "2.8",
                  "KJ": "12.0",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "0.4",
                  "SUGARS": "0.4",
                  "FIBRE": "0.1",
                  "PROTEIN": "0.1",
                  "SALT": "0.0"
                }
              ]
            }
          ],
          "product_image": "https://tortillaarabia.com/wp-content/uploads/2018/01/b_b-M-Green.png",
          "hover_product_image": "https://tortillaarabia.com/wp-content/uploads/2018/01/b_b-M-Gray.png"
        },
        {
          "id": 274,
          "link": "https://tortillaarabia.com/nutrition_products/big-burrito/",
          "meal_name": "Large Burrito / Burrito Bowl",
          "nutritionalCat": [
            {
              "group_name": "Large Burrito",
              "nutritionalSubItem": [
                {
                  "item_name": "Large Wrap",
                  "kcal": "281.2",
                  "KJ": "1189.4",
                  "FAT": "4.6",
                  "SAT_FAT": "2.3",
                  "CARBS": "52.2",
                  "SUGARS": "3.0",
                  "FIBRE": "2.2",
                  "PROTEIN": "6.8",
                  "SALT": "1.1"
                },
                {
                  "item_name": "Mexican Rice",
                  "kcal": "221.4",
                  "KJ": "935.6",
                  "FAT": "2.4",
                  "SAT_FAT": "0.1",
                  "CARBS": "45.6",
                  "SUGARS": "1.5",
                  "FIBRE": "0.9",
                  "PROTEIN": "4.6",
                  "SALT": "0.7"
                },
                {
                  "item_name": "Cilantro Rice",
                  "kcal": "197.1",
                  "KJ": "835.7",
                  "FAT": "2.0",
                  "SAT_FAT": "0.1",
                  "CARBS": "40.9",
                  "SUGARS": "0.3",
                  "FIBRE": "0.7",
                  "PROTEIN": "4.3",
                  "SALT": "0.5"
                },
                {
                  "item_name": "Romaine Lettuce",
                  "kcal": "6.4",
                  "KJ": "27.2",
                  "FAT": "0.2",
                  "SAT_FAT": "0.0",
                  "CARBS": "0.7",
                  "SUGARS": "0.7",
                  "FIBRE": "0.4",
                  "PROTEIN": "0.3",
                  "SALT": "0.0"
                }
              ]
            },
            {
              "group_name": "Select Beans & Vege",
              "nutritionalSubItem": [
                {
                  "item_name": "Black Beans",
                  "kcal": "138.6",
                  "KJ": "582.4",
                  "FAT": "1.4",
                  "SAT_FAT": "0.3",
                  "CARBS": "27.6",
                  "SUGARS": "1.7",
                  "FIBRE": "10.6",
                  "PROTEIN": "9.3",
                  "SALT": "0.7"
                },
                {
                  "item_name": "Pinto Beans",
                  "kcal": "151.2",
                  "KJ": "637.0",
                  "FAT": "1.1",
                  "SAT_FAT": "0.7",
                  "CARBS": "32.6",
                  "SUGARS": "2.1",
                  "FIBRE": "13.4",
                  "PROTEIN": "9.6",
                  "SALT": "0.7"
                },
                {
                  "item_name": "Peppers & Onions",
                  "kcal": "13.2",
                  "KJ": "55.2",
                  "FAT": "0.4",
                  "SAT_FAT": "0.0",
                  "CARBS": "2.1",
                  "SUGARS": "1.8",
                  "FIBRE": "0.9",
                  "PROTEIN": "0.4",
                  "SALT": "0.0"
                }
              ]
            },
            {
              "group_name": "Select Meat",
              "nutritionalSubItem": [
                {
                  "item_name": "Chicken",
                  "kcal": "238.7",
                  "KJ": "998.8",
                  "FAT": "14.3",
                  "SAT_FAT": "3.5",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "27.6",
                  "SALT": "0.7"
                },
                {
                  "item_name": "Chili Con Carne",
                  "kcal": "249.6",
                  "KJ": "1039.2",
                  "FAT": "15.6",
                  "SAT_FAT": "6.1",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "27.1",
                  "SALT": "1.9"
                },
                {
                  "item_name": "Barbacoa (beef)",
                  "kcal": "166.8",
                  "KJ": "698.4",
                  "FAT": "7.8",
                  "SAT_FAT": "3.2",
                  "CARBS": "0.6",
                  "SUGARS": "0.6",
                  "FIBRE": "0.0",
                  "PROTEIN": "23.4",
                  "SALT": "1.2"
                },
                {
                  "item_name": "Steak",
                  "kcal": "256.3",
                  "KJ": "1068.1",
                  "FAT": "16.4",
                  "SAT_FAT": "7.7",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "27.2",
                  "SALT": "1.1"
                },
                {
                  "item_name": "Shrimps",
                  "kcal": "125.3",
                  "KJ": "519.4",
                  "FAT": "10.5",
                  "SAT_FAT": "4.0",
                  "CARBS": "0.4",
                  "SUGARS": "0.4",
                  "FIBRE": "0.0",
                  "PROTEIN": "7.4",
                  "SALT": "1.4"
                }
              ]
            },
            {
              "group_name": "Select Toppings & Salsas",
              "nutritionalSubItem": [
                {
                  "item_name": "Sour Cream",
                  "kcal": "69.7",
                  "KJ": "288.4",
                  "FAT": "6.5",
                  "SAT_FAT": "4.0",
                  "CARBS": "1.8",
                  "SUGARS": "1.8",
                  "FIBRE": "0.0",
                  "PROTEIN": "1.5",
                  "SALT": "0.1"
                },
                {
                  "item_name": "Cheese",
                  "kcal": "112.4",
                  "KJ": "467.3",
                  "FAT": "8.4",
                  "SAT_FAT": "5.4",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "8.8",
                  "SALT": "0.6"
                },
                {
                  "item_name": "Guacmaole",
                  "kcal": "95.4",
                  "KJ": "393.6",
                  "FAT": "9.4",
                  "SAT_FAT": "2.0",
                  "CARBS": "1.6",
                  "SUGARS": "0.7",
                  "FIBRE": "0.2",
                  "PROTEIN": "1.0",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Pico (mild)",
                  "kcal": "16.0",
                  "KJ": "69.6",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "3.2",
                  "SUGARS": "2.8",
                  "FIBRE": "1.0",
                  "PROTEIN": "0.6",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Sweetcorn Salsa",
                  "kcal": "70.4",
                  "KJ": "292.8",
                  "FAT": "0.7",
                  "SAT_FAT": "0.1",
                  "CARBS": "12.7",
                  "SUGARS": "2.9",
                  "FIBRE": "2.1",
                  "PROTEIN": "1.8",
                  "SALT": "0.3"
                },
                {
                  "item_name": "Verde (medium)",
                  "kcal": "10.0",
                  "KJ": "42.8",
                  "FAT": "0.2",
                  "SAT_FAT": "0.0",
                  "CARBS": "2.0",
                  "SUGARS": "1.4",
                  "FIBRE": "0.3",
                  "PROTEIN": "0.2",
                  "SALT": "0.7"
                },
                {
                  "item_name": "Roja (hot)",
                  "kcal": "18.4",
                  "KJ": "76.8",
                  "FAT": "0.6",
                  "SAT_FAT": "0.2",
                  "CARBS": "3.3",
                  "SUGARS": "0.4",
                  "FIBRE": "1.6",
                  "PROTEIN": "0.8",
                  "SALT": "0.4"
                },
                {
                  "item_name": "Pickled Onions",
                  "kcal": "7.6",
                  "KJ": "32.0",
                  "FAT": "0.0",
                  "SAT_FAT": "0.0",
                  "CARBS": "1.3",
                  "SUGARS": "0.9",
                  "FIBRE": "0.2",
                  "PROTEIN": "0.2",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Jalapenos",
                  "kcal": "5.6",
                  "KJ": "23.4",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "1.4",
                  "SUGARS": "0.8",
                  "FIBRE": "0.5",
                  "PROTEIN": "0.1",
                  "SALT": "0.6"
                },
                {
                  "item_name": "Iceberg lettuce",
                  "kcal": "2.8",
                  "KJ": "12.0",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "0.4",
                  "SUGARS": "0.4",
                  "FIBRE": "0.1",
                  "PROTEIN": "0.1",
                  "SALT": "0.0"
                }
              ]
            }
          ],
          "product_image": "https://tortillaarabia.com/wp-content/uploads/2018/02/b_b-L-Green.png",
          "hover_product_image": "https://tortillaarabia.com/wp-content/uploads/2018/02/b_b-L-Gray.png"
        },
        {
          "id": 1007,
          "link": "https://tortillaarabia.com/nutrition_products/tres-tacos/",
          "meal_name": "Tres Tacos",
          "product_image": "https://tortillaarabia.com/wp-content/uploads/2018/04/SS-Green.png",
          "nutritionalCat": [
            {
              "group_name": "Tres Tacos",
              "nutritionalSubItem": [
                {
                  "item_name": "Flour Tacos (3)",
                  "kcal": "189.0",
                  "KJ": "795.6",
                  "FAT": "4.2",
                  "SAT_FAT": "2.5",
                  "CARBS": "32.4",
                  "SUGARS": "1.7",
                  "FIBRE": "0.9",
                  "PROTEIN": "4.5",
                  "SALT": "1.4"
                },
                {
                  "item_name": "Corn Tacos (3)",
                  "kcal": "189.9",
                  "KJ": "803.7",
                  "FAT": "1.6",
                  "SAT_FAT": "0.2",
                  "CARBS": "32.8",
                  "SUGARS": "0.3",
                  "FIBRE": "0.0",
                  "PROTEIN": "4.0",
                  "SALT": "0.8"
                }
              ]
            },
            {
              "group_name": "Select Fillings",
              "nutritionalSubItem": [
                {
                  "item_name": "Peppers & onions",
                  "kcal": "9.9",
                  "KJ": "41.4",
                  "FAT": "0.3",
                  "SAT_FAT": "0.0",
                  "CARBS": "1.6",
                  "SUGARS": "1.4",
                  "FIBRE": "0.7",
                  "PROTEIN": "0.3",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Chicken",
                  "kcal": "195.3",
                  "KJ": "817.2",
                  "FAT": "11.7",
                  "SAT_FAT": "2.9",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "22.6",
                  "SALT": "0.5"
                },
                {
                  "item_name": "Chili Con Carne",
                  "kcal": "249.6",
                  "KJ": "1039.2",
                  "FAT": "15.6",
                  "SAT_FAT": "6.1",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "27.1",
                  "SALT": "1.9"
                },
                {
                  "item_name": "Barbacoa (beef)",
                  "kcal": "166.8",
                  "KJ": "698.4",
                  "FAT": "7.8",
                  "SAT_FAT": "3.2",
                  "CARBS": "0.6",
                  "SUGARS": "0.6",
                  "FIBRE": "0.0",
                  "PROTEIN": "23.4",
                  "SALT": "1.2"
                },
                {
                  "item_name": "Steak",
                  "kcal": "209.7",
                  "KJ": "873.9",
                  "FAT": "13.4",
                  "SAT_FAT": "6.3",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "22.2",
                  "SALT": "0.9"
                },
                {
                  "item_name": "Shrimps",
                  "kcal": "125.3",
                  "KJ": "519.4",
                  "FAT": "10.5",
                  "SAT_FAT": "4.0",
                  "CARBS": "0.4",
                  "SUGARS": "0.4",
                  "FIBRE": "0.0",
                  "PROTEIN": "7.4",
                  "SALT": "1.4"
                }
              ]
            },
            {
              "group_name": "Select Toppings & Salsas",
              "nutritionalSubItem": [
                {
                  "item_name": "Sour Cream",
                  "kcal": "159.2",
                  "KJ": "659.2",
                  "FAT": "14.8",
                  "SAT_FAT": "9.1",
                  "CARBS": "4.2",
                  "SUGARS": "4.2",
                  "FIBRE": "0.0",
                  "PROTEIN": "3.5",
                  "SALT": "0.1"
                },
                {
                  "item_name": "Cheese",
                  "kcal": "192.7",
                  "KJ": "801.0",
                  "FAT": "14.4",
                  "SAT_FAT": "9.2",
                  "CARBS": "0.1",
                  "SUGARS": "0.1",
                  "FIBRE": "0.0",
                  "PROTEIN": "15.1",
                  "SALT": "1.0"
                },
                {
                  "item_name": "Guacamole",
                  "kcal": "95.4",
                  "KJ": "393.6",
                  "FAT": "9.4",
                  "SAT_FAT": "2.0",
                  "CARBS": "1.6",
                  "SUGARS": "0.7",
                  "FIBRE": "0.2",
                  "PROTEIN": "1.0",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Pico (mild)",
                  "kcal": "12.0",
                  "KJ": "52.2",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "2.4",
                  "SUGARS": "2.1",
                  "FIBRE": "0.7",
                  "PROTEIN": "0.4",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Sweetcorn Salsa",
                  "kcal": "52.8",
                  "KJ": "219.6",
                  "FAT": "0.5",
                  "SAT_FAT": "0.1",
                  "CARBS": "9.5",
                  "SUGARS": "2.2",
                  "FIBRE": "1.6",
                  "PROTEIN": "1.4",
                  "SALT": "0.2"
                },
                {
                  "item_name": "Verde (medium)",
                  "kcal": "15.0",
                  "KJ": "64.2",
                  "FAT": "0.3",
                  "SAT_FAT": "0.1",
                  "CARBS": "3.0",
                  "SUGARS": "2.1",
                  "FIBRE": "0.4",
                  "PROTEIN": "0.4",
                  "SALT": "1.0"
                },
                {
                  "item_name": "Roja (hot)",
                  "kcal": "27.6",
                  "KJ": "115.2",
                  "FAT": "0.9",
                  "SAT_FAT": "0.2",
                  "CARBS": "4.9",
                  "SUGARS": "0.7",
                  "FIBRE": "2.5",
                  "PROTEIN": "1.1",
                  "SALT": "0.7"
                },
                {
                  "item_name": "Pickled Onions",
                  "kcal": "7.6",
                  "KJ": "32.0",
                  "FAT": "0.0",
                  "SAT_FAT": "0.0",
                  "CARBS": "1.3",
                  "SUGARS": "0.9",
                  "FIBRE": "0.2",
                  "PROTEIN": "0.2",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Jalapenos",
                  "kcal": "5.6",
                  "KJ": "23.4",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "1.4",
                  "SUGARS": "0.8",
                  "FIBRE": "0.5",
                  "PROTEIN": "0.1",
                  "SALT": "0.6"
                },
                {
                  "item_name": "Iceberg Lettuce",
                  "kcal": "2.8",
                  "KJ": "12.0",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "0.4",
                  "SUGARS": "0.4",
                  "FIBRE": "0.1",
                  "PROTEIN": "0.1",
                  "SALT": "0.0"
                }
              ]
            }
          ],
          "hover_product_image": "https://tortillaarabia.com/wp-content/uploads/2018/04/SS-Gray.png"
        },
        {
          "id": 1008,
          "link": "https://tortillaarabia.com/nutrition_products/quesadilla/",
          "meal_name": "Quesadilla",
          "product_image": "https://tortillaarabia.com/wp-content/uploads/2018/04/Q-Green.png",
          "nutritionalCat": [
            {
              "group_name": "Quesadilla",
              "nutritionalSubItem": [
                {
                  "item_name": "Medium Wrap",
                  "kcal": "195.4",
                  "KJ": "826.3",
                  "FAT": "3.2",
                  "SAT_FAT": "1.6",
                  "CARBS": "36.2",
                  "SUGARS": "2.1",
                  "FIBRE": "1.5",
                  "PROTEIN": "4.8",
                  "SALT": "0.7"
                },
                {
                  "item_name": "Tortilla Chips",
                  "kcal": "174.3",
                  "KJ": "730.5",
                  "FAT": "8.1",
                  "SAT_FAT": "0.7",
                  "CARBS": "22.6",
                  "SUGARS": "0.5",
                  "FIBRE": "1.7",
                  "PROTEIN": "2.0",
                  "SALT": "0.3"
                }
              ]
            },
            {
              "group_name": "Select Meat",
              "nutritionalSubItem": [
                {
                  "item_name": "Chicken",
                  "kcal": "173.6",
                  "KJ": "726.4",
                  "FAT": "10.4",
                  "SAT_FAT": "2.6",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "20.1",
                  "SALT": "0.5"
                },
                {
                  "item_name": "Chili Con Carne",
                  "kcal": "187.2",
                  "KJ": "779.4",
                  "FAT": "11.7",
                  "SAT_FAT": "4.6",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "20.3",
                  "SALT": "1.4"
                },
                {
                  "item_name": "Barbacoa (beef)",
                  "kcal": "125.1",
                  "KJ": "523.8",
                  "FAT": "5.9",
                  "SAT_FAT": "2.4",
                  "CARBS": "0.5",
                  "SUGARS": "0.5",
                  "FIBRE": "0.0",
                  "PROTEIN": "17.6",
                  "SALT": "0.9"
                },
                {
                  "item_name": "Steak",
                  "kcal": "186.4",
                  "KJ": "776.8",
                  "FAT": "11.9",
                  "SAT_FAT": "5.6",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "19.8",
                  "SALT": "0.8"
                },
                {
                  "item_name": "Shrimps",
                  "kcal": "125.3",
                  "KJ": "519.4",
                  "FAT": "10.5",
                  "SAT_FAT": "4.0",
                  "CARBS": "0.4",
                  "SUGARS": "0.4",
                  "FIBRE": "0.0",
                  "PROTEIN": "7.4",
                  "SALT": "1.4"
                }
              ]
            },
            {
              "group_name": "Select Toppings & Salsas",
              "nutritionalSubItem": [
                {
                  "item_name": "Sour Cream",
                  "kcal": "119.4",
                  "KJ": "494.4",
                  "FAT": "11.1",
                  "SAT_FAT": "6.8",
                  "CARBS": "3.1",
                  "SUGARS": "3.1",
                  "FIBRE": "0.0",
                  "PROTEIN": "2.6",
                  "SALT": "0.1"
                },
                {
                  "item_name": "Cheese",
                  "kcal": "192.7",
                  "KJ": "801.0",
                  "FAT": "14.4",
                  "SAT_FAT": "9.2",
                  "CARBS": "0.1",
                  "SUGARS": "0.1",
                  "FIBRE": "0.0",
                  "PROTEIN": "15.1",
                  "SALT": "1.0"
                },
                {
                  "item_name": "Guacamole",
                  "kcal": "95.4",
                  "KJ": "394.0",
                  "FAT": "9.4",
                  "SAT_FAT": "2.0",
                  "CARBS": "1.6",
                  "SUGARS": "0.7",
                  "FIBRE": "0.2",
                  "PROTEIN": "1.0",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Pico (mild)",
                  "kcal": "12.0",
                  "KJ": "52.2",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "2.4",
                  "SUGARS": "2.1",
                  "FIBRE": "0.7",
                  "PROTEIN": "0.4",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Sweetcorn Salsa",
                  "kcal": "52.8",
                  "KJ": "219.6",
                  "FAT": "0.5",
                  "SAT_FAT": "0.1",
                  "CARBS": "9.5",
                  "SUGARS": "2.2",
                  "FIBRE": "1.6",
                  "PROTEIN": "1.4",
                  "SALT": "0.2"
                },
                {
                  "item_name": "Verde (medium)",
                  "kcal": "8.8",
                  "KJ": "37.5",
                  "FAT": "0.2",
                  "SAT_FAT": "0.0",
                  "CARBS": "1.8",
                  "SUGARS": "1.2",
                  "FIBRE": "0.2",
                  "PROTEIN": "0.2",
                  "SALT": "0.6"
                },
                {
                  "item_name": "Roja (hot)",
                  "kcal": "18.4",
                  "KJ": "76.8",
                  "FAT": "0.6",
                  "SAT_FAT": "0.2",
                  "CARBS": "3.3",
                  "SUGARS": "0.4",
                  "FIBRE": "1.6",
                  "PROTEIN": "0.8",
                  "SALT": "0.4"
                }
              ]
            }
          ],
          "hover_product_image": "https://tortillaarabia.com/wp-content/uploads/2018/04/Q-Gray.png"
        },
        {
          "id": 1009,
          "link": "https://tortillaarabia.com/nutrition_products/nachos-queso/",
          "meal_name": "Nachos Queso",
          "product_image": "https://tortillaarabia.com/wp-content/uploads/2018/04/N-Green.png",
          "nutritionalCat": [
            {
              "group_name": "Nachos Queso",
              "nutritionalSubItem": [
                {
                  "item_name": "Tortilla Chips",
                  "kcal": "498.0",
                  "KJ": "2087.0",
                  "FAT": "23.0",
                  "SAT_FAT": "2.1",
                  "CARBS": "64.6",
                  "SUGARS": "1.4",
                  "FIBRE": "4.9",
                  "PROTEIN": "5.8",
                  "SALT": "0.9"
                },
                {
                  "item_name": "Cheese Sauce",
                  "kcal": "235.0",
                  "KJ": "990.0",
                  "FAT": "20.1",
                  "SAT_FAT": "12.0",
                  "CARBS": "3.4",
                  "SUGARS": "2.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "10.3",
                  "SALT": "1.3"
                }
              ]
            },
            {
              "group_name": "Select Meat",
              "nutritionalSubItem": [
                {
                  "item_name": "Chicken",
                  "kcal": "173.6",
                  "KJ": "726.4",
                  "FAT": "10.4",
                  "SAT_FAT": "2.6",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "20.1",
                  "SALT": "0.5"
                },
                {
                  "item_name": "Chili Con Carne",
                  "kcal": "187.2",
                  "KJ": "779.4",
                  "FAT": "11.7",
                  "SAT_FAT": "4.6",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "20.3",
                  "SALT": "1.4"
                },
                {
                  "item_name": "Barbacoa (beef)",
                  "kcal": "125.1",
                  "KJ": "523.8",
                  "FAT": "5.9",
                  "SAT_FAT": "2.4",
                  "CARBS": "0.5",
                  "SUGARS": "0.5",
                  "FIBRE": "0.0",
                  "PROTEIN": "17.6",
                  "SALT": "0.9"
                },
                {
                  "item_name": "Steak",
                  "kcal": "186.4",
                  "KJ": "776.8",
                  "FAT": "11.9",
                  "SAT_FAT": "5.6",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "19.8",
                  "SALT": "0.8"
                },
                {
                  "item_name": "Shrimps",
                  "kcal": "125.3",
                  "KJ": "519.4",
                  "FAT": "10.5",
                  "SAT_FAT": "4.0",
                  "CARBS": "0.4",
                  "SUGARS": "0.4",
                  "FIBRE": "0.0",
                  "PROTEIN": "7.4",
                  "SALT": "1.4"
                }
              ]
            },
            {
              "group_name": "Select Toppings & Salsas",
              "nutritionalSubItem": [
                {
                  "item_name": "Guacamole",
                  "kcal": "95.4",
                  "KJ": "393.6",
                  "FAT": "9.4",
                  "SAT_FAT": "2.0",
                  "CARBS": "1.6",
                  "SUGARS": "0.7",
                  "FIBRE": "0.2",
                  "PROTEIN": "1.0",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Sweetcorn Salsa",
                  "kcal": "52.8",
                  "KJ": "219.6",
                  "FAT": "0.5",
                  "SAT_FAT": "0.1",
                  "CARBS": "9.5",
                  "SUGARS": "2.2",
                  "FIBRE": "1.6",
                  "PROTEIN": "1.4",
                  "SALT": "0.2"
                },
                {
                  "item_name": "Pico (mild)",
                  "kcal": "12.0",
                  "KJ": "52.2",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "2.4",
                  "SUGARS": "2.1",
                  "FIBRE": "0.7",
                  "PROTEIN": "0.4",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Jalapenos",
                  "kcal": "5.6",
                  "KJ": "23.4",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "1.4",
                  "SUGARS": "0.8",
                  "FIBRE": "0.5",
                  "PROTEIN": "0.1",
                  "SALT": "0.6"
                }
              ]
            }
          ],
          "hover_product_image": "https://tortillaarabia.com/wp-content/uploads/2018/04/N-Gray.png"
        },
        {
          "id": 1109,
          "link": "https://tortillaarabia.com/nutrition_products/chips-with-salsa/",
          "meal_name": "Chips with Salsa",
          "product_image": "https://tortillaarabia.com/wp-content/uploads/2018/04/Tc-Green.png",
          "nutritionalCat": [
            {
              "group_name": "Bag of chips & salsa",
              "nutritionalSubItem": [
                {
                  "item_name": "Tortilla Chips",
                  "kcal": "498.0",
                  "KJ": "2087.0",
                  "FAT": "23.0",
                  "SAT_FAT": "2.1",
                  "CARBS": "64.6",
                  "SUGARS": "1.4",
                  "FIBRE": "4.9",
                  "PROTEIN": "5.8",
                  "SALT": "0.9"
                }
              ]
            },
            {
              "group_name": "Shot of Salsa",
              "nutritionalSubItem": [
                {
                  "item_name": "Sour Cream",
                  "kcal": "119.4",
                  "KJ": "494.4",
                  "FAT": "11.1",
                  "SAT_FAT": "6.8",
                  "CARBS": "3.1",
                  "SUGARS": "3.1",
                  "FIBRE": "0.0",
                  "PROTEIN": "2.6",
                  "SALT": "0.1"
                },
                {
                  "item_name": "Guacamole",
                  "kcal": "95.4",
                  "KJ": "393.6",
                  "FAT": "9.4",
                  "SAT_FAT": "2.0",
                  "CARBS": "1.6",
                  "SUGARS": "0.7",
                  "FIBRE": "0.2",
                  "PROTEIN": "1.0",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Pico (mild)",
                  "kcal": "12.0",
                  "KJ": "52.2",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "2.4",
                  "SUGARS": "2.1",
                  "FIBRE": "0.7",
                  "PROTEIN": "0.4",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Verde (medium)",
                  "kcal": "15.0",
                  "KJ": "64.2",
                  "FAT": "0.3",
                  "SAT_FAT": "0.1",
                  "CARBS": "3.0",
                  "SUGARS": "2.1",
                  "FIBRE": "0.4",
                  "PROTEIN": "0.4",
                  "SALT": "1.0"
                },
                {
                  "item_name": "Roja (hot)",
                  "kcal": "27.6",
                  "KJ": "115.2",
                  "FAT": "0.9",
                  "SAT_FAT": "0.2",
                  "CARBS": "4.9",
                  "SUGARS": "0.7",
                  "FIBRE": "2.5",
                  "PROTEIN": "1.1",
                  "SALT": "0.7"
                },
                {
                  "item_name": "Sweetcorn Salsa",
                  "kcal": "52.8",
                  "KJ": "219.6",
                  "FAT": "0.5",
                  "SAT_FAT": "0.1",
                  "CARBS": "9.5",
                  "SUGARS": "2.2",
                  "FIBRE": "1.6",
                  "PROTEIN": "1.4",
                  "SALT": "0.2"
                }
              ]
            },
            {
              "group_name": "Double Shot of Salsa",
              "nutritionalSubItem": [
                {
                  "item_name": "Sour cream",
                  "kcal": "169.2",
                  "KJ": "700.4",
                  "FAT": "15.7",
                  "SAT_FAT": "9.7",
                  "CARBS": "4.4",
                  "SUGARS": "4.4",
                  "FIBRE": "0.0",
                  "PROTEIN": "3.7",
                  "SALT": "0.2"
                },
                {
                  "item_name": "Guacamole",
                  "kcal": "190.8",
                  "KJ": "787.2",
                  "FAT": "18.8",
                  "SAT_FAT": "4.0",
                  "CARBS": "3.1",
                  "SUGARS": "1.4",
                  "FIBRE": "0.4",
                  "PROTEIN": "2.0",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Pico (mild)",
                  "kcal": "18.0",
                  "KJ": "78.3",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "3.6",
                  "SUGARS": "3.2",
                  "FIBRE": "1.1",
                  "PROTEIN": "0.6",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Verde (medium)",
                  "kcal": "21.3",
                  "KJ": "91.0",
                  "FAT": "0.4",
                  "SAT_FAT": "0.1",
                  "CARBS": "4.3",
                  "SUGARS": "3.0",
                  "FIBRE": "0.6",
                  "PROTEIN": "0.5",
                  "SALT": "1.4"
                },
                {
                  "item_name": "Roja (hot)",
                  "kcal": "39.1",
                  "KJ": "163.2",
                  "FAT": "1.3",
                  "SAT_FAT": "0.3",
                  "CARBS": "7.0",
                  "SUGARS": "0.9",
                  "FIBRE": "3.5",
                  "PROTEIN": "1.6",
                  "SALT": "0.9"
                },
                {
                  "item_name": "Sweetcorn Salsa",
                  "kcal": "79.2",
                  "KJ": "329.4",
                  "FAT": "0.8",
                  "SAT_FAT": "0.1",
                  "CARBS": "14.3",
                  "SUGARS": "3.2",
                  "FIBRE": "2.3",
                  "PROTEIN": "2.1",
                  "SALT": "0.3"
                }
              ]
            },
            {
              "group_name": "Bowl of salsa",
              "nutritionalSubItem": [
                {
                  "item_name": "Sour Cream",
                  "kcal": "358.2",
                  "KJ": "1483.2",
                  "FAT": "33.3",
                  "SAT_FAT": "20.5",
                  "CARBS": "9.4",
                  "SUGARS": "9.4",
                  "FIBRE": "0.0",
                  "PROTEIN": "7.9",
                  "SALT": "0.3"
                },
                {
                  "item_name": "Guacamole",
                  "kcal": "381.6",
                  "KJ": "1574.4",
                  "FAT": "37.7",
                  "SAT_FAT": "7.9",
                  "CARBS": "6.2",
                  "SUGARS": "2.9",
                  "FIBRE": "0.7",
                  "PROTEIN": "4.1",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Pico (mild)",
                  "kcal": "30.0",
                  "KJ": "130.5",
                  "FAT": "0.2",
                  "SAT_FAT": "0.0",
                  "CARBS": "6.0",
                  "SUGARS": "5.3",
                  "FIBRE": "1.8",
                  "PROTEIN": "1.1",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Verde (medium)",
                  "kcal": "45.0",
                  "KJ": "192.6",
                  "FAT": "0.9",
                  "SAT_FAT": "0.2",
                  "CARBS": "9.0",
                  "SUGARS": "6.3",
                  "FIBRE": "1.3",
                  "PROTEIN": "1.1",
                  "SALT": "3.1"
                },
                {
                  "item_name": "Roja (hot)",
                  "kcal": "82.8",
                  "KJ": "345.6",
                  "FAT": "2.7",
                  "SAT_FAT": "0.7",
                  "CARBS": "14.8",
                  "SUGARS": "2.0",
                  "FIBRE": "7.4",
                  "PROTEIN": "3.4",
                  "SALT": "2.0"
                },
                {
                  "item_name": "Sweetcorn Salsa",
                  "kcal": "132",
                  "KJ": "549",
                  "FAT": "1.4",
                  "SAT_FAT": "0.2",
                  "CARBS": "23.9",
                  "SUGARS": "5.4",
                  "FIBRE": "3.9",
                  "PROTEIN": "3.5",
                  "SALT": "0.5"
                }
              ]
            }
          ],
          "hover_product_image": "https://tortillaarabia.com/wp-content/uploads/2018/04/Tc-Gray.png"
        },
        {
          "id": 1111,
          "link": "https://tortillaarabia.com/nutrition_products/breakfast-burrito/",
          "meal_name": "Breakfast Burrito",
          "product_image": "https://tortillaarabia.com/wp-content/uploads/2018/04/b_b-B-Green.png",
          "nutritionalCat": [
            {
              "group_name": "Medium Breakfast Burrito",
              "nutritionalSubItem": [
                {
                  "item_name": "Medium Wrap",
                  "kcal": "195.4",
                  "KJ": "826.3",
                  "FAT": "3.2",
                  "SAT_FAT": "1.6",
                  "CARBS": "36.2",
                  "SUGARS": "2.1",
                  "FIBRE": "1.5",
                  "PROTEIN": "4.8",
                  "SALT": "0.7"
                },
                {
                  "item_name": "Potatoes",
                  "kcal": "61.6",
                  "KJ": "312.0",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "14.0",
                  "SUGARS": "1.4",
                  "FIBRE": "1.2",
                  "PROTEIN": "1.6",
                  "SALT": "1.2"
                },
                {
                  "item_name": "Chorizo",
                  "kcal": "125.3",
                  "KJ": "519.4",
                  "FAT": "10.5",
                  "SAT_FAT": "4.0",
                  "CARBS": "0.4",
                  "SUGARS": "0.4",
                  "FIBRE": "0.0",
                  "PROTEIN": "7.4",
                  "SALT": "1.4"
                },
                {
                  "item_name": "Black Beans",
                  "kcal": "99.0",
                  "KJ": "416.0",
                  "FAT": "1.0",
                  "SAT_FAT": "0.2",
                  "CARBS": "19.7",
                  "SUGARS": "1.2",
                  "FIBRE": "7.6",
                  "PROTEIN": "6.7",
                  "SALT": "0.5"
                },
                {
                  "item_name": "Scrambled Egg",
                  "kcal": "124.2",
                  "KJ": "515.7",
                  "FAT": "8.55",
                  "SAT_FAT": "2.8",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "11.7",
                  "SALT": "0.3"
                },
                {
                  "item_name": "Sour Cream",
                  "kcal": "69.7",
                  "KJ": "288.4",
                  "FAT": "6.5",
                  "SAT_FAT": "4.0",
                  "CARBS": "1.8",
                  "SUGARS": "1.8",
                  "FIBRE": "0.0",
                  "PROTEIN": "1.5",
                  "SALT": "0.1"
                },
                {
                  "item_name": "Cheese",
                  "kcal": "112.4",
                  "KJ": "467.3",
                  "FAT": "8.4",
                  "SAT_FAT": "5.4",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "8.8",
                  "SALT": "0.6"
                },
                {
                  "item_name": "Guacamole",
                  "kcal": "95.4",
                  "KJ": "393.6",
                  "FAT": "9.4",
                  "SAT_FAT": "2.0",
                  "CARBS": "1.6",
                  "SUGARS": "0.7",
                  "FIBRE": "0.2",
                  "PROTEIN": "1.0",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Pico (mild)",
                  "kcal": "16.0",
                  "KJ": "69.6",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "3.2",
                  "SUGARS": "2.8",
                  "FIBRE": "1.0",
                  "PROTEIN": "06.",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Verde (medium)",
                  "kcal": "10.0",
                  "KJ": "42.8",
                  "FAT": "0.2",
                  "SAT_FAT": "0.0",
                  "CARBS": "2.0",
                  "SUGARS": "1.4",
                  "FIBRE": "0.3",
                  "PROTEIN": "0.2",
                  "SALT": "0.7"
                },
                {
                  "item_name": "Roja (hot)",
                  "kcal": "18.4",
                  "KJ": "76.8",
                  "FAT": "0.6",
                  "SAT_FAT": "0.2",
                  "CARBS": "3.3",
                  "SUGARS": "0.4",
                  "FIBRE": "1.6",
                  "PROTEIN": "0.8",
                  "SALT": "0.4"
                }
              ]
            },
            {
              "group_name": "Large Breakfast Burrito",
              "nutritionalSubItem": [
                {
                  "item_name": "Large Wrap",
                  "kcal": "281.2",
                  "KJ": "1189.4",
                  "FAT": "4.6",
                  "SAT_FAT": "2.3",
                  "CARBS": "52.2",
                  "SUGARS": "3.0",
                  "FIBRE": "2.2",
                  "PROTEIN": "6.8",
                  "SALT": "1.1"
                },
                {
                  "item_name": "Chorizo",
                  "kcal": "125.3",
                  "KJ": "519.4",
                  "FAT": "10.5",
                  "SAT_FAT": "4.0",
                  "CARBS": "0.4",
                  "SUGARS": "0.4",
                  "FIBRE": "0.0",
                  "PROTEIN": "7.4",
                  "SALT": "1.4"
                },
                {
                  "item_name": "Potatoes",
                  "kcal": "92.4",
                  "KJ": "468.0",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "21.0",
                  "SUGARS": "2.0",
                  "FIBRE": "1.8",
                  "PROTEIN": "2.4",
                  "SALT": "1.8"
                },
                {
                  "item_name": "Black Beans",
                  "kcal": "138.6",
                  "KJ": "582.4",
                  "FAT": "1.4",
                  "SAT_FAT": "0.3",
                  "CARBS": "27.6",
                  "SUGARS": "1.7",
                  "FIBRE": "10.6",
                  "PROTEIN": "9.3",
                  "SALT": "0.7"
                },
                {
                  "item_name": "Scrambled Eggs",
                  "kcal": "151.8",
                  "KJ": "630.3",
                  "FAT": "10.5",
                  "SAT_FAT": "3.4",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "14.3",
                  "SALT": "0.4"
                },
                {
                  "item_name": "Guacamole",
                  "kcal": "95.4",
                  "KJ": "393.6",
                  "FAT": "9.4",
                  "SAT_FAT": "2.0",
                  "CARBS": "1.6",
                  "SUGARS": "0.7",
                  "FIBRE": "0.2",
                  "PROTEIN": "1.0",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Pico (mild)",
                  "kcal": "16.0",
                  "KJ": "69.6",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "3.2",
                  "SUGARS": "2.8",
                  "FIBRE": "1.0",
                  "PROTEIN": "0.6",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Sour Cream",
                  "kcal": "69.7",
                  "KJ": "288.4",
                  "FAT": "6.5",
                  "SAT_FAT": "4.0",
                  "CARBS": "1.8",
                  "SUGARS": "1.8",
                  "FIBRE": "0.0",
                  "PROTEIN": "1.5",
                  "SALT": "0.1"
                },
                {
                  "item_name": "Cheese",
                  "kcal": "112.4",
                  "KJ": "467.3",
                  "FAT": "8.4",
                  "SAT_FAT": "5.4",
                  "CARBS": "0.0",
                  "SUGARS": "0.0",
                  "FIBRE": "0.0",
                  "PROTEIN": "8.8",
                  "SALT": "0.6"
                },
                {
                  "item_name": "Verde (medium)",
                  "kcal": "10.0",
                  "KJ": "42.8",
                  "FAT": "0.2",
                  "SAT_FAT": "0.0",
                  "CARBS": "2.0",
                  "SUGARS": "1.4",
                  "FIBRE": "0.3",
                  "PROTEIN": "0.2",
                  "SALT": "0.7"
                },
                {
                  "item_name": "Roja (hot)",
                  "kcal": "18.4",
                  "KJ": "76.8",
                  "FAT": "0.6",
                  "SAT_FAT": "0.2",
                  "CARBS": "3.3",
                  "SUGARS": "0.4",
                  "FIBRE": "1.6",
                  "PROTEIN": "0.8",
                  "SALT": "0.4"
                }
              ]
            }
          ],
          "hover_product_image": "https://tortillaarabia.com/wp-content/uploads/2018/04/b_b-B-Gray.png"

        },
        {
          "id": 1118,
          "link": "https://tortillaarabia.com/nutrition_products/breakfast-quesadilla/",
          "meal_name": "Breakfast Quesadilla",
          "product_image": "https://tortillaarabia.com/wp-content/uploads/2018/04/BrQ-Green.png",
          "nutritionalCat": [
            {
              "group_name": "Breakfast Quesadilla",
              "nutritionalSubItem": [
                {
                  "item_name": "Chorizo",
                  "kcal": "125.3",
                  "kj": "519.4",
                  "FAT": "10.5",
                  "SAT_FAT": "4.0",
                  "CARBS": "0.4",
                  "SUGARS": "0.4",
                  "FIBRE": "0.0",
                  "PROTEIN": "7.4",
                  "SALT": "1.4"
                },
                {
                  "item_name": "Medium Wrap",
                  "kcal": "195.4",
                  "kj": "826.3",
                  "FAT": "3.2",
                  "SAT_FAT": "1.6",
                  "CARBS": "36.2",
                  "SUGARS": "2.1",
                  "FIBRE": "1.5",
                  "PROTEIN": "4.8",
                  "SALT": "0.7"
                },
                {
                  "item_name": "Cheese",
                  "kcal": "192.7",
                  "kj": "801.0",
                  "FAT": "14.4",
                  "SAT_FAT": "9.2",
                  "CARBS": "0.1",
                  "SUGARS": "0.1",
                  "FIBRE": "0.0",
                  "PROTEIN": "15.1",
                  "SALT": "1.0"
                },
                {
                  "item_name": "Sour Cream",
                  "kcal": "119.4",
                  "kj": "494.4",
                  "FAT": "11.1",
                  "SAT_FAT": "6.8",
                  "CARBS": "3.1",
                  "SUGARS": "3.1",
                  "FIBRE": "0.0",
                  "PROTEIN": "2.6",
                  "SALT": "0.1"
                },
                {
                  "item_name": "Pico (mild)",
                  "kcal": "12.0",
                  "kj": "52.2",
                  "FAT": "0.1",
                  "SAT_FAT": "0.0",
                  "CARBS": "2.4",
                  "SUGARS": "2.1",
                  "FIBRE": "0.7",
                  "PROTEIN": "0.4",
                  "SALT": "0.0"
                },
                {
                  "item_name": "Verde (medium)",
                  "kcal": "8.8",
                  "kj": "37.5",
                  "FAT": "0.2",
                  "SAT_FAT": "0.0",
                  "CARBS": "1.8",
                  "SUGARS": "1.2",
                  "FIBRE": "0.2",
                  "PROTEIN": "0.2",
                  "SALT": "0.6"
                },
                {
                  "item_name": "Roja (hot)",
                  "kcal": "18.4",
                  "kj": "76.8",
                  "FAT": "0.6",
                  "SAT_FAT": "0.2",
                  "CARBS": "3.3",
                  "SUGARS": "0.4",
                  "FIBRE": "1.6",
                  "PROTEIN": "0.8",
                  "SALT": "0.4"
                }
              ]
            }
          ],
          "hover_product_image": "https://tortillaarabia.com/wp-content/uploads/2018/04/BrQ-Gray.png"
        }
      ],
      selectedMeal: null,
      selectedRow: [],
      kcal: 0,
      kj: 0,
      fat: 0,
      satfat: 0,
      carbs: 0,
      sugars: 0,
      fibre: 0,
      protein: 0,
      salt: 0
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    window.scroll(0, 0)
  }
  selectMeal = (index: any) => {
    var scrollDiv = (document as any).getElementById("Steptwo").offsetTop;
    window.scrollTo({ top: scrollDiv - 100, behavior: 'smooth' });
    this.setState({
      selectedMeal: index,
      selectedRow: [],
      kcal: 0,
      kj: 0,
      fat: 0,
      satfat: 0,
      carbs: 0,
      sugars: 0,
      fibre: 0,
      protein: 0,
      salt: 0
    })
  }
  selectRow = (index: any, groupIndex: any, kcal: any, kj: any, fat: any, satfat: any, carbs: any, sugars: any, fibre: any, protein: any, salt: any) => {
    let { selectedRow } = this.state;
    let rowfind = selectedRow.filter((obj: any) => {
      return (obj.selectedRow == index && obj.selectedGroup == groupIndex)
    })
    if (rowfind.length == 0) {
      selectedRow.push({ selectedRow: index, selectedGroup: groupIndex })
      this.setState(
        {
          selectedRow: selectedRow,
          kcal: (parseFloat(this.state.kcal) + parseFloat(kcal)).toFixed(1),
          kj: (parseFloat(this.state.kj) + parseFloat(kj)).toFixed(1),
          fat: (parseFloat(this.state.fat) + parseFloat(fat)).toFixed(1),
          satfat: (parseFloat(this.state.satfat) + parseFloat(satfat)).toFixed(1),
          carbs: (parseFloat(this.state.carbs) + parseFloat(carbs)).toFixed(1),
          sugars: (parseFloat(this.state.sugars) + parseFloat(sugars)).toFixed(1),
          fibre: (parseFloat(this.state.fibre) + parseFloat(fibre)).toFixed(1),
          protein: (parseFloat(this.state.protein) + parseFloat(protein)).toFixed(1),
          salt: (parseFloat(this.state.salt) + parseFloat(salt)).toFixed(1)
        })
    } else {
      //delete record from array that is not matched with clicked row
      var filtered = selectedRow.filter((value) => {
        let result1 = value.selectedRow !== index;
        let result2 = value.selectedGroup !== groupIndex;
        if (result1 == true || result2 == true) {
          return true;
        }
      });
      this.setState({
        selectedRow: filtered,
        kcal: (parseFloat(this.state.kcal) - parseFloat(kcal)).toFixed(1),
        kj: (parseFloat(this.state.kj) - parseFloat(kj)).toFixed(1),
        fat: (parseFloat(this.state.fat) - parseFloat(fat)).toFixed(1),
        satfat: (parseFloat(this.state.satfat) - parseFloat(satfat)).toFixed(1),
        carbs: (parseFloat(this.state.carbs) - parseFloat(carbs)).toFixed(1),
        sugars: (parseFloat(this.state.sugars) - parseFloat(sugars)).toFixed(1),
        fibre: (parseFloat(this.state.fibre) - parseFloat(fibre)).toFixed(1),
        protein: (parseFloat(this.state.protein) - parseFloat(protein)).toFixed(1),
        salt: (parseFloat(this.state.salt) - parseFloat(salt)).toFixed(1)
      })
    }
  }
  handleSubmit = () => {
    alert("submit")
  };

  render() {
    return (
      <div className="main">
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
          <div style={{position:"relative",zIndex:2}} className="container">
            <div className="backBtn-wrapper">
              <Link to={`/menu${window.location.search}`}>
                <div className="org-header__btn--circle">
                  <svg data-v-c2f068f0="" fill="#820c24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" data-spec="icon-arrow-left" className="org-header__icon" style={{ width: '15px', height: '15px' }}><path d="M74.363 79.637a8.997 8.997 0 010 12.727C72.605 94.121 70.303 95 68 95s-4.605-.879-6.363-2.637l-36-36a8.997 8.997 0 010-12.727l36-36a8.997 8.997 0 0112.727 0 8.997 8.997 0 010 12.727L44.727 50l29.636 29.637z"></path></svg>
                </div>
              </Link>
            </div>
          </div>
        </nav>
        <div className="container">
          <div className="nutrition-top_title">Nutritional Info Calculator</div>
          <div className="nutritional-top-step">
            <div className="nutritional-top-step_title">STEP ONE: PICK YOUR MEAL</div>
            <div className="row my-5">
              {this.state.information.map((meal: any, index: any) => {
                return (
                  <div className="col-md-3 col-6" key={index} onClick={() => this.selectMeal(index)}>
                    <div className="nutritional-top-step-list-item">
                      <span className="nutritional-top-step-list-title-wrapper p-4 py-3 text-center">{meal.meal_name}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div id={`Steptwo`}></div>
        {
          (this.state.selectedMeal || this.state.selectedMeal == 0) &&
          //0 check is used to display the content for index 0 because 0 is equal to null for && condition
          <div className="container">
            <div className="text-center p-3">
              <div className="nutritional-top-step_title">STEP TWO: PICK YOUR OPTIONS</div>
              <p className="context-text mt-3">Use this calculator to work out the nutritional information of your meal.
                Select ingredients using the + button. Changed your mind? No worries,
                simply press the red X to remove that ingredient.</p>
            </div>
            <div className="nutritional-content-table table-responsive">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <td></td>
                    <td className="green"><div>Kcal</div></td>
                    <td><div>KJ</div></td>
                    <td className="green"><div>FAT</div></td>
                    <td><div>SAT FAT</div></td>
                    <td className="green"><div>CARBS</div></td>
                    <td><div>SUGARS</div></td>
                    <td className="green"><div>FIBRE</div></td>
                    <td><div>PROTEIN</div></td>
                    <td className="green"><div>SALT</div></td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.information.map((meal: any, mealIndex: any) => {
                    return (
                      this.state.selectedMeal == mealIndex && (
                        meal.nutritionalCat.map((group: any, groupIndex: any) => {
                          return (
                            <>
                              <tr>
                                <td colSpan={10} className="group_title">{group.group_name}</td>
                              </tr>
                              {group.nutritionalSubItem.map((item: any, itemIndex: any) => {
                                let rowfind = this.state.selectedRow.filter((obj: any) => {
                                  return (obj.selectedRow == itemIndex && obj.selectedGroup == groupIndex)
                                })
                                return (
                                  <tr {...((itemIndex + 1 == group.nutritionalSubItem.length) ? { className: `ingridientRow last${rowfind.length > 0 ? " active" : ""}` } : { className: `ingridientRow${rowfind.length > 0 ? " active" : ""}` })} onClick={() => { this.selectRow(itemIndex, groupIndex, item.kcal, item.KJ, item.FAT, item.SAT_FAT, item.CARBS, item.SUGARS, item.FIBRE, item.PROTEIN, item.SALT) }}>
                                    <th><div className="ingridientRow_title">{item.item_name}</div></th>
                                    <td className="green"><span className="table_value" > {item.kcal}</span></td>
                                    <td><span className="table_value">{item.KJ}</span></td>
                                    <td className="green"><span className="table_value">{item.FAT}g</span></td>
                                    <td><span className="table_value">{item.SAT_FAT}g</span></td>
                                    <td className="green"><span className="table_value">{item.CARBS}g</span></td>
                                    <td><span className="table_value">{item.SUGARS}g</span></td>
                                    <td className="green"><span className="table_value">{item.FIBRE}g</span></td>
                                    <td><span className="table_value">{item.PROTEIN}g</span></td>
                                    <td className="green"><span className="table_value">{item.SALT}g</span></td>
                                  </tr>
                                )
                              })}
                            </>
                          )
                        })
                      )
                    )
                  })
                  }
                  <tr className="ingridientRow footer">
                    <th><div className="total-title">Totals</div></th>
                    <td><span className="total_table_value" >{this.state.kcal} <br />Kcal</span></td>
                    <td><span className="total_table_value">{this.state.kj} <br />KJ</span></td>
                    <td><span className="total_table_value">{this.state.fat}g <br />FAT</span></td>
                    <td><span className="total_table_value">{this.state.satfat}g <br />SATFAT</span></td>
                    <td><span className="total_table_value">{this.state.carbs}g <br />CARBS</span></td>
                    <td><span className="total_table_value">{this.state.sugars}g <br />SUGARS</span></td>
                    <td><span className="total_table_value">{this.state.fibre}g <br />FIBRE</span></td>
                    <td><span className="total_table_value">{this.state.protein}g <br />PROTIEN</span></td>
                    <td><span className="total_table_value">{this.state.salt}g <br />SALT</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        }
      </div >
    );
  }
}
// const mapStateToProps = (state: any) => {
//   return {
//     isReset: state.login.isReset,
//     respMessage: state.login.respMessage,
//     switchTab: state.login.switchTab,
//     isValidOtp: state.login.isValidOtp,
//     OtprespMessage: state.login.OtprespMessage
//   };
// };

// const mapDispatchToProps = (dispatch: any) => {
//   return {
//     generateOtp: function (phone: any) {
//       dispatch(generateOtp(phone));
//     },
//     ValidateOtp: function (data: any) {
//       dispatch(ValidateOtp(data));
//     },
//     changePasswordOtp: function (data: any) {
//       dispatch(changePasswordOtp(data));
//     },
//     updateForgotTab: function (tab: any) {
//       dispatch(updateForgotTab(tab))
//     }
//   };
// };

export default connect(null, null)(index);
