import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import "../AboutUs/aboutUs.css";
import './cookie.css'
import cookiesData from './../../data/cookies.json'
import i18next from "i18next";

class Cookie extends Component<{ history: any }, {}> {
  
    constructor(props: any) {
        super(props); 
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        
        let lang = localStorage.getItem("lang");

        return (
            <main className="main">
                <Link to={`/menu${window.location.search}`}>
                    <div style={{ position:"relative", zIndex:2 }} className="container">
                        <div className="backBtn-wrapper mobile-view-page">
                            <div className="org-header__btn--circle">
                                <span 
                                    className="iconify"
                                    style={{ width: "45px", height: "45px", color: "rgba(152, 162, 179, 1)" }}
                                    data-icon="bi:arrow-left-circle"
                                >
                                </span>
                            </div>
                        </div>
                    </div>
                </Link>
                <div className="about-section">
                    <div className="container">
                        <div className="row">
                            <div id="cookies" className="col-lg-12" style={{ fontFamily: "Open Sans", fontSize: "16px" }}>
                                <h5 className="text-dark text-center" style={{ fontSize: "2.4rem" }}>
                                    {i18next.t('cookie.title')}
                                </h5>
                                <p>
                                    <strong>{i18next.t('cookie.desc')}</strong>
                                </p>
                                <p>
                                    <strong>{i18next.t('cookie.desc2')}</strong>
                                </p>
                                <p>
                                    <strong>{i18next.t('cookie.followLine')}</strong>
                                </p>
                                <div className="table-wrapper">
                                    <table>
                                        <thead>
                                            <tr className="text-white">
                                                <th>{i18next.t('cookie.table.cat')}</th>
                                                <th>{i18next.t('cookie.table.class')}</th>
                                                <th>{i18next.t('cookie.table.name')}</th>
                                                <th>{i18next.t('cookie.table.purpose')}</th>
                                                <th>{i18next.t('cookie.table.span')}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="tbody-text">
                                            {
                                                cookiesData.map((item:any, index:any)=> {
                                                    return(
                                                        <tr key={index}>
                                                            <td>{i18next.t(`${item}.cat`)}</td>
                                                            <td>{i18next.t(`${item}.class`)}</td>
                                                            <td>{i18next.t(`${item}.name`)}</td>
                                                            <td>{i18next.t(`${item}.purpose`)}</td>
                                                            <td>{i18next.t(`${item}.span`)}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <h5 className="text-dark mt-5" style={{ fontSize: "2.4rem" }}>
                                    {i18next.t('cookie.cookie_disabled')}
                                </h5>
                                <p>
                                    <strong>{i18next.t('cookie.how_cookie_disabled')}</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );

    }

}

export default Cookie;
