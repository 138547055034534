import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_en from "./translations/en.json"
import common_ar from "./translations/ar.json"
import LanguageDetector from "i18next-browser-languagedetector";


const resources = {
    en: {
        translation: common_en
    },
    ar: {
        translation: common_ar
    }
}
const options = {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lang',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'lang',
  lookupSessionStorage: 'lang',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' }
}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    // lng: "en",
    detection: {
    order: ['localStorage', 'cookie'],
    lookupQuerystring: 'lang',
    lookupLocalStorage: 'lang',
    lookupSessionStorage: 'lang',
    },
    fallbackLng: "en",
  });

export default i18n;