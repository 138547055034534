import React from 'react';
import './page404.css'
class PageNotFound extends React.Component<{history: any},{}> {
    constructor(props:any) {
        super(props);
    }

    render() {
        let lang = localStorage.getItem('lang')
        return (
            <div>
                <h1 className='heading404'>
                    404
                </h1>
                <h2 className='heading-secondary'>{lang=='ar' ? 'وجه الفتاة! الصفحة غير موجودة' : 'OOPS! PAGE NOT FOUND'}</h2>
                <h2 className='heading-third'>{lang == 'ar' ? 'آسف ، الصفحة التي تبحث عنها غير موجودة. العودة الى الصفحة الرئيسية' :"Sorry, the page you are looking for doesn't exist. Go back to home page"}</h2>
                <div style={{display: 'flex',justifyContent:"center"}}>
                    <button onClick={()=>this.props.history.push('/')} className="btn get_direction_btn_for_track btn404">
                        {lang=='ar' ? 'العودة للمنزل' : 'RETURN HOME'}
                    </button>
                </div>
                
            </div>
        )
    }
}

export default PageNotFound;