import React, { Component } from "react";
import { connect } from "react-redux";
import { getStores, searchStore } from "../../Redux";
import i18next from "i18next";
import { RestaurantlocatorState } from "../Interface/loginInterface";
import { Scrollbars } from "rc-scrollbars";
import Map from "./map";
import './resloc.css';
import { Link } from "react-router-dom";
import { GoogleMapsAPI } from "../../ApiManager/client-config";
import Geocode from "react-geocode";
import moment from "moment";
const lang = localStorage.getItem('lang')
Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();
class index extends Component<{ google?: any, restuarants: any[], searchStoresArray: any[], searchStores: (data: any, store_id: any) => {}, getStores: (storeType: any) => {} }, RestaurantlocatorState> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: '',
            stores: [],
            storeName: lang=="en"?"All Stores":"جميع المتاجر",
            showDropDown: false,
            locationEnabled:false
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getStores("All");
        navigator.geolocation.getCurrentPosition(position => {
            this.setState({locationEnabled: true})
            // console.log(position)
          }, error => {
              console.error(error)
              this.setState({locationEnabled: false})
          })
    }
    onSearch = () => {
        let { restuarants } = this.props;
    }
    handleInputChange = (e:any) => {
        this.setState({ id: e.target.value });
        this.props.searchStores(this.props.restuarants, e.target.value)
    }
    setStoreId = (store: any) => {
        // console.log("store id ", store);
        if(store === 'All') {
            this.setState({id: store, storeName: store})
        }
        else {
            this.setState({id: store.store_id, storeName: store.store_name});
        }
        this.props.searchStores(this.props.restuarants, store.store_id)
        this.setState({showDropDown: false})
    }   
    render() {

        const { restuarants, searchStoresArray } = this.props;
        const { stores, id } = this.state;
        let lang = localStorage.getItem("lang")
        let language = ''
        if(lang == 'ar') {
            language = 'الجميع'
        } else if(lang == 'en') {
            language = 'Open Map'
        }

        return (
            <main className="main">
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div style={{position:"relative",zIndex:2}} className="container">
                    <div className="backBtn-wrapper mobile-view-page">
                        <Link to={`/menu${window.location.search}`}>
                            <div className="org-header__btn--circle">
                                <svg data-v-c2f068f0="" fill="#820c24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" data-spec="icon-arrow-left" className="org-header__icon" style={{ width: '15px', height: '15px' }}><path d="M74.363 79.637a8.997 8.997 0 010 12.727C72.605 94.121 70.303 95 68 95s-4.605-.879-6.363-2.637l-36-36a8.997 8.997 0 010-12.727l36-36a8.997 8.997 0 0112.727 0 8.997 8.997 0 010 12.727L44.727 50l29.636 29.637z"></path></svg>
                            </div>
                        </Link>
                    </div>
                    </div>
                </nav>
                <div className="container">
                    <header id="center">
                        <h2 className="pt-2" style={{fontSize: '24px'}}>{i18next.t('restaurantLocatorPage.restaurant_locator')}</h2>
                    </header>
                    <div className="row">
                        <div className="col-md-12">
                            {/* <select name="store" value={this.state.id} onChange={this.handleInputChange} style={{ borderRadius: 20, fontSize: "13px", padding: '1rem' }} className="form-control mt-1" required>
                                <option value="All">All</option>
                                {restuarants &&
                                    restuarants.map((store, index) => (
                                        <option key={index} value={store.store_id}>{store.store_name}</option>
                                    ))
                                }
                            </select> */}
                            <div className="dropdown">
                                <div onClick={() => {this.setState({showDropDown: !this.state.showDropDown})}} className="dropdown-select">
                                    <span>{this.state.storeName}</span>
                                    {!this.state.showDropDown ? 
                                    <i className="fa fa-caret-down icon"></i>
                                    : 
                                    <i className="fa fa-caret-up icon"></i>
                                    }
                                </div>
                                {this.state.showDropDown && 
                                <div className="dropdown-option-list">
                                    <div onClick={() => {this.setStoreId("All")}} className="dropdown-option-item">
                                        <span>{lang=='ar'?'الجميع':'All'}</span>
                                    </div>
                                    {restuarants &&
                                    restuarants.map((store, index) => (
                                        <div onClick={() => {this.setStoreId(store)}} className="dropdown-option-item">
                                        <span>{lang=='ar' ? store.store_name_ar ? store.store_name_ar :store.store_name : store.store_name}</span>
                                        </div>
                                    ))
                                    }
                                </div>
                                }
                            </div>
                            <br />
                            <p style={{textAlign: "center",paddingTop: "15px",fontWeight: "bold",marginBottom: "unset"}} id="txtcolor">{lang == 'ar' ? 'مركز الاتصال:' :'Call Center:'} <a style={{color:'inherit'}} href={lang=='ar' ? "332 533 600" : "tel:600 533 332"}><br />{lang=='ar' ? '332 533 600': '600 533 332'}</a></p>
                        </div>
                        {/* <div className="col-md-3">
                            <button type="submit" onClick={this.onSearch} style={{ borderRadius: 20 }} className="btn custom-default-red-button mt-1">{i18next.t('restaurantLocatorPage.search_btn')}</button>
                        </div> */}
                    </div>
                    <br />

                    <div className="row">
                        <div className="col-sm-4">
                            <Scrollbars style={(restuarants && restuarants.length > 0) ? { height: '45vh' } : { height: '20vh' }} >

                                {(restuarants && restuarants.length == 0) &&
                                    <label id="txtcolor" className="my-4 ml-3"> {i18next.t('restaurantLocatorPage.no_result')} </label>
                                }
                                {restuarants &&
                                    searchStoresArray.length > 0 ?
                                    searchStoresArray.map((store: any) => (
                                        
                                        <div>
                                            <p id="txtcolor">{lang='ar' ? store.store_ar_name ? store.store_ar_name :store.store_name : store.store_name}</p>
                                            <p id="info">{store.use_mannual_address == 1 && store.mannual_address != '' ? store.mannual_address :store.address} </p>
                                            <p id="info">{store.city}</p>
                                            <p id='info'>{moment(store.store_open_time,["hh:mm:ss"]).utc(true).format('hh:mm A')} - {moment(store.store_close_time,["hh:mm:ss"]).utc(true).format('hh:mm A')}</p>
                                            {store && this.state.locationEnabled && <div className='mt-2' style={{display:'flex',justifyContent:'center'}}>
                                                <button onClick={()=>window.open("https://maps.google.com?q="+store.lat+","+store.lng)} className="btn direction-btn">
                                                    {/* {lang == 'ar' ? "افتح الخريطة" : "Open Map"} */}
                                                    {language}
                                                </button>                                               
                                            </div>}
                                            <br />
                                    
                                            {/* {
                                                store.contact1 != null && <p id="info"> <label id="txtcolor">{i18next.t('restaurantLocatorPage.phone_number')}</label> {store.contact1}</p>

                                            } */}

                                            <hr className="new1" />
                                        </div>
                                    )) : restuarants.map((store: any) => (
                                     
                                        <div style={{marginRight:lang=='ar'?"28px":"0px"}} onClick={() => {this.setStoreId(store)}} className="stores-wrapper">
                                            <label style={{margin:"unset"}} id="txtcolor">{lang=='ar' ? store.store_name_ar ? store.store_name_ar :store.store_name : store.store_name}</label>
                                            <p id="info">{store.use_mannual_address == 1 && store.mannual_address != '' ? store.mannual_address :store.address} </p>
                                            <p id="info">{store.city}</p>
                                            <p id='info'>{moment(store.store_open_time,["hh:mm:ss"]).utc(true).format('hh:mm A')} - {moment(store.store_close_time,["hh:mm:ss"]).utc(true).format('hh:mm A')}</p>
                                            {store && this.state.locationEnabled && <div className='mt-2' style={{display:'flex',justifyContent:'center'}}>
                                                <button onClick={()=>window.open("https://maps.google.com?q="+store.lat+","+store.lng)} className="btn direction-btn">
                                                    {lang == 'en' ? "Open Map" : "افتح الخريطة"}
                                                </button>
                                            </div>}
                                            <br />
                                            {/* {
                                                store.contact1 != null && <p id="info"> <label id="txtcolor">{i18next.t('restaurantLocatorPage.phone_number')}</label> {store.contact1}</p>

                                            } */}

                                            <hr className="new1" />
                                        </div>
                                    ))
                                }
                            </Scrollbars>
                        </div>
                        <div className="col-sm-8">
                            <Map
                                data={searchStoresArray.length > 0 ? searchStoresArray : restuarants}
                                google={this.props.google}
                                height='300px'
                                zoom={10}
                            />
                        </div>
                    </div>

                    <br />
                </div>
            </main>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        restuarants: state.login.restuarants,
        searchStoresArray: state.login.searchStores
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getStores: function (data: any) {
            dispatch(getStores(data));
        },
        searchStores: function (data: any, store_id: any) {
            dispatch(searchStore(data, store_id));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
