import React, { Component } from "react";
// import LoginComponent from "../components/Login/index";
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import Terms from "../components/Terms/index";
import { Redirect } from "react-router-dom";

class TrackOrer extends Component<{history:any},{}> {
    componentDidMount(){
        document.title = "Terms & Conditions - HALLA"
        
    }
    render() {
        return (
            <div className="page-wrapper">
                <div id="page-wrap">
                    <Header history={this.props.history}/>
                    <Terms history={this.props.history}/>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default TrackOrer;