import React, { Component } from "react";
import { Helmet } from "react-helmet";

class Index extends Component<{}, {}> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Helmet>
        <script
          type="text/javascript"
          src="https://cdn-prod.eu.securiti.ai/consent/auto_blocking/379cf83c-d7f5-4361-8ddf-58925504a4a6/677d3b06-694a-4611-8284-8bc3240d2a07.js"
        ></script>
        <script type="text/javascript">
          {`
                        (function () {
                            var s = document.createElement('script');
                           s.src = 'https://cdn-prod.eu.securiti.ai/consent/cookie-consent-sdk-loader.js';
			               s.setAttribute('data-tenant-uuid', '379cf83c-d7f5-4361-8ddf-58925504a4a6');
			               s.setAttribute('data-domain-uuid', '677d3b06-694a-4611-8284-8bc3240d2a07');
			               s.setAttribute('data-backend-url', 'https://app.eu.securiti.ai');
			               s.setAttribute('data-skip-css', 'false');
                           s.defer = true;
				           s.addEventListener('load', function() {
				          window.setConsentBannerParams(
		           {overrideBannerLanguage: {
				        'https://www.hallashawarmame.com': 'en',
		                 }
		            });
		        	});
			         var parent_node = document.head || document.body;
			             parent_node.appendChild(s);
                        })()
                    `}
        </script>
      </Helmet>
    );
  }
}

export default Index;
