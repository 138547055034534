var ip = window.location.hostname;
var protocol = window.location.protocol == "https:" ? 'https' : 'http';
export const API_URL = `${protocol}://${ip}:3007/`;
export const BASE_URL = `${protocol}://${ip}:3007/`;
// export const API_URL = `https://h_shawarma-web.simplexts.com.au:3007/`;
// export const BASE_URL = `https://h_shawarma-web.simplexts.com.au:3007/`;
// For Production URL'S
// export const API_URL = `https://hallashawarmame.com:3004/`;
// export const BASE_URL = `https://hallashawarmame.com:3004/`;
export const GoogleMapsAPI = 'AIzaSyD63nbTLBPArnmu6jpOvukRHxo5QbCFRrY';  //for Kyuchon solution
export const priceunit = 'AED '
export const encryptionKey = 'AIzaSyD63nbTLBPArnmu6jpOvukRHxo5QbCFRrY';  //for Kyuchon solution

