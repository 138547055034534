/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { submitMailingList } from "../../Redux/Actions";
import { AddMailsProps, AddMailsStates } from "../Interface/loginInterface";
import './stylecomplaint.css';

class NewsLetterEmails extends Component<AddMailsProps, AddMailsStates>{

    constructor(props: any) {
        super(props);
        this.state = {
            first_name: "",
            last_name: "",
            email: "",
            dob: null,
            phone: '',
            is_student: null,
            is_confirm: 0,
            netWork: false,
            isValidEmail: true,
            isValidPhone: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    handleSubmit = (event: any) => {

        const { first_name, last_name, email, phone, dob, is_student, is_confirm } = this.state;
        this.setState({ netWork: true })
        const data: any = {
            first_name: first_name,
            last_name: last_name,
            email_address: email,
            phone_number: phone,
            is_student: is_student,
            is_confirm: is_confirm
        };
        if (dob) {
            data.dob = dob
        }
        setTimeout(() => {
            this.setState({ netWork: false })
        }, 3000);
        this.props.submitMails(data);
        // console.log(data)
    };
    handleInputChange(event: { target: { name: any; value: any } }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    validatePhone(event: { target: { name: any; value: any; }; }) {
        let reg = /^[0-9\b]+$/;
        if (event.target.value === '' || reg.test(event.target.value)) {
            this.setState({ phone: event.target.value, isValidPhone: true })
        } else {
            event.target.value = event.target.value.slice(0, event.target.value.length - 1);
            this.setState({ phone: event.target.value, isValidPhone: false });
        }
    };
    validateEmail = (event: { target: { name: any; value: any; }; }) => {
        let reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/;
        if (event.target.value != '') {
            if (reg.test(event.target.value)) {
                this.setState({ email: event.target.value, isValidEmail: true });
            } else {
                this.setState({ email: event.target.value, isValidEmail: false })
            }
        }
        else {
            this.setState({ email: event.target.value, isValidEmail: true })
        }
    };
    checkPhone = (phone: any) => {
        if (phone) {
            // var phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
            // var mobileno = /^((\\+91-?)|0)?[0-9]{11}$/;
            var phoneno = /^(?:\971|0)(?:2|3|4|6|7|9|50|51|52|55|56)[0-9]{7}$/;  // uae mobile numbers
            if (phone.match(phoneno)) {
                return true;
            } else {
                return false;
            }
        }
    };
    isUserReady = () => {
        let { first_name, last_name, email, phone, is_student, is_confirm, dob, isValidEmail } = this.state;
        let validateSignal = false;
        if (first_name !== "" && last_name !== "" && email !== "" && phone !== "" && isValidEmail) {
            validateSignal = true;
        }
        if (phone.length < 10 || phone.length > 15) {
            validateSignal = false;
        }
        return validateSignal;
    }

    render() {
        let todayDateTime = new Date();
        let today = todayDateTime.toISOString().split("T")[0];
        return (
            <main className="main">
                <div style={{position:"relative",zIndex:2}} className="container">
                    <div className="backBtn-wrapper">
                        <Link to={`/menu${window.location.search}`}>
                            <div className="org-header__btn--circle">
                                <svg data-v-c2f068f0="" fill="#820c24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" data-spec="icon-arrow-left" className="org-header__icon" style={{ width: '15px', height: '15px' }}><path d="M74.363 79.637a8.997 8.997 0 010 12.727C72.605 94.121 70.303 95 68 95s-4.605-.879-6.363-2.637l-36-36a8.997 8.997 0 010-12.727l36-36a8.997 8.997 0 0112.727 0 8.997 8.997 0 010 12.727L44.727 50l29.636 29.637z"></path></svg>
                            </div>
                        </Link>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <div className="form-row" >
                                        <div className="form-group col-md-12">
                                            <h5><strong>Sign up here for things tortilla!</strong></h5>
                                            <p>Enter your details below to receive direct marketing <span style={{ color: "#8a2432" }}>*</span> indicates a required answer.</p>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <h5 id="txt">FIRST NAME <sup style={{ color: "#8a2432", fontSize: "12px" }}>*</sup></h5>
                                            <input name="first_name" placeholder="First Name" style={{ fontSize: "15px" }} type="text" onChange={this.handleInputChange} className="form-control" id="round" required />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <h5 id="txt">LAST NAME <sup style={{ color: "#8a2432", fontSize: "12px" }}>*</sup></h5>
                                            <input name="last_name" placeholder="Last Name" style={{ fontSize: "15px" }} type="text" onChange={this.handleInputChange} className="form-control" id="round" required />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <h5 id="txt">EMAIL <sup style={{ color: "#8a2432", fontSize: "12px" }}>*</sup></h5>
                                            <input name="email" placeholder="Email" style={{ fontSize: "15px" }} type="email" onChange={this.validateEmail} className="form-control" id="round" required />
                                            {(this.state.isValidEmail == false && this.state.email !== "") &&
                                                <small className="form-text text-danger">Invalid Email</small>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <h5 id="txt">Date of birth</h5>
                                            <input type="date" style={{ fontSize: "15px" }} max={today} name="dob" required data-msg="Please Enter Order Date" className="form-control laterDateInput" onChange={this.handleInputChange} />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <h5 id="txt">PHONE NUMBER <sup style={{ color: "#8a2432", fontSize: "12px" }}>*</sup></h5>
                                            <input name="phone" placeholder="Phone" style={{ fontSize: "15px" }} type="tel" onChange={this.handleInputChange} className="form-control" id="round" required />
                                            {(!this.checkPhone(this.state.phone) && this.state.phone !== "") &&
                                                <small className="form-text text-danger">Invalid Phone Number</small>
                                            }
                                        </div>
                                        <div className="form-group col-md-12 my-4">
                                            <h5 id="txt">Are you a student?</h5>
                                            <div className="form-check form-check-inline" onClick={() => { this.setState({ is_student: 1 }) }}>
                                                <input className="form-check-input" checked={this.state.is_student == 1 && true} type="checkbox" id="flexCheckDefault" />
                                                <label className="mx-4" style={{ fontSize: 15 }}>
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline" onClick={() => { this.setState({ is_student: 0 }) }}>
                                                <input className="form-check-input" checked={this.state.is_student == 0 && true} type="checkbox" id="flexCheckChecked" />
                                                <label className="mx-4" style={{ fontSize: 15 }}>
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <div className="form-check" onClick={() => { this.setState({ is_confirm: this.state.is_confirm == 0 ? 1 : 0 }) }}>
                                                <input className="form-check-input" checked={this.state.is_confirm == 1 && true} type="checkbox" id="flexCheckDefault" />
                                                <label className="mx-4" style={{ fontSize: 15 }}>
                                                    Please tick this box to confirm you're happy to receive direct marketing from us.
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <p><strong>We will always store your personal details securely. We’ll use them to provide the service that you have requested,
                                                and communicate with you in the way that you have agreed to. Your data may also be used for analysis purposes,
                                                to help us to provide the best service possible. We will only allow your information
                                                to be used by suppliers working on our behalf and we’ll only share it if required to
                                                do so by law.</strong></p>
                                        </div>
                                        <div className="form-group col-md-4 p-3">
                                            {this.state.netWork == false ?
                                                <button disabled={!this.isUserReady()} onClick={this.handleSubmit} id="h" type="submit" className="btn custom-default-red-button">Submit</button>
                                                :
                                                <button
                                                    disabled={this.state.netWork}
                                                    id="roundb"
                                                    className="btn custom-default-red-button"
                                                >
                                                    <i className="fa fa-spinner fa-spin"></i>Loading
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        stores: state.login.restuarants,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        submitMails: function (data: any) {
            dispatch(submitMailingList(data))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsLetterEmails);
