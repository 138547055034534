import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import "../AboutUs/aboutUs.css";
import privacyData from './../../data/privacy-policy.json'
import dataPrivacy from './../../data/dataPrivacy.json'
import categoryData from './../../data/categoryRecipient.json'
import i18next from "i18next";
class index extends Component<{ history: any }, {}> {
  constructor(props: any) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const script = document.createElement("script");
    script.src = "https://code.iconify.design/2/2.2.1/iconify.min.js";
    script.async = true;
    document.body.appendChild(script);
  }
  render() {
    let lang = localStorage.getItem("lang");
    return (
      <main className="main">
        <Link to={`/menu${window.location.search}`}>
          <div
            style={{ position: "relative", zIndex: 2 }}
            className="container"
          >
            <div className="backBtn-wrapper mobile-view-page">
              <div className="org-header__btn--circle">
                <span
                  className="iconify"
                  style={{
                    // marginRight: "10px",
                    width: "45px",
                    height: "45px",
                    color: "#179145",
                  }}
                  data-icon="bi:arrow-left-circle"
                ></span>
              </div>
            </div>
          </div>
        </Link>
        <div className="about-section">
          <div className="container">
           
              <div className="row">
                <div
                  className="col-lg-12"
                  style={{
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                  }}
                >
                  <h5
                    className="text-dark text-center"
                    style={{ fontSize: "2.4rem" }}
                  >
                    Privacy Policy
                  </h5>
                  {/* <h6 className="subtitle myprofile font-weight-bold font-weight-bold">General</h6> */}
                  
                  <h6 className="subtitle myprofile font-weight-bold">
                  Introduction
                  </h6>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                    We are committed to protect and respect your right to privacy. 
                    This Privacy Notice ("Notice") governs the collection and use of 
                    personal data by Halla Shawarma which is a subsidiary of the food 
                    and beverages division Galadari Ice Cream Co. Ltd. LLC. This Notice 
                    is applicable to Galadari Brothers Co. LLC (parent company) as well as 
                    Galadari Ice Creams’ other subsidiaries that may also collect and process 
                    your personal data, either as controllers or processors. 
                    This Notice describes how and why we use any personal data you provide to us, 
                    as well as information we might obtain from other sources.
                    </strong>
                  </p>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                    Your personal data may be used for any of the purposes specified in this Notice, 
                    or for additional purposes that we notify you of when your personal data is being collected.
                    </strong>
                  </p>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                    In this Notice, references to "our," "us," or "we" refer to Halla Shawarma.
                    </strong>
                  </p>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                    Any references to "you" or "your" refers to the relevant individual who is the subject of the personal data.
                    </strong>
                  </p>
                  <h6 className="subtitle myprofile font-weight-bold">
                  Who are we?
                  </h6>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                    Halla Shawarma is the data controller for the purposes of any applicable data protection laws.
                    </strong>
                  </p>
                  <h6 className="subtitle myprofile font-weight-bold">
                  Definitions covered in this Notice
                  </h6>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                    When we refer to "processing" your "personal data" in this Notice,
                    we qualify this as any action done with or in connection with your
                    personal data (this includes gathering, storing, and deleting 
                    such personal data). Any information that can be used to locate 
                    or (in)directly identify you is considered as “personal data”. 
                    Examples of personal data are: birth date, age, gender, nationality, passport, marital status, signature, country of residence, place of birth, photo, family details, voice recordings, religion, spoken languages, emergency contact information, insurance information, driver's license number.
                    </strong>
                  </p>
                  
                  <h6 className="subtitle myprofile font-weight-bold">
                  What types of personal data do we collect from you? 
                  </h6>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                    We process different categories of personal data further described below.
                    </strong>
                  </p>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                      <span style={{ fontSize: "16px" }}  className="subtitle myprofile font-weight-bold">(1)	Contact Details</span><br/>To respond to your requests or inquiries. For example,
                      we use your information to fulfil orders or prizes. We
                      might also use your information to register you for a
                      promotion on our website.
                    </strong>
                  </p>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                      <span style={{ fontSize: "16px" }}  className="subtitle myprofile font-weight-bold">(2)Identification Data </span><br/>
                      Identification Data is personal data that helps us identify you, such as your name, title, copy of your passport, Emirates ID, driver’s license, proof of utility bills etc.
                    </strong>
                  </p>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                      <span style={{ fontSize: "16px" }}  className="subtitle myprofile font-weight-bold">(3)	Sensitive personal data</span><br/>
                      This category of personal data is considered as sensitive by law. Examples of sensitive personal data are your health data or your biometric data or a copy of your photo.
                    </strong>
                  </p>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                      <span style={{ fontSize: "16px" }}  className="subtitle myprofile font-weight-bold">(4) Web Data</span><br/>Web data is personal data collected via our websites such as cookies, user activity records, IP addresses, social media profiles, and information about your interactions on our websites.
                    </strong>
                  </p>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                      <span style={{ fontSize: "16px" }}  className="subtitle myprofile font-weight-bold">(5) Geolocation Data</span><br/>
                      Geolocation data is personal data such as the location of your device.
                      
                    </strong>
                  </p>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                      <span style={{ fontSize: "16px" }}  className="subtitle myprofile font-weight-bold">(6)	Financial Data</span><br/>
                      Financial data is personal data such as your bank and card information, bank statements and your salary details.
                    </strong>
                  </p>
                  <h6 className="subtitle myprofile font-weight-bold">
                  For what purposes do we process your personal data?
                  </h6>
                  <div className="about-section">
                    <div className="container">
                        <div className="row">
                            <div id="cookies" className="col-lg-12" style={{ fontFamily: "Open Sans", fontSize: "16px" }}>
                                
                                <div className="table-wrapper">
                                    <table>
                                        <thead>
                                            <tr className="text-white">
                                                <th>{i18next.t('policy.table.cat')}</th>
                                                <th>{i18next.t('policy.table.class')}</th>
                                                <th>{i18next.t('policy.table.name')}</th>
                                              
                                            </tr>
                                        </thead>
                                        <tbody className="tbody-text">
                                            {
                                                privacyData.map((item:any, index:any)=> {
                                                    return(
                                                        <tr key={index}>
                                                            <td>{i18next.t(`${item}.cat`)}</td>
                                                            <td>{i18next.t(`${item}.class`)}</td>
                                                            <td>{i18next.t(`${item}.name`)}</td>
                                              
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                 
                  <h6 className="subtitle myprofile font-weight-bold">
                  Retention of Personal Data
                  </h6>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                    We only retain your personal data for as long as necessary to carry out the purposes indicated in this Notice. After this period, we will securely delete your personal data, unless we are obligated to retain your personal data to comply with a legal or regulatory requirement or to deal with any potential disputes.
                    If we no longer need to keep your personal data, we will delete it from our systems and records and/or take steps to properly anonymize it so that you cannot be identified from it.
                    </strong>
                  </p>
                  
                  <h6 className="subtitle myprofile font-weight-bold">
                  Your rights
                  </h6>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                    You have certain rights that you can exercise. Please send an email to dpo@galadaribrothers.com to exercise your rights. The following rights may be applicable to you, depending on the jurisdiction where your personal data is processed
                    </strong>
                  </p>
                  <div className="about-section">
                    <div className="container">
                        <div className="row">
                            <div id="cookies" className="col-lg-12" style={{ fontFamily: "Open Sans", fontSize: "16px" }}>
                                
                                <div className="table-wrapper">
                                    <table>
                                        <thead>
                                            <tr className="text-white">
                                                <th>{i18next.t('policy.table.cat')}</th>
                                                <th>{i18next.t('policy.table.class')}</th>
                                    
                                              
                                            </tr>
                                        </thead>
                                        <tbody className="tbody-text">
                                            {
                                                dataPrivacy.map((item:any, index:any)=> {
                                                    return(
                                                        <tr key={index}>
                                                            <td>{i18next.t(`${item}.cat`)}</td>
                                                            <td>{i18next.t(`${item}.class`)}</td>
                                                            
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                    It is important to note that the rights stated above are not absolute and that not every request can be fully granted. In certain cases, for instance where we are required to retain your personal data by contracts or the law, we might not be able to delete it or limit its processing.
                    </strong>
                  </p>
                  <h6 className="subtitle myprofile font-weight-bold">
                  With whom do we share your personal data?
                  </h6>
                  <p>
                    <strong style={{ fontSize: "16px", marginBottom: "0" }}>
                    We may share your personal data with: 
                    </strong>
                  </p>
                  <ol>
                                    <strong style={{ fontSize: "16px" }}>
                                        <li>i.	Our service providers, business partners, and affiliates </li>
                                        <li>ii.	Authorized third parties </li>
                                        <li>iii.	Other third parties required by law.</li>
                                    </strong>
                                </ol>
                  
                  
                  {/* <h2 className="text-dark pt-5" style={{ borderTop: '2px dotted #8a2432' }}>CONTACT US</h2> */}
                  {/* <h6 className="subtitle myprofile font-weight-bold">CONTACT US</h6> */}
                  {/* <p><strong>Please use our feedback form under <Link to="/feedback">contact us </Link> page to tell us what’s on your mind and we’ll get in touch with you directly.</strong></p>
                                <p><strong>If you’d like to contact a specific store, visit our<Link to="locations-overview"> locations page </Link> for store contact details.</strong></p> */}
                  {/* <h6 className="subtitle myprofile font-weight-bold">FOOD FOR THOUGHT PROGRAMME</h6>
                                <p><strong>Mexican Grill t/a Tortilla Ltd has commissioned Market Force Information to conduct market research into the usage of Tortilla locations around the country.<br />
                                    Both Tortilla Ltd and Market Force Information are committed to protecting and respecting your privacy. You are never required to provide us with the types of information covered in the Mystery Customer or ‘Food For Thought’ programme. You can still participate in the surveys if you decide to withhold some or all of the information of the information requested. Further information can be found on our privacy policy at the bottom of this page or on the link on questionnaire itself.</strong></p>
                                <h2 className="text-dark pt-5" style={{ borderTop: '2px dotted #8a2432' }}>LOYALTY PROGRAMME</h2>
                                <h6 className="subtitle myprofile font-weight-bold">LOYALTY PROGRAMME</h6>
                                <p><strong>Our (very) old paper loyalty cards are no longer valid starting April 2022. Place your first order online and automatically join our loyalty program. In store, just share your phone number to be able to use yout new electronic card and start earning points.</strong></p>
                                <p><strong>Loyalty cards have no cash value and cannot be swapped or changed for an alternative product/discount. Tortilla reserves the right to refuse/withdraw or amend this offer at any time without notice. Loyalty offer not available on third party sites (e.g. Apple Wallet).</strong></p> */}
                 
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                    We regulate how and with whom we share your personal data. We may disclose your information for purposes such as payment processing, order fulfilment, product installation, customer service, marketing, financing, servicing or repair, and other similar operations.
                    </strong>
                  </p>
                  <div className="about-section">
                    <div className="container">
                        <div className="row">
                            <div id="cookies" className="col-lg-12" style={{ fontFamily: "Open Sans", fontSize: "16px" }}>
                                
                                <div className="table-wrapper">
                                    <table>
                                        <thead>
                                            <tr className="text-white">
                                                <th>{i18next.t('policy.table.categoryRecipient')}</th>
                                                <th>{i18next.t('policy.table.purpose')}</th>
                                    
                                              
                                            </tr>
                                        </thead>
                                        <tbody className="tbody-text">
                                            {
                                                categoryData.map((item:any, index:any)=> {
                                                    return(
                                                        <tr key={index}>
                                                            <td>{i18next.t(`${item}.cat`)}</td>
                                                            <td>{i18next.t(`${item}.class`)}</td>
                                                            
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                  <h6 className="subtitle myprofile font-weight-bold">
                  How do we secure your personal data?
                  </h6>
                  
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                     We have adopted appropriate technological and operational security measures to protect your personal data against loss, abuse, alteration, or destruction. Only authorized people have access to personal data, and these individuals are contractually bound to keep it confidential. We require our suppliers and vendors to implement appropriate safeguards when they access or use personal data that we share with them.
                    </strong>
                  </p>
                  
                  <h6 className="subtitle myprofile font-weight-bold">Automated Decision Making/Profiling </h6>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                    Our artificial intelligence technologies may enable automated data processing in several fields. This is called “profiling”. If we make use of profiling, we will always obtain your consent.  We will also conduct privacy impact assessments to implement appropriate measures to protect your rights.
                    </strong>
                  </p>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                    When we make use of profiling, you have the right to object against it. This right can be invoked by using the contact details provided in the "Contact Us" section below.
                    </strong>
                  </p>
                  <h6 className="subtitle myprofile font-weight-bold">
                  Collection of personal data from children
                  </h6>
                  <p>
                    <strong style={{ fontSize: "16px" }}>
                    Our services are intended for adults, and not directed to children. We do not intentionally gather or request personal data from children. If we collect personal data from children, we will obtain verified parental consent.
                    </strong>
                  </p>
                  <h6 className="subtitle myprofile font-weight-bold">
                  International data transfers
                  </h6>
                  <p>
                    <strong style={{ fontSize: "16px" }}>When processing your personal data, we might share it with third parties in other countries to the extent required to achieve the purposes stated in this Notice. Halla Shawarma is a subsidiary of the food and beverages division Galadari Ice Cream Co. Ltd. LLC which is a part of Galadari Brothers Co. LLC. Your personal data may be shared within this group of companies, as well as with Galadari Brothers Co. LLC's subsidiaries located in countries outside of the UAE. Such transfers shall be continually done in accordance with applicable data protection laws.</strong>
                  </p>
                  <p>
                    <strong style={{ fontSize: "16px" }}>We transfer personal data from the United Kingdom and the European Economic Area ("EEA") to organizations outside the EEA in accordance with the EU Standard Data Protection Clauses. For more information regarding transfers, please contact us at dpo@galadaribrothers.com.</strong>
                  </p>
                  <h6 className="subtitle myprofile font-weight-bold">
                  Privacy Center
                  </h6>
                  <p>
                    <strong style={{ fontSize: "16px" }}>Our Privacy Center enables you to customize your consent preferences. By clicking on <a target="_blank" href="https://privacy-central.eu.securiti.ai/#/form-access-preference/bd4ff1ef-06d9-489a-917c-c3480c0a652a/0682c43b-f25e-4a04-a008-659159bbdee6">Privacy Center</a>, you can manage the consent given to us. </strong>
                  </p>
                  <h6 className="subtitle myprofile font-weight-bold">
                  Contact Us
                  </h6>
                  <p>
                    <strong style={{ fontSize: "16px" }}>If you have any questions or concerns about this Notice, please contact us at dpo@galadaribrothers.com. </strong>
                  </p>
                  <h6 className="subtitle myprofile font-weight-bold">
                  Updates to the Notice
                  </h6>
                  <p>
                    <strong style={{ fontSize: "16px" }}>This Notice may be updated on a regular basis to reflect new developments. We reserve the right to update, add, or remove portions of this Notice at any time. You are also advised to revisit this Notice periodically for updates. </strong>
                  </p>
                  <p>
                    <strong style={{ fontSize: "16px" }}>The most recent update to this Notice will be indicated below. </strong>
                  </p>
                  <h6 className="subtitle myprofile font-weight-bold">
                  Date of Publication – 22nd August, 2024
                  </h6>
                </div>
              </div>
           
          
          </div>
        </div>
      </main>
    );
  }
}

export default index;
