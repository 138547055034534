import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import './aboutUs.css';

class index extends Component {
    constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        // console.log("")
        const script = document.createElement("script");

        script.src = "https://code.iconify.design/2/2.2.1/iconify.min.js";
        script.async = true;

        document.body.appendChild(script);

    }
    render() {
        let lang = localStorage.getItem('lang')
        return (
            <main className="main">
                <div style={{ position: "relative", zIndex: 2 }} className="container">
                    <div className="backBtn-wrapper mobile-view-page">
                        <Link to={`/menu${window.location.search}`}>
                            <div className="org-header__btn--circle">
                                {/* <svg data-v-c2f068f0="" fill="#820c24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" data-spec="icon-arrow-left" className="org-header__icon" style={{ width: '15px', height: '15px' }}><path d="M74.363 79.637a8.997 8.997 0 010 12.727C72.605 94.121 70.303 95 68 95s-4.605-.879-6.363-2.637l-36-36a8.997 8.997 0 010-12.727l36-36a8.997 8.997 0 0112.727 0 8.997 8.997 0 010 12.727L44.727 50l29.636 29.637z"></path></svg> */}
                                <span className="iconify"
                                    style={{
                                        // marginRight: "10px",
                                        width: "45px",
                                        height: "45px",
                                        color: "#179145"
                                    }}
                                    data-icon="bi:arrow-left-circle">
                                </span>
                            </div>
                        </Link>
                    </div>
                </div>
                {lang == 'en' ? <div className="about-section about-section-custom">
                    <div className="container">
                        <h2 className="subtitle text-center" style={{ fontSize: "2.4rem" }}>{i18next.t('ourStory.our_story')}</h2>
                        <div className="row">
                            <div className="col-lg-12" style={{ fontFamily: "Open Sans", fontSize: "18px"}}>
                                <p className="mb-2"> 
                                    <strong>
                                        Halla! 
                                    </strong>        
                                </p>
                                <p className="mb-2">
                                    <strong>
                                        Welcome to Dubai's newest, coolest & tastiest shawarma hangout spot – <b style={{ color: "rgb(152, 25, 40)" }}> Halla Shawarma! </b>
                                    </strong>
                                </p>
                                <p className="mb-2">
                                    <strong>
                                    Your search for the best shawarma ends here – we don’t make empty promises, our creations are delightfully appetizing and irresistibly tasty.    
                                    </strong>
                                </p>

                                <p className="mb-2">
                                    <strong>
                                        Shawarma is more than a dish; it's a culinary masterpiece, meticulously crafted from our 
                                        deep-rooted love for tradition, culture, and exceptional food. Your cravings are in good 
                                        hands. Halla Shawarma is the brainchild of the passionate and experienced <b style={{ color: "rgb(152, 25, 40)" }}> Galadari Food & 
                                        Beverage Division </b>, a well-established name in the world of food and beverages.  
                                    </strong>
                                </p>

                                <p className="mb-2">
                                    <strong>
                                        Delve into our menu and uncover hidden gems like the Halla Beef Salad, a fusion of tender 
                                        beef shawarma and fresh veggies. The Grilled Spicy Chicken offers a smoky, spicy delight, 
                                        while our Quesadillas Shawarma bursts with flavors. And don't forget the Shawarma Rice 
                                        Bowl or Batata Shawarma, featuring crispy fries generously topped with shawarma and 
                                        sauces. And that’s not all, our drinks are not afterthoughts – we have a full menu of 
                                        refreshing juices and karak options to pair with our satisfying shawarma.
                                    </strong>
                                </p>  

                                <p className="mb-2">
                                    <strong>
                                        Our story unfolds at five prime locations in Dubai: Aswaaq Mall Al Warqa, Dakakeen in Al 
                                        Khawaneej, Burjuman Mall, Qusais and Muweilah, Sharjah. It's our pleasure to bring the 
                                        deliciousness closer to you. 
                                    </strong>
                                </p>

                                <p className="mb-2">
                                    <strong>
                                        But our story doesn't end here. We're all about growth and innovation. We have exciting 
                                        plans, with a commitment to expanding rapidly across the UAE and GCC markets. We're 
                                        dedicated to sharing the very essence of our culture, the richness of our local flavors, and our 
                                        shawarma expertise with an even broader audience.
                                    </strong>
                                </p>

                                <p className="mb-5">
                                    <strong>
                                        Our vision is clear: we aim to become the number 1 destination for Shawarma in the region.       
                                    </strong>
                                </p>

                                {/* <div>
                                    <p style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>
                                        <strong>
                                            FUN LINES
                                        </strong>
                                    </p>
                                    <ol>
                                        <li><b>"Indulge in the Art of Shawarma Perfection"</b></li>
                                        <li><b>"Dubai’s Hidden Shawarma Gem"</b></li>
                                        <li><b>Halla all the way!  </b></li>
                                    </ol>
                                </div> */}
                             
                            </div>
                        </div>
                    </div>
                </div> : <div className="about-section">
                    <div className="container">
                        <h2 className="subtitle text-center" style={{ fontSize: "2.4rem" }}>{i18next.t('ourStory.our_story')}</h2>
                        <div className="row">
                            <div className="col-lg-12" style={{ fontFamily: "Open Sans", fontSize: "18px" }}>
                                <p className="mb-2"> 
                                    <strong>
                                    هلا!
                                    </strong>        
                                </p>
                                <p className="mb-2">
                                    <strong>
                                    مرحباً بكم في أحدث وأروع وألذ مكان لتناول الشاورما في دبي - هلا شاورما!
                                    </strong>
                                </p>
                                <p className="mb-2">
                                    <strong>
                                    بحثك الطويل عن أفضل أنواع الشاورما ينتهي هنا - نحن لا نقدم وعوداً فقط، إبداعاتنا شهية 
ومبهجة ولذيذة بشكل لا يقاوم. 
                                    </strong>
                                </p>
                                <p className="mb-2">
                                    <strong>
                                    الشاورما هي أكثر من مجرد طبق؛ إنها تحفة من فن الطهي، نحضّرها بدقة وتفاني مستمدين ذلك من 
حبنا العميق للتقاليد والثقافة والطعام الاستثنائي. نقدم لك طعم يناسب ذوقك الرفيع. هلا شاورما 
هي من إبداعات شركة كلداري للأطعمة والمشروبات التي تمتاز بفريقها المتحمّس وذو الخبرة الكبيرة، 
وهي اسم راسخ في عالم الأطعمة والمشروبات. 
                                    </strong>
                                </p>
                                <p className="mb-2">
                                    <strong>
                                    انغمس في قائمة الطعام لدينا واكتشف الجواهر المخفية مثل سلطة هلا شاورما لحم، وهي مزيج من 
شاورما اللحم الطري والخضار الطازجة. يقدم الدجاج المشوي الحار نكهة مدخنة حارة، بينما تزخر 
شاورما الكاساديا بالنكهات. ولا تنس طبق أرز الشاورما أو بطاطا الشاورما، الذي يضم البطاطس 
المقلية المقرمشة المغطاة بالشاورما والصلصات. وهذا ليس كل شيء، فمشروباتنا ليست مجرد أفكار 
جاهزة - فلدينا قائمة كاملة من العصائر المنعشة وخيارات الكرك لتتماشى مع الشاورما اللذيذة.
                                    </strong>
                                </p>
                                <p className="mb-2">
                                    <strong>
                                    قصتنا تتكشف في خمسة مواقع رئيسية في دبي: أسواق مول - الورقاء، دكاكين - الخوانيج، برجمان 
مول، القصيص، مويلح - الشارقة. إنه لمن دواعي سرورنا أن نجعل اللذة أقرب إليك. 
                                    </strong>
                                </p>
                                <p className="mb-2">
                                    <strong>
                                    لكن قصتنا لا تنتهي هنا. هدفنا الأهم هو النمو والابتكار. لدينا خطط مثيرة، مع الالتزام بالتوسّع 
بسرعة في أسواق الإمارات العربية المتحدة ودول مجلس التعاون الخليجي. نحن ملتزمون بمشاركة 
جوهر ثقافتنا وثراء نكهاتنا المحلية وخبرتنا في الشاورما مع جمهور أوسع.
                                    </strong>
                                </p>
                                <p className="mb-2">
                                    <strong>
                                        رؤيتنا واضحة: نهدف إلى أن نصبح الوجهة الأولى للشاورما في المنطقة.
                                    </strong>
                                </p>
                
                                <div>
                                    <p style={{ textTransform: 'capitalize', textDecoration: 'underline' }}>
                                        <strong>
                                            عناوين ممتعة    
                                        </strong>
                                    </p>
                                    <ol>
                                        <li><b>"انغمس في فن الشاورما المذهل"</b></li>
                                        <li><b>"جوهرة الشاورما المخفية في دبي"</b></li>
                                        <li><b>هلا بكم ومعكم! </b></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </main>
        );
    }
}

export default index;
