import React, { Component } from "react";
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import CartComponent from "../components/Cart/index";

class Cart extends Component<{ history: any }> {
  componentDidMount() {
    document.title = "Cart - HALLA";
  }
  render() {
    return (
      <div className="page-wrapper">
        <div id="page-wrap">
          <Header history={this.props.history} />
          <CartComponent history={this.props.history} />
          <Footer />
        </div>

      </div>
    );
  }
}

export default Cart;
