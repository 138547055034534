import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import i18next from "i18next";
import { GoogleMapsAPI } from '../../ApiManager/client-config';
Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();
interface Props {
    data: any[], google: any, zoom: any, height: any
}
interface State { [x: number]: any, address: any, lat: any, lng: any }
class Map extends Component<Props, State>{
    map: any;
    constructor(props: any) {
        super(props);
        this.state = {
            address: '',
            lat: 0,
            lng: 0
        }
    }
    /**
     * Get the current address from the default map position and set those values in the state
     */
    componentDidMount() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                this.setState({ lat: position.coords.latitude, lng: position.coords.longitude })
                Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                    (response: any) => {
                        const address = response.results[0].formatted_address
                        this.setState({
                            address: (address) ? address : ''
                        })
                    },
                    (error: any) => {
                        console.error(error);
                    }
                );
            })
        }
    };
    componentWillReceiveProps(nextProps: any) {
        if (nextProps.data.length > 0) {
            Geocode.fromLatLng(nextProps.data[0].lat, nextProps.data[0].lng).then(
                (response: any) => {
                    const address = response.results[0].formatted_address;
                    this.setState({
                        address: (address) ? address : ''
                    })
                },
                (error: any) => {
                    console.error(error);
                }
            );
        }

    }
    /**
     * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps: any, nextState: any): boolean | any {
        if (
            this.state.lat !== nextState.lat ||
            this.state.address !== nextState.address
        ) {
            return true
        } else {
            return false
        }
    }
    /**
     * This Event triggers when the marker window is closed
     *
     * @param event
     */
    onInfoWindowClose = (event: any) => {

    };

    /**
     * When the marker is dragged you get the lat and long using the functions available from event object.
     * Use geocode to get the address, city, area and state from the lat and lng positions.
     * And then set those values in the state.
     *
     * @param event
     */
    onMarkerDragEnd = (event: any) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();

        Geocode.fromLatLng(newLat, newLng).then(
            (response: any) => {
                const address = response.results[0].formatted_address;
                this.setState({
                    address: (address) ? address : '',
                    lat: newLat,
                    lng: newLng
                })
            },
            (error: any) => {
                console.error(error);
            }
        );
    };

    render() {
        let { data } = this.props;
        // console.log("restuarant", data)
        const AsyncMap: any = withScriptjs(
            withGoogleMap(
                (props: any) => (
                    
                    <GoogleMap
                        google={this.props.google}
                        defaultZoom={this.props.zoom}
                        defaultCenter={{ lat: this.props.data.length>0? this.props.data[0].lat: this.state.lat, lng: this.props.data.length>0? this.props.data[0].lng: this.state.lng }}
                    >
                        
                        {/* InfoWindow on top of marker */}
                        {/* {this.state.lat !== "" &&
                            <InfoWindow
                                onClose={this.onInfoWindowClose}
                                position={{ lat: (this.state.lat + 0.0018), lng: this.state.lng }}
                            >
                                <div>
                                    <span style={{ padding: 0, margin: 0 }}>{this.state.address}</span>
                                </div>
                            </InfoWindow>
                        } */}
                        {/*Marker*/}
                        {/* <Marker
                            google={this.props.google}
                            name={'Dolores park'}
                            icon={"http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
                            // draggable={true}
                            // onDragEnd={this.onMarkerDragEnd}
                            position={{ lat: this.state.lat, lng: this.state.lng }}
                        /> */}
                        {data.length > 0 && data.map((obj, index) => {
                            return (
                                <Marker
                                    key={index}
                                    google={this.props.google}
                                    name={'Dolores park'}
                                    // draggable={true}
                                    // onDragEnd={this.onMarkerDragEnd}
                                    position={{ lat: obj.lat, lng: obj.lng }}
                                />
                            )
                        })}

                        <Marker />
                        {/* For Auto complete Search Box */}
                        {/* <Autocomplete
                            style={{
                                width: '100%',
                                height: '40px',
                                paddingLeft: '16px',
                                marginTop: '2px'
                            }}
                            placeholder={"Enter your Nearby Location"}
                            onPlaceSelected={this.onPlaceSelected}
                            types={['address']}   //types={['(regions)']}
                            componentRestrictions={{ country: "pk" }}
                        /> */}
                    </GoogleMap>
                    
                )
            )
        );
        let map;
        if (this.state.lat !== undefined) {
            map = <div style={{ width: '100%' }}>
                {/* <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor="" id="txtcolor">{i18next.t('map.address')}</label>
                            <input type="text" name="address" className="form-control" readOnly value={this.state.address} />
                        </div>
                    </div>
                </div> */}
                <AsyncMap
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=geometry,drawing,places`}
                    loadingElement={
                        <div style={{ height: '40vh', width: `100%` }} />
                    }
                    containerElement={
                        <div style={{ height: this.props.height }} />
                    }
                    mapElement={
                        <div style={{ height: '40vh', width: `100%` }} />
                    }
                />
            </div>
        } else {
            map = <div style={{ height: this.props.height }} />
        }
        return (map)
    }
}
export default Map
