import React, { Component } from "react";
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import Location from "../components/Landing/index";

import { Redirect } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

class LandingPage extends Component<{ history: any }, {}> {
  componentDidMount() {
      let windowObj = (window as any)
      windowObj.dataLayer.push({desc: null });
      windowObj.dataLayer.push({
        event: 'landing_page',
        desc: 'Landing Page visited'
      });  
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 3000);
  }
  render() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <title>Halla Shawarma - Taste you crave</title>
            <meta name="description" content="Order now from UAE coolest & Tastiest Shawarma handout spot" />
            <meta name="tags" content="" />
          </Helmet>
          <div className="page-wrapper">
            <div id="page-wrap">
              <Header history={this.props.history} />
              <Location history={this.props.history} />
              <Footer />
            </div>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default LandingPage;
