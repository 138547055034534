import { data } from "jquery";
import {
  LOGOUT_CUSTOMER,
  LOG_IN,
  SIGN_UP,
  STORES_LIST,
  MENU_COMBO_LIST,
  MENU_GROUPS_LIST,
  MENU_ITEMS,
  ORDER_LIST,
  GET_CART,
  SAVE_CART,
  GET_BRAND,
  STORE_NAME,
  GET_TAX,
  STORE_ID,
  STORE_TYPE,
  ORDER_ID,
  COMBO_DETAIL,
  COMBO_DETAIL_ID,
  ITEM_DETAIL,
  ADDRESS_GUEST,
  ORDER_TYPE,
  ORDER_PERIOD,
  SAVE_HOUSEDETAIL,
  SAVE_STREETDETAIL,
  SAVE_ADDRESSTYPE,
  SELECT_STORE_ID,
  SELECT_STORE,
  ADD_GUEST,
  PAYMENT,
  CUSTOMER_ID,
  FRANCHISE_APPLICATION,
  SIGNUP_k2g,
  TOP_LIST,
  HERO_ITEMS,
  FORGOT,
  ADDRESS_LIST,
  FAV_MENU_ITEM,
  OFFERS_LIST,
  COUPON_DATA,
  CHECKOUT_TAB_NAME,
  RESTUARANTS,
  ORDER_DATA,
  GET_PROFILE,
  HANDLE_PROFILE_INPUT,
  TOP_SELLING_ITEMS,
  ORDER_DETAIL,
  SAVE_ADDRESS_USER,
  SAVE_STORE_DETAILS,
  ADD_CONTACT,
  ADD_EMAIL,
  PAYMENT_METHOD,
  SUCCESS_FLAG,
  ORDER,
  URL_MATCH_METHOD,
  UPDATE_LSM,
  LSM_ITEMS,
  GET_TAX_CARD,
  GET_TAX_CASH,
  SAVE_TAX,
  SAVE_DISCOUNT,
  NETWORK_STATUS,
  NEW_CUSTOMER_PHONE_URL,
  TOP_DEALS_HOME,
  SWITCH_TAB,
  VALIDATE_OTP,
  SPECIFIC_STORE_ITEMS,
  MENU_GROUPS_LIST_HOME,
  HANDLE_SPECIAL_INPUT,
  MENU_STORE_ID,
  UPDATE_GROUPS_LIST,
  CARD_ORDER_DETAIL,
  LOCATION_FLAG,
  LOCATION_MODAL_FLAG,
  SEARCH_RESTUARANTS,
  ACCOUNT_AUTH,
  SAVE_ADDRESS,
  STORE_OPEN_FLAG,
  DELIVERY_FEE,
  PICKUP_STORES_LIST,
  SAVE_CARD_DETAILS,
  EXTRA_ADDRESS_DETAILS,
  FEATURED_PRODUCTS,
  SAVE_MASHRIK_BANK_DATA,
  HANDLE_CURTLERY,
  LAST_ORDER,
  KML_SHAPE,
  CROSS_SELL_ITEM_IDS,
  GROUPS_FOR_LANDING
} from "../Actions/types";

const initialState: any = {
  isLoggedIn: "",
  cross_sell_item_ids:[] || '',
  isOpen: "",
  firstname: "",
  lastname: "",
  email: "",
  dob: "",
  brand_details:'',
  phone: "",
  gender: "",
  emailExit: "",
  isLoggedInError: "",
  loginErrorMessage: "",
  storesList: [] || "",
  pickupStoresList: [] || "",
  storeStatus: 0,
  deliveryfee: "",
  menuCombosData: [] || "",
  storeSelected: {},
  groupList: [] || "",
  groupsHome: [] || "",
  groupsData: [] || "",
  menuItemsData: [] || "",
  lsmItemsData: [] || "",
  specificStoreItems: [] || "",
  ordersData: [] || "",
  cartData: [] || "",
  success: "",
  taxData: "",
  taxDataCash: [] || "",
  taxDataCard: [] || "",
  storeIdSelect: "",
  Store_ID: "",
  Types: "",
  orderType: "Delivery",
  paymentMethod: "Card",
  lookupURL: "",
  orderPeriod: "now",
  addressType: "",
  streetDetail: "",
  houseDetail: "",
  orderId: "",
  comboData: {},
  combo_ID: "",
  groupName: "",
  itemData: {},
  cardInfo: {},
  address_save: "",
  selectStoreId: undefined,
  menuStoreId: '',
  guestInfo: {},
  successStatus: "",
  paymentSuccess: '',
  CustomerId: '',
  isFilled: '',
  successStatusRegister: '',
  taxAmount: 0,
  discountAmount: 0,
  topList: '',
  topDealsHome: '',
  topItemsList: '',
  heroItem: [] || '',
  isReset: "",
  addressData: [] ,
  favItemData: [] || '',
  offers: [] || '',
  coupon: {},
  cardDetails: {},
  tabName: "location",
  switchTab: "1",
  isValidOtp: '',
  restuarants: [] || "",
  searchStores: [] || "",
  storeData: {},
  orderDetail: [],
  saveAddressUser: 'Home',
  couponSuccess: "",
  storeDetails: {},
  successFlag: false,
  posFlag:false,
  respMessage: "",
  OtprespMessage: '',
  order_id: "",
  lsmStatus: false,
  emptyLsmItemsFlag: false,
  netWork: false,
  oldCustomerCode: "",
  oldCustomerSuccess: true,
  oldCustomerMessage: "",
  oldCustomerName: "",
  special_instructions: "",
  loader: true,
  changeLocationModal: false,
  openLocModal: false,
  accountAuthStatus: "1",
  lat: 0,
  lng: 0,
  detail_address:"",
  storeOpenFlag: true,
  extra_address_details:'',
  address_id:'',
  featuredProducts:[] || '',
  mashrik_bank_data:{},
  bank_api_success:false,
  curtlery:false,
  lastOrder:[],
  kml_shape:null,
  groupsForLanding:[]
  // emailSignup:'',
  // passwordSignup:''
};

const loginReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGOUT_CUSTOMER:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };
      case CROSS_SELL_ITEM_IDS:
      return {
        ...state,
        cross_sell_item_ids: action.payload,
      };
    case GET_BRAND:
      return {
        ...state,
        brand_details:action.payload
      }
      case LAST_ORDER:
        return {
          ...state,
          lastOrder: action.payload
        }
      case HANDLE_CURTLERY:
        return {
          ...state,
          curtlery:action.curtlery
        }
    case HANDLE_PROFILE_INPUT: return {
      ...state,
      [action.input.name]: action.input.value
    }
    case HANDLE_SPECIAL_INPUT: return {
      ...state,
      [action.input.name]: action.input.value
    }
    case GET_PROFILE: return {
      ...state,
      firstname: action.payload.first_name,
      lastname: action.payload.last_name,
      email: action.payload.email_address,
      dob: action.payload.customer_birthday,
      phone: action.payload.phone_number,
      gender: action.payload.gender,
    }
    case KML_SHAPE:
      return {
        ...state,
        kml_shape:action.payload
      }
    case SIGNUP_k2g:
      return {
        ...state,
        isOpen: action.isOpen,
        emailExit: action.emailExit,
        successStatusRegister: action.successStatusRegister,
        // emailSignup:action.email,
        // passwordSignup:action.password
      };
    case SIGN_UP:
      return {
        ...state,
        isOpen: action.isOpen,
        emailExit: action.emailExit,
        // emailSignup:action.email,
        // passwordSignup:action.password
      };
    case LOG_IN:
      return {
        ...state,
        isLoggedInError: action.isLoggedInError,
        loginErrorMessage: action.loginErrMessage
      };

    case STORES_LIST:
      return {
        ...state,
        deliveryfee: action.deliveryfee,
        storesList: action.payload,
        storeSelected: action.payload[0],
        selectStoreId: action.selectStoreId,
        storeStatus: action.storeStatus,
        lat: action.lat,
        lng: action.lng
      };
      case PICKUP_STORES_LIST:
      return {
        ...state,
        pickupStoresList: action.payload,
        lat: action.lat,
        lng: action.lng
      };
    case MENU_STORE_ID:
      return {
        ...state,
        menuStoreId: action.payload,
        changeLocationModal: action.changeLocationModal
      }
    case MENU_COMBO_LIST:
      return {
        ...state,
        menuCombosData: action.payload,
      };
    case TOP_LIST:
      return {
        ...state,
        topList: action.payload,
      };
    case TOP_DEALS_HOME:
      return {
        ...state,
        topDealsHome: action.payload,
      };
    case TOP_SELLING_ITEMS:
      return {
        ...state,
        topItemsList: action.payload,
      };
    case MENU_GROUPS_LIST:
      return {
        ...state,
        groupList: action.payload,
        groupsData: action.groupsData,
        loader: action.loaderFlag
      };
    case MENU_GROUPS_LIST_HOME:
      return {
        ...state,
        groupsHome: action.payload,
      };
    case MENU_ITEMS:
      return {
        ...state,
        menuItemsData: action.payload,
        GroupName: action.groupName,
      };
    case LSM_ITEMS:
      return {
        ...state,
        lsmItemsData: action.payload,
        emptyLsmItemsFlag: action.emptyLsmItems,
        changeLocationModal: action.changeLocationModal
      };
    case SPECIFIC_STORE_ITEMS:
      return {
        ...state,
        specificStoreItems: action.payload,
      };
    case ORDER_LIST:
      return {
        ...state,
        ordersData: action.payload,
      };
    case GET_CART:
      return {
        ...state,
        cartData: action.payload,
      };
    case SAVE_CART:
      return {
        ...state,
        success: action.success,
      };
    case STORE_NAME:
      return {
        ...state,
        storeIdSelect: action.StoreID,
      };
    case GET_TAX:
      return {
        ...state,
        taxData: action.payload,
      };
    case GET_TAX_CASH:
      return {
        ...state,
        taxDataCash: action.payload,
        deliveryfee: action.deliveryfee
      };
    case GET_TAX_CARD:
      return {
        ...state,
        taxDataCard: action.payload,
        deliveryfee: action.deliveryfee
      };
    case STORE_ID:
      return {
        ...state,
        Store_ID: action.Store_ID,
      };
    case STORE_TYPE:
      return {
        ...state,
        Types: action.Types,
      };
    case ORDER_ID:
      return {
        ...state,
        orderId: action.payload,
      };
    case COMBO_DETAIL:
      return {
        ...state,
        comboData: action.payload,
      };
    case COMBO_DETAIL_ID:
      return {
        ...state,
        comboID: action.payload,
      };
    case ITEM_DETAIL:
      return {
        ...state,
        itemData: action.payload,
      };
    case ADDRESS_GUEST:
      return {
        ...state,
        address_save: action.Address,
      };
      case "SAVE_ADDRESS_ID":
      return {
        ...state,
        address_id: action.Address,
      };
    case ORDER_TYPE:
      return {
        ...state,
        orderType: action.orderType,
      };
    case SAVE_ADDRESS_USER:
      return {
        ...state,
        saveAddressUser: action.saveAddressUser,
      };
    case ORDER_PERIOD:
      return {
        ...state,
        orderPeriod: action.orderPeriod
      }
    case SAVE_HOUSEDETAIL:
      return {
        ...state,
        houseDetail: action.houseDetail,
      };
    case SAVE_STREETDETAIL:
      return {
        ...state,
        streetDetail: action.streetDetail,
      };
    case SAVE_ADDRESSTYPE:
      return {
        ...state,
        addressType: action.addressType,
      };
    case SELECT_STORE_ID:
      return {
        ...state,
        selectStoreId: action.selectStoreId,
      };
    case SELECT_STORE:
      return {
        ...state,
        storeSelected: action.storeSelected,
      };
    case ADD_GUEST:
      return {
        ...state,
        guestInfo: action.payload,
        successStatus: action.SuccessStatus,
      };
    case PAYMENT:
      return {
        ...state,
        paymentSuccess: action.PaymentSuccess,
        successFlag: action.successFlag,
        posFlag:action.posFlag,
        futuresuccessFlag: action.futuresuccessFlag
      };
    case CUSTOMER_ID:
      return {
        ...state,
        CustomerId: action.CustomerId,
      };
    case FRANCHISE_APPLICATION:
      return {
        ...state,
        isFilled: action.isFilled,
      }
    case HERO_ITEMS:
      return {
        ...state,
        heroItem: action.payload
      }
    case FORGOT:
      return {
        ...state,
        isReset: action.isReset,
        respMessage: action.respMessage,
        switchTab: action.switchTab
      }
    case VALIDATE_OTP:
      return {
        ...state,
        isValidOtp: action.isValidOtp,
        OtprespMessage: action.OtprespMessage,
        switchTab: action.switchTab
      }
    case ADDRESS_LIST:
      return {
        ...state,
        addressData: action.payload,
      };
    case FAV_MENU_ITEM:
      return {
        ...state,
        favItemData: action.payload,
      };
    case OFFERS_LIST:
      return {
        ...state,
        offers: action.payload,
      }
    case COUPON_DATA:
      return {
        ...state,
        coupon: action.payload,
        couponSuccess: action.successResponse
      };
    case CHECKOUT_TAB_NAME:
      return {
        ...state,
        tabName: action.tabName
      }
    case ORDER_DATA:
      return {
        ...state,
        storeData: action.storeData
      }
    case RESTUARANTS:
      return {
        ...state,
        restuarants: action.stores
      }
    case ORDER_DETAIL:
      return {
        ...state,
        orderDetail1: action.payload
      }
    case PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.paymentMethod,
      };
    case URL_MATCH_METHOD:
      return {
        ...state,
        lookupURL: action.url,
      };
    case ORDER:
      return {
        ...state,
        order_id: action.order_id,
      };
    case UPDATE_LSM:
      return {
        ...state,
        lsmStatus: action.statusInfo,
      };
    case SAVE_TAX:
      return {
        ...state,
        taxAmount: action.taxAmount,
      };
    case SAVE_DISCOUNT:
      return {
        ...state,
        discountAmount: action.discountValue,
      };
    case NETWORK_STATUS:
      return {
        ...state,
        netWork: action.payload
      }
    case NEW_CUSTOMER_PHONE_URL:
      return {
        ...state,
        oldCustomerCode: action.code,
        oldCustomerMessage: action.response,
        oldCustomerSuccess: action.success,
        oldCustomerName: action.name
      }
    case SWITCH_TAB:
      return {
        ...state,
        switchTab: action.switchTab
      }
    case CARD_ORDER_DETAIL:
      return {
        ...state,
        cardDetails: action.payload
      }
    case LOCATION_FLAG:
      return {
        ...state,
        changeLocationModal: action.payload
      }
    case LOCATION_MODAL_FLAG:
      return {
        ...state,
        openLocModal: action.payload
      }
    case SEARCH_RESTUARANTS:
      return {
        ...state,
        searchStores: action.searchStore
      }
    case ACCOUNT_AUTH:
      return {
        ...state,
        accountAuthStatus: action.switchTab
      }
      case  SAVE_ADDRESS:
        return {
          ...state,
          detail_address: action.payload
        }
    case STORE_OPEN_FLAG:
      return {
        ...state,
        storeOpenFlag: action.openFlag
      }
      case DELIVERY_FEE:
      return {
        ...state,
        deliveryfee: action.deliveryfee
      }
      case SAVE_CARD_DETAILS:
      return {
        ...state,
        cardInfo: action.payload
      }
      case EXTRA_ADDRESS_DETAILS:
      return {
        ...state,
        extra_address_details: action.payload
      }
      case FEATURED_PRODUCTS:
      return {
        ...state,
        featuredProducts:action.payload
      }
      case SAVE_MASHRIK_BANK_DATA:
        return {
          ...state,
          mashrik_bank_data:action.payload,
          bank_api_success:action.response_success
        }
      case GROUPS_FOR_LANDING:
        return{
          ...state,
          groupsForLanding:action.payload
        }
    default:
      return state;
  }
};
export default loginReducer;
