import { Component, ReactNode } from "react";
import axios from "axios";
import { v4 as uuid } from 'uuid';
//import { tracking_server } from "../ApiManager/client-config";

const userAgent = navigator.userAgent;
const isAndroid = userAgent.indexOf("Android") > -1;
const isiOS = !!userAgent.match(/iPad|iPhone|iPod/);

let channel = "";
let browser = "";
let appVersion:any = null;

if (isAndroid) {
  appVersion = "Android " + parseFloat(userAgent.slice(userAgent.indexOf("Android") + 8));
} else if (isiOS) {
  const match = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
  const version = match ? [parseInt(match[1], 10), parseInt(match[2], 10), parseInt(match[3] || '0', 10)] : [];
  appVersion = `iOS ${version.join(".")}`;
} else {
  appVersion = "Web";
}

// detect device type
if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry)/)) {
  channel = "mobile";
} else {
  channel = "web";
}

// detect browser type
if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
  browser = 'Opera';
} else if (navigator.userAgent.indexOf("Chrome") != -1) {
  browser = 'Chrome';
} else if (navigator.userAgent.indexOf("Safari") != -1) {
  browser = 'Safari';
} else if (navigator.userAgent.indexOf("Firefox") != -1) {
  browser = 'Firefox';

} else {
  browser = 'unknown';
}



interface Props {
  location: Location;
  setAddEvent: (addEvent: (data: any) => void) => void;
}

interface Location {
  pathname: string;
}

enum TrackCategory {
  events = "events",
  pages = "pages",
  payment_log="payment_log",
  empty = "empty"
}

//const ip = window.location.hostname;
//const protocol = window.location.protocol == "https:" ? 'https' : 'http';
//const trakApiUrl =`${protocol}://${ip}:6001/api/tracking`;  
const trakApiUrl ="https://sensewebstg.simplexts.com.au/sense/tracking";  

const brandId = 1;
let user_ip:any;

class UseTrackEvent extends Component<Props> {
  shouldLog: boolean = true;

  

  trackerEndPoint = async (data: any) => {
    try {
      // console.log("ddd",data)
      await axios.post(`${trakApiUrl}/user_tracking`, data)
    } catch (error) {
      console.error("error while tracker end point", error);
    }
  }

  trackerPaymentLogs = async (data: any) => {
    // console.log("payment log -----------------",data)
    try {
      await axios.post(`${trakApiUrl}/payment_logs`, data)
    } catch (error) {
      console.error("error while tracker end point", error);
    }
  }

  addEvent = (data: any) => {
    let { category } = data;
    let { events } = data
    if (localStorage.getItem("trackId") == null) {
      if (category === TrackCategory.empty) {
        localStorage.setItem("trackId", uuid() + "_" + Date.now())
        let obj = {
          tracking_id: localStorage.getItem("trackId"),
          ip_address:user_ip || localStorage.getItem("user_ip"),
          brand_id: brandId,
          page_track: data.page_track,
          category: TrackCategory.pages,
          channel,
          browser,
          version:appVersion
        }
        // console.log("first time add event called")
        this.trackerEndPoint(obj)
      }
    }
    else {
      if (category === TrackCategory.events) {
        this.trackerEndPoint({...data, tracking_id: localStorage.getItem("trackId")})
      } else if (category === TrackCategory.pages) {
        this.trackerEndPoint({...data, tracking_id: localStorage.getItem("trackId")})
      }
      else if (category === TrackCategory.payment_log) {
        this.trackerPaymentLogs({...events,tracking_id: localStorage.getItem("trackId")})
        
      }
      
    }
  }
  
  async componentDidMount() {
    this.props.setAddEvent(this.addEvent);

    if(!localStorage.getItem("user_ip"))
    {
      const response = await fetch('https://api.ipify.org/?format=json');
      const data = await response?.json();
      user_ip = data?.ip;
      localStorage.setItem("user_ip",user_ip);
    }  
     
 
    if (this.shouldLog) {
      //window.addEventListener('load', () => localStorage.removeItem("trackId"));
      this.shouldLog = false;
      let obj = { category: TrackCategory.empty, page_track: { page_name: this.props.location.pathname == "/" ? "/Landing" : this.props.location.pathname } };
      this.addEvent(obj);
    } else {
      let obj = { category: TrackCategory.pages, page_track: { page_name: this.props.location.pathname == "/" ? "/Landing" : this.props.location.pathname } };
      this.addEvent(obj);
    }
   
  }

  componentDidUpdate(prevProps: Props) {
    const { location } = this.props;
    if (prevProps.location.pathname !== window.location.pathname) {
      let obj = { category: TrackCategory.pages, page_track: { page_name: location.pathname == "/" ? "/Landing" : location.pathname } };
      this.addEvent(obj);
    }
  }

  
  render(): null {
    return null;
}
}

export default UseTrackEvent;
