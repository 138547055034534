import React, { Component } from "react";
// import LoginComponent from "../components/Login/index";
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import Cookie from "../components/CookiePolicy/cookie";

class CookiePolicy extends Component<{history:any},{}> {
    
    componentDidMount(){
        document.title = "Cookie Policy - HALLA"
    }

    render() {
        return (
            <div className="page-wrapper">
                <div id="page-wrap">
                    <Header history={this.props.history}/>
                    <Cookie history={this.props.history}/>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default CookiePolicy;